<div class="web_user_main_div" *ngIf="role == 1 || role == 6">
  <div style="    
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;">
    <div class="header">USER MANAGEMENT</div>
    <div class="swiching_web_user_btn_main_div">
      <button
        class="swiching_web_user_btn"
        [ngClass]="cesActiveFlag ? 'swiching_web_user_btn_active' : ''"
        (click)="handleSwicthWebUser('ces')"
      >
        ENTERPRISE USER
      </button>
      <button
        class="swiching_web_user_btn"
        [ngClass]="cesActiveFlag ? '' : 'swiching_web_user_btn_active'"
        (click)="handleSwicthWebUser('bm')"
      >
        RETAIL USER
      </button>
    </div>
    <div class="adding_btn_main_div">
      <button (click)="addNewUser()">
        <img src="../../../assets/img/plus_icon.svg" alt="" /><span
          >ADD USER</span
        >
      </button>
    </div>
  </div>
  <div class="web_user_content_main_div">

    <!-- ----------------------------------------ENTERPRISE USER TABLE ------------------------ ------------------------------ -->

    <div *ngIf="cesActiveFlag">
      <div class="web_user_search_main_div">
        <div class="web_user_search_sub_div">
          <div class="search_icon_inp_div">
            <img
              src="../../../assets/img/search_icon.svg"
              alt=""
              class="search_icon_inp_icon"
            />
            <input
              type="text"
              class="search_icon_inp"
              placeholder="Search"
              (keyup)="applyFilterCES($event)"
              autocomplete="off"
            />
          </div>
          <div class="dropdown">
            <button
              class="btn filter_btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="../../../assets/img/../img/filter_icon.svg"
                alt=""
              />Filters
            </button>
            <ul class="dropdown-menu">
              <li
                class="dropdown-item"
                style="color: #5f6370"
                [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                (click)="filterByStatus('all')"
              >
                All
              </li>
              <li
                class="dropdown-item"
                style="color: #12c058"
                [ngClass]="selectedFilter.active ? 'filterActiveCss' : ''"
                (click)="filterByStatus('active')"
              >
                Active Enterprise Users
              </li>
              <li
                class="dropdown-item"
                style="color: #d43738"
                [ngClass]="selectedFilter.expired ? 'filterActiveCss' : ''"
                (click)="filterByStatus('inactive')"
              >
                Inactive Enterprise Users
              </li>
              <li
                class="dropdown-item"
                style="color: #406cde"
                [ngClass]="selectedFilter.active ? 'filterActiveCss' : ''"
                (click)="filterByStatus('requested')"
              >
                Unassigned Enterprise Users
              </li>
            </ul>
          </div>
        </div>
        <div
          class="filterApplied"
          (click)="filterByStatus('all')"
          *ngIf="selectedFilterDropdown != 'all'"
        >
          {{ selectedFilterDropdown }}
          <img
            style="width: 15px; height: 15px; margin-left: 20px"
            src="../../../assets/img/xmark.svg"
          />
        </div>
      </div>
      <div class="web_user_table_main_div">
        <table
          mat-table
          [dataSource]="dataSourceEnterprise"
          matSort
          class="mat-elevation-z8"
          style="width: 100%; position: relative"
        >
          <!-- Sl NO -->
          <ng-container matColumnDef="sl_no">
            <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
            <td class="" mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </td>
          </ng-container>

          <!-- ENTERPRISE USER Name  -->
          <ng-container matColumnDef="name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: auto;
                "
              >
                <p mat-sort-header>Enterprise User</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EuNameFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ EuNameFilter ? EuNameFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="EuNameFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="EuApplyFilter('', 'name', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of EuNameDropdown"
                      (click)="EuApplyFilter(status, 'name', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td
              class="common_table_data"
              mat-cell
              *matCellDef="let element"
              style="color: #7893ec; cursor: pointer"
              (click)="handleModifyCES(element.id)"
            >
              {{ element.name }}
            </td>
          </ng-container>

          <!-- Company Name-->
          <ng-container matColumnDef="company_name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: auto;
                "
              >
                <p mat-sort-header>Company</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EuCompanyFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ EuCompanyFilter ? EuCompanyFilter : "All" }}
                    <img
                      (click)="EuApplyFilter('', 'company', 1)"
                      style="width: 20px"
                      *ngIf="EuCompanyFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of EuCompanyDropdown"
                      (click)="EuApplyFilter(status, 'company')"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.company_name }}
            </td>
          </ng-container>

          <!-- No. of Licenses -->
          <ng-container matColumnDef="licenses">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div style="display: flex; flex-direction: column; height: auto;">
                <p mat-sort-header>No. of Licenses</p>
                <!-- <div class="dropdown">
                  <button class="btn btnn filter_btn multiFilterBtn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img *ngIf="!EuLicensesFilter" src="../../../assets/img/../img/Group.svg" alt=""/>
                    {{ EuLicensesFilter ? EuLicensesFilter : "All" }}
                    <img style="width: 20px" *ngIf="EuLicensesFilter" src="../../../assets/img/../img/close-svgrepo-com.svg" alt=""
                      (click)="EuApplyFilter('', 'licenses', 1)"/>
                  </button>
                  <ul class="dropdown-menu">
                    <li class="dropdown-item" style="color: #5f6370" [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of EuLicensesDropdown" (click)="EuApplyFilter(status, 'licenses', 0)">
                      {{ status }}
                    </li>
                  </ul>
                </div> -->
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element" style="text-align: center;">
              {{ element.licenses}}
            </td>
          </ng-container>

          <!-- State -->
          <ng-container matColumnDef="state">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: auto;
                "
              >
                <p mat-sort-header>State</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EuCityFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ EuCityFilter ? EuCityFilter : "All" }}
                    <img
                      (click)="EuApplyFilter('', 'city', 1)"
                      style="width: 20px"
                      *ngIf="EuCityFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of EuCityDropdown"
                      (click)="EuApplyFilter(status, 'city', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.state }}
            </td>
          </ng-container>

          <!-- Date -->
          <ng-container matColumnDef="date">
            <th
              class="common_table_data"
              style="width: 6rem"
              mat-header-cell
              *matHeaderCellDef
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: auto;
                "
              >
                <p mat-sort-header>Date</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EuDateFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ EuDateFilter ? EuDateFilter : "All" }}
                    <img
                      (click)="EuApplyFilter('', 'date', 1)"
                      style="width: 20px"
                      *ngIf="EuDateFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of EuDateDropdown"
                      (click)="EuApplyFilter(status, 'date', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.date }}
            </td>
          </ng-container>

          <!-- Payment Due Date -->
          <ng-container matColumnDef="payment_due_date">
            <th
              class="common_table_data"
              style="width: 8rem"
              mat-header-cell
              *matHeaderCellDef
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: auto;
                "
              >
                <p mat-sort-header>Payment Due Date</p>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.payment_due_date ? (element.payment_due_date | date:'dd/MM/yyyy') : 'N/A' }}
            </td>
          </ng-container>

          <!-- Assign BM -->
          <ng-container matColumnDef="assign_business_manager">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: auto;
                  width: 10rem;
                "
              >
                <p mat-sort-header>Business Manager</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EuBmFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ EuBmFilter ? EuBmFilter : "All" }}
                    <img
                      (click)="EuApplyFilter('', 'bm', 1)"
                      style="width: 20px"
                      *ngIf="EuBmFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of EuBMDropdown"
                      (click)="EuApplyFilter(status, 'bm', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td
              class="common_table_data"
              mat-cell
              *matCellDef="let element"
              style="cursor: pointer"
              (click)="
                handleAssignment(
                  'bm',
                  element.assign_business_manager
                    ? element.assign_business_manager.id
                    : 0,
                  element.id
                )
              "
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                "
              >
                <span>{{
                  element.assign_business_manager == null
                    ? "None"
                    : element.assign_business_manager.name
                }}</span>
                <img
                  src="../../../assets/img/arrow_drop_down_ICON.svg"
                  alt=""
                />
              </div>
            </td>
          </ng-container>

          <!--Assign Account Managers -->
          <ng-container matColumnDef="assign_account_manager">
            <!-- <th class="common_table_data"  mat-header-cell *matHeaderCellDef>Account <br />
                            Managers</th> -->
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: auto;
                  width: 10rem;
                "
              >
                <p mat-sort-header>Account <br />Manager</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EuAmFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ EuAmFilter ? EuAmFilter : "All" }}
                    <img
                      (click)="EuApplyFilter('', 'am', 1)"
                      style="width: 20px"
                      *ngIf="EuAmFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of EuAMDropdown"
                      (click)="EuApplyFilter(status, 'am', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td
              class="common_table_data"
              mat-cell
              *matCellDef="let element"
              [ngClass]="
                element.assign_business_manager == null
                  ? ''
                  : 'disabledDropdpown'
              "
              (click)="
                handleAssignment(
                  'am',
                  element.assign_account_manager
                    ? element.assign_account_manager.id
                    : 0,
                  element.id
                )
              "
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                "
              >
                <span>{{
                  element.assign_account_manager == null
                    ? "None"
                    : element.assign_account_manager.name
                }}</span>
                <img
                  *ngIf="element.assign_business_manager == null"
                  src="../../../assets/img/arrow_drop_down_ICON.svg"
                  alt=""
                />
              </div>
            </td>
            <!-- <td class="common_table_data"  mat-cell *matCellDef="let element" style="cursor: pointer;"  (click)="handleAssignment('am',element.assign_account_manager?element.assign_account_manager.id:0,element.id,'retail')">
                            <div style="display: flex; justify-content: space-between;padding:10px 0;">
                                <span>{{element.assign_account_manager == null ? 'None' :
                                    element.assign_account_manager.name}}</span>
                                <img src="../../../assets/img/arrow_drop_down_ICON.svg" alt="">
                            </div>
                        </td> -->
          </ng-container>

          <!-- Change to Retail user -->
          <ng-container matColumnDef="downgrade">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Downgrade to Retail User
            </th>
            <td
              class="common_table_data"
              mat-cell
              *matCellDef="let element"
              style="cursor: pointer"
              (click)="confirmUpgrade(element.id, 'eu')"
            >
              <div style="display: flex; justify-content: center">
                <img
                  style="width: 30px; height: auto;"
                  src="../../../assets/img/downgrade.svg"
                  alt=""
                />
              </div>
            </td>
          </ng-container>

          <!-- Status -->
          <ng-container matColumnDef="status">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: auto;
                "
              >
                <p mat-sort-header>Status</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EuStatusFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ EuStatusFilter ? EuStatusFilter : "All" }}
                    <img
                      (click)="EuApplyFilter('', 'status', 1)"
                      style="width: 20px"
                      *ngIf="EuStatusFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of EuStatusDropdown"
                      (click)="EuApplyFilter(status, 'status', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <!-- <span
                                [ngClass]="element.status == 'Active'?'colorGreen':element.status == 'Inactive'?'colorRed':'colorBlue'">
                                {{element.status}}</span> -->
              <div class="dropdownn">
                <button
                  class="btn d-flex"
                  style="border: none"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  [ngClass]="
                    element.status == 'Active'
                      ? 'colorGreen'
                      : element.status == 'Inactive'
                        ? 'colorRed'
                        : 'colorBlue'
                  "
                >
                  {{ element.status }}
                  <img
                    style="width: 20px"
                    src="../../../assets/img/../img/arrow_drop_down_ICON.svg"
                    alt=""
                  />
                </button>
                <ul
                  class="dropdown-menu"
                  style="padding: 5px 5px; cursor: pointer"
                >
                  <li
                    class="dropdown-item"
                    class="colorGreen"
                    style="margin-bottom: 5px"
                    (click)="changeUserStatus(element, 'Active', 'eu')"
                  >
                    Active
                  </li>
                  <li
                    class="dropdown-item"
                    class="colorRed"
                    (click)="changeUserStatus(element, 'Inactive', 'eu')"
                  >
                    Inactive
                  </li>
                </ul>
              </div>
            </td>
          </ng-container>

          <!-- Reset Password -->
          <ng-container matColumnDef="reset_password">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Reset Password
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <img
                src="../../../assets/img/reset_icon.svg"
                alt=""
                srcset=""
                (click)="resetPasswordCES(element.id)"
                style="cursor: pointer"
              />
            </td>
          </ng-container>

          <!-- Modify -->
          <ng-container matColumnDef="modify">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Modify
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <div style="display: flex">
                <img
                  src="../../../assets/img/remove_icon.svg"
                  alt=""
                  style="cursor: pointer"
                  (click)="handelDeleteCES(element.id)"
                />
                <!-- <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
                                <img src="../../../assets/img/edit_icon.svg" alt="" style="cursor: pointer;" (click)="handleModifyCES(element.id)" > -->
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsEnterprise"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsEnterprise"
          ></tr>
        </table>
      </div>
    </div>

    <!-- --------------------------------------Retail User-------------------------------------- ---------------- -->

    <div *ngIf="!cesActiveFlag">
      <div class="web_user_search_main_div">
        <div class="web_user_search_sub_div">
          <div class="search_icon_inp_div">
            <img
              src="../../../assets/img/search_icon.svg"
              alt=""
              class="search_icon_inp_icon"
            />
            <input
              type="text"
              class="search_icon_inp"
              placeholder="Search"
              (keyup)="applyFilterRetail($event)"
              autocomplete="off"
            />
          </div>
        </div>

        <div class="dropdown">
          <button
            class="btn filter_btn"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="../../../assets/img/../img/filter_icon.svg"
              alt=""
            />Filters
          </button>
          <ul class="dropdown-menu">
            <li
              class="dropdown-item"
              style="color: #5f6370"
              [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
              (click)="filterByStatusRetail('all')"
            >
              All
            </li>
            <li
              class="dropdown-item"
              style="color: #12c058"
              [ngClass]="selectedFilter.active ? 'filterActiveCss' : ''"
              (click)="filterByStatusRetail('active')"
            >
              Active Retail Users
            </li>
            <li
              class="dropdown-item"
              style="color: #d43738"
              [ngClass]="selectedFilter.active ? 'filterActiveCss' : ''"
              (click)="filterByStatusRetail('inactive')"
            >
              Inactive Retail Users
            </li>
            <li
              class="dropdown-item"
              style="color: #406cde"
              [ngClass]="selectedFilter.active ? 'filterActiveCss' : ''"
              (click)="filterByStatusRetail('requested')"
            >
              Unassigned Retail Users
            </li>
          </ul>
        </div>
        <div
          class="filterApplied"
          (click)="filterByStatusRetail('all')"
          *ngIf="selectedFilterDropdown != 'all'"
        >
          {{ selectedFilterDropdown }}
          <img
            style="width: 15px; height: 15px; margin-left: 20px"
            src="../../../assets/img/xmark.svg"
          />
        </div>
      </div>
      <div class="web_user_table_main_div">
        <table
          mat-table
          [dataSource]="dataSourceRetail"
          matSort
          class="mat-elevation-z8"
          style="width: 100%; position: relative"
        >
          <!-- Sl NO -->
          <ng-container matColumnDef="sl_no">
            <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
            <td class="" mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </td>
          </ng-container>

          <!-- Retail User Name  -->
          <ng-container matColumnDef="name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: auto;
                  justify-content: space-between;
                "
              >
                <p>Retail User Name</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RUNameFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ RUNameFilter ? RUNameFilter : "All" }}
                    <img
                      (click)="RuApplyFilter('', 'name', 1)"
                      style="width: 20px"
                      *ngIf="RUNameFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of RUNameDropdown"
                      (click)="RuApplyFilter(status, 'name', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td
              class="common_table_data"
              mat-cell
              *matCellDef="let element"
              style="color: #7893ec; cursor: pointer"
              (click)="editRetailUser(element.id)"
            >
              {{ element.name == null ? "N/A" : element.name }}
            </td>
          </ng-container>

          <!-- Company name -->
          <ng-container matColumnDef="company_name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: auto;
                "
              >
                <p mat-sort-header>Company Name</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RUCompanyFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ RUCompanyFilter ? RUCompanyFilter : "All" }}
                    <img
                      (click)="RuApplyFilter('', 'company', 1)"
                      style="width: 20px"
                      *ngIf="RUCompanyFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of RUCompanyDropdown"
                      (click)="RuApplyFilter(status, 'company', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.company_name == null ? "N/A" : element.company_name }}
            </td>
          </ng-container>

          <!-- No. of Licenses -->
          <ng-container matColumnDef="licenses">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div style="display: flex; flex-direction: column; height: auto;">
                <p mat-sort-header>No. of Licenses</p>
                <!-- <div class="dropdown">
                  <button class="btn btnn filter_btn multiFilterBtn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img *ngIf="!EuLicensesFilter" src="../../../assets/img/../img/Group.svg" alt=""/>
                    {{ EuLicensesFilter ? EuLicensesFilter : "All" }}
                    <img style="width: 20px" *ngIf="EuLicensesFilter" src="../../../assets/img/../img/close-svgrepo-com.svg" alt=""
                      (click)="EuApplyFilter('', 'licenses', 1)"/>
                  </button>
                  <ul class="dropdown-menu">
                    <li class="dropdown-item" style="color: #5f6370" [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of EuLicensesDropdown" (click)="EuApplyFilter(status, 'licenses', 0)">
                      {{ status }}
                    </li>
                  </ul>
                </div> -->
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element" style="text-align: center;">
              {{ element.licenses}}
            </td>
          </ng-container>

          <!-- City -->
          <ng-container matColumnDef="state">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: auto;
                "
              >
                <p mat-sort-header>State</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RUCityFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ RUCityFilter ? RUCityFilter : "All" }}
                    <img
                      (click)="RuApplyFilter('', 'city', 1)"
                      style="width: 20px"
                      *ngIf="RUCityFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of RUCityDropdown"
                      (click)="RuApplyFilter(status, 'city', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.state == null ? "N/A" : element.state }}
            </td>
          </ng-container>

          <!-- Date -->
          <ng-container matColumnDef="date">
            <th
              class="common_table_data"
              style="width: 6rem"
              mat-header-cell
              *matHeaderCellDef
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: auto;
                "
              >
                <p mat-sort-header>Date</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RUDateFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ RUDateFilter ? RUDateFilter : "All" }}
                    <img
                      (click)="RuApplyFilter('', 'date', 1)"
                      style="width: 20px"
                      *ngIf="RUDateFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of RUDateDropdown"
                      (click)="RuApplyFilter(status, 'date', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.date }}
            </td>
          </ng-container>

          <!-- Assign AM -->
          <ng-container matColumnDef="assign_account_manager">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: auto;
                  width: 10rem;
                "
              >
                <p mat-sort-header>Account Manager</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RUAmFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ RUAmFilter ? RUAmFilter : "All" }}
                    <img
                      (click)="RuApplyFilter('', 'bm', 1)"
                      style="width: 20px"
                      *ngIf="RUAmFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of RUAMDropdown"
                      (click)="RuApplyFilter(status, 'bm', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td
              class="common_table_data"
              mat-cell
              *matCellDef="let element"
              style="cursor: pointer"
              (click)="
                handleAssignment(
                  'am',
                  element.assign_account_manager
                    ? element.assign_account_manager.id
                    : 0,
                  element.id,
                  'retail'
                )
              "
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                "
              >
                <span>{{
                  element.assign_account_manager == null
                    ? "None"
                    : element.assign_account_manager.name
                }}</span>
                <img
                  src="../../../assets/img/arrow_drop_down_ICON.svg"
                  alt=""
                />
              </div>
            </td>
          </ng-container>

          <!-- Change to ENterprise user -->
          <ng-container matColumnDef="upgrade_access">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Upgrade to Enterprise User
            </th>
            <td
              class="common_table_data"
              mat-cell
              *matCellDef="let element"
              style="cursor: pointer"
              (click)="confirmUpgrade(element.id, 'ru')"
            >
              <div style="display: flex; justify-content: center">
                <img
                  style="width: 30px; height: auto"
                  src="../../../assets/img/upgrade.png"
                  alt=""
                />
              </div>
            </td>
          </ng-container>

          <!-- Status -->
          <ng-container matColumnDef="status">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: auto;
                "
              >
                <p mat-sort-header>Status</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RUStatusFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ RUStatusFilter ? RUStatusFilter : "All" }}
                    <img
                      (click)="RuApplyFilter('', 'status', 1)"
                      style="width: 20px"
                      *ngIf="RUStatusFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of RUStatusDropdown"
                      (click)="RuApplyFilter(status, 'status', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <!-- <span [ngClass]="element.status == 'Active'?'colorGreen':element.status == 'Inactive'?'colorRed':'colorBlue'">
                                {{element.status}}</span> -->
              <div class="dropdownn">
                <button
                  class="btn d-flex"
                  style="border: none"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  [ngClass]="
                    element.status == 'Active'
                      ? 'colorGreen'
                      : element.status == 'Inactive'
                        ? 'colorRed'
                        : 'colorBlue'
                  "
                >
                  {{ element.status }}
                  <img
                    style="width: 20px"
                    src="../../../assets/img/../img/arrow_drop_down_ICON.svg"
                    alt=""
                  />
                </button>
                <ul
                  class="dropdown-menu"
                  style="padding: 5px 5px; cursor: pointer"
                >
                  <li
                    class="dropdown-item"
                    style="margin-bottom: 5px"
                    class="colorGreen"
                    (click)="changeUserStatus(element, 'Active', 'ru')"
                  >
                    Active
                  </li>
                  <li
                    class="dropdown-item"
                    class="colorRed"
                    (click)="changeUserStatus(element, 'Inactive', 'ru')"
                  >
                    Inactive
                  </li>
                </ul>
              </div>
            </td>
          </ng-container>

          <!-- Reset Password -->
          <ng-container matColumnDef="resetPassword">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Reset Password
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <img
                src="../../../assets/img/reset_icon.svg"
                alt=""
                srcset=""
                (click)="resetPasswordRetail(element.id)"
                style="cursor: pointer"
              />
            </td>
          </ng-container>

          <!-- Modify -->
          <ng-container matColumnDef="modify">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Modify
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <div style="display: flex">
                <img
                  src="../../../assets/img/remove_icon.svg"
                  alt=""
                  style="cursor: pointer"
                  (click)="handelDeleteRetail(element.id)"
                />
                <!-- <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
                                <img src="../../../assets/img/edit_icon.svg" alt=""
                                    (click)="editRetailUser(element.id)" style="cursor: pointer;"> -->
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsBM"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsBM"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
