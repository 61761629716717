import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/api/http.service';
import { DialogDeleteCofirm } from '../common_dialog/delete-confirm/delete_confirm_dialog';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data-share/data.service';
import { NotificationsComponent } from '../common_dialog/notifications/notifications.component';
import { Router } from '@angular/router';
import { AES, enc } from 'crypto-js';
import { MatTableDataSource } from '@angular/material/table';
import { PincodeComponent } from './pincode/pincode.component';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  
  selectorTabData: any = 'storage';
  individualStorage: any = 0;
  retailStorage: any = 0;
  retailLicenseCount: any = 0;
  notifications: any = [];
  isNewNotifications: boolean = false;
  constructor(
    private http: HttpService,
    private toast: ToastrService,
    public dialog: MatDialog,
    private dataShare: DataService,
    private router: Router,
  ) {}
  role: any;

  displayedColumns: string[] = [
    'sl_no',
    'pincode',
    'district',
    'state',
    'modify',
  ];
  pageSize = 50;  // Default page size
  currentPage = 1;
  totalItems: number = 1;  // Total items from the API
  data: any[] = [];
  searchQuery: number | string = ''; 
  roleId: any;
  pincodes: any = new MatTableDataSource();

  ngOnInit(): void {
    this.loadPincodes();
    const roleIdEncoded = localStorage.getItem('role');
    if (roleIdEncoded) {
      const roleId = AES.decrypt(
        roleIdEncoded,
        this.dataShare.secretKey,
      ).toString(enc.Utf8);
      this.role = roleId;
      console.log(this.role, roleId);
    }
    this.http.getStorageLimit().subscribe((res: any) => {
      if (res['status']) {
        this.retailStorage = res['results'][0].upload_size_limit;
        this.individualStorage = res['results'][0].storage_size_limit;
      }
    });
    this.http.getRetailLicenseLimit().subscribe((res: any) => {
      if (res['status']) {
        this.retailLicenseCount = res['results'][0].ru_license_limit;
      }
    });
    this.getNotifications();

    setTimeout(async () => {
      this.photo = await localStorage.getItem('photo');
      this.username = await localStorage.getItem('username');
    }, 0);
  }
  username: any = 'User';
  photo: any = '../../../assets/img/default_dp.png';
  tPhoto: any = '../../../assets/img/default_dp.png';
  navigate(route: string) {
    if (this.router.url == route) return;
    else this.router.navigate([route]);
  }
  getNotifications() {
    this.http.getNotifications(37).subscribe(
      (res: any) => {
        if (res['status']) {
          this.notifications = res['result'];
          const newNotf = this.notifications.filter(
            (data: any) => data.is_read === false,
          );
          console.log(this.notifications, newNotf);
          if (newNotf.length > 0) this.isNewNotifications = true;
          else this.isNewNotifications = false;
        } else {
          this.notifications = [];
          this.isNewNotifications = false;
        }
      },
      (err: any) => {
        this.toast.error('Oops!, Somrthing went wrong Please try again', '', {
          timeOut: 2000,
        });
      },
    );
  }
  @Output() messageEmitter = new EventEmitter<any>();
  moveTab(tab: any, to: any = 'Enterprise') {
    this.messageEmitter.emit(tab);
    this.dataShare.prefilter = to;
  }
  readNotifications(element: any) {
    this.moveTab('service', element.type == 'eu' ? 'Enterprise' : 'Retail');
    this.dataShare.dialogRef(element.service_request_id, element.type);
    let data = {
      id: element.id,
      is_read: 1,
    };
    this.http.readNotification(data).subscribe(
      (res: any) => {
        if (res['status']) {
          this.getNotifications();
        } else {
          this.toast.error(res['message'], '', { timeOut: 2000 });
        }
      },
      (err: any) => {
        this.toast.error('Oops!, Somrthing went wrong Please try again', '', {
          timeOut: 2000,
        });
      },
    );
  }
  showNotif: boolean = false;
  toggleNotif() {
    this.dialog
      .open(NotificationsComponent, {
        data: this.notifications,
        position: {
          top: `5rem`,
          right: `25rem`,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.getNotifications();
        }
      });
  }
  selectorTab(data: any) {
    this.selectorTabData = data;
    if (data == 'storage') {
      this.getSettingData();
    }
  }
  getSettingData() {}
  confirmStorageSubmit(from: any) {
    var heading: any;
    if (from == 'storage')
      heading = 'Are you sure you want to Edit STORAGE Details?';
    else heading = 'Are you sure you want to Edit RETAIL LICENSE COUNT?';
    const dialogRef = this.dialog
      .open(DialogDeleteCofirm, {
        hasBackdrop: true,
        disableClose: true,
        data: {
          heading: heading,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          if (from == 'storage') this.storageSubmit();
          else this.confirmLicense();
        }
      });
  }
  storageSubmit() {
    if (this.retailStorage >= 1) {
      if (this.individualStorage >= 1) {
        let data = {
          upload_size_limit: this.retailStorage,
          storage_size_limit: this.individualStorage,
        };
        this.http.setStorageLimit(data).subscribe(
          (res: any) => {
            if (res['status']) {
              this.toast.success('Storage Data Updated Successfully', '', {
                timeOut: 2000,
              });
            } else {
              this.toast.error('Oops! Something went wrong', '', {
                timeOut: 2000,
              });
            }
          },
          (err: any) => {
            this.toast.error('Oops! Something went wrong', '', {
              timeOut: 2000,
            });
          },
        );
      } else {
        this.toast.error(
          'Individual Upload File Size Limit should be Greater than 1',
          '',
          { timeOut: 3000 },
        );
      }
    } else {
      this.toast.error(
        'Individual Retail User Account Storage Limit should be Greater than 1',
        '',
        { timeOut: 3000 },
      );
    }
  }

  confirmLicense() {
    if (this.retailLicenseCount >= 1) {
      let data = {
        ru_license_limit: this.retailLicenseCount,
      };
      this.http.setRetailLicenseLimit(data).subscribe(
        (res: any) => {
          if (res['status']) {
            this.toast.success('License Data Updated Successfully', '', {
              timeOut: 2000,
            });
          } else {
            this.toast.error('Oops! Something went wrong', '', {
              timeOut: 2000,
            });
          }
        },
        (err: any) => {
          this.toast.error('Oops! Something went wrong', '', { timeOut: 2000 });
        },
      );
    } else {
      this.toast.error(
        'Retail Users License Limit should be Greater than 1',
        '',
        { timeOut: 3000 },
      );
    }
  }
  // Handle page change
  ngAfterViewInit() {
    this.pincodes.paginator = this.paginator;
    this.loadPincodes(); // Load data after view initialization
  }

  loadPincodes() {
    console.log("page:", this.currentPage, 'page size: ', this.pageSize);

    this.http.getPincodes(this.currentPage, this.pageSize, this.searchQuery).subscribe(
      (data: any) => {
        this.pincodes.data = data.results;  // Populate the table with the data from the API
        this.totalItems = data.count;
        console.log('Pincodes loaded:', data);
      },
      (error) => {
        console.error('Error loading pincodes', error);
      }
    );
  }

  onSearch() {
    if (this.searchQuery === '') {
      this.loadPincodes(); // Reset to all records if search is empty
    } else {
      this.currentPage = 1;  // Reset to first page
      this.loadPincodes();
    }
  }

  clearSearch() {
    this.searchQuery = '';  // Clear the search query
    this.currentPage = 1;
    this.loadPincodes();  // Reload the table with all data
  }

  onPageChange(event: any) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.loadPincodes();  // Reload data based on the new page and page size
  }

  addPincode() {
    this.dialog
      .open(PincodeComponent, { hasBackdrop: true, disableClose: true, data: {} })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.loadPincodes();
        }
      });
  }

  modifyPincode(pincodeData: any) {
    this.dialog
      .open(PincodeComponent, { hasBackdrop: true, disableClose: true, data: pincodeData })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.loadPincodes();
        }
      });
  }
}
