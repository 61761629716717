<div class="ces_main_div" style="max-width: 100%;  min-height: auto;">
    <div class="ces_header">
      <span>{{ title ? title : 'Document Version History' }}</span>
    </div>
    <div class="ces_content_main_div" style="background: white;">

      <div style="width:100%;padding:2rem;padding-top: 0;" *ngIf="!data.addNew">
        
        <div class="license_modify_pd_main_divq headerr" style="
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr;
        gap: 20px;
        border-bottom: 2px solid #ddd;
        padding: 10px 0;">
            <div style="min-width:5rem;">User Name</div>
            <div style="min-width:10rem;">File Name</div>
            <div style="min-width:5rem;">Status</div>
            <div style="min-width:5rem;">Expiry Date</div>
            <div style="min-width:15rem;">Document Updated on</div>
            <div style="min-width:5rem;">Remarks</div>

        </div>

        <div style="border-bottom: 1px solid #ddd;">
          <div *ngFor="let item of historyData" 
               class="license_modify_pd_main_divq"
               style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr; gap: 20px; padding: 10px 0;">
              <div style="min-width:5rem;">{{ item.name || 'N/A' }}</div>
              <div style="min-width:10rem; color: #6386fa; cursor: pointer;" 
                (click)="viewLicence(item.file_path)">
                  {{ item.file_name || 'N/A' }}
              </div>
              <div style="min-width:5rem;">{{ item.status || 'N/A' }}</div>
              <div style="min-width:5rem;"> {{ item.expiry_date ? (item.expiry_date | date: 'dd/MM/yyyy') : 'N/A' }}</div>
              <div style="min-width:15rem;">{{ item?.datetime | date: "EEE, MMM d yyyy 'at' h:mm a" }}</div>
              <div style="min-width: 5rem">{{ item.remarks }}</div>
          </div>
      </div>
    </div> 
    </div>
    <div class="ces_btn_main_div" style="    
    padding: 1rem 2rem;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;">
      <div class="ces_pd_btn_div">
        <div (click)="close()">CLOSE</div>
      </div>
    </div>
  </div>
  