import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

export interface BlogPost {
  id: number;
  title: { rendered: string };
  modified: string;
  link: string;
  img?: string;
  source?: string;
}

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  private readonly FSSAI_URL = 'https://myfssai.in/wp-json/wp/v2';
  private readonly FSW_URL = 'https://foodsafetyworks.com/wp-json/wp/v2';

  private allPosts = new BehaviorSubject<BlogPost[]>([]);
  private isLoading = new BehaviorSubject<boolean>(false);

  private cachedBlogs: BlogPost[] | null = null;
  private cachedInsights: BlogPost[] | null = null;
  private cachedNews: BlogPost[] | null = null;
  private cachedAnnouncements: BlogPost[] | null = null;

  posts$ = this.allPosts.asObservable();
  loading$ = this.isLoading.asObservable();

  constructor(private http: HttpClient) {}

  /**
   * Load all categories of content (blogs, insights, news, announcements)
   * and cache them for future use.
   */
  loadAllContent(): Observable<{ blogs: BlogPost[]; insights: BlogPost[]; }> {
    // Check if all data is cached
    if (this.cachedBlogs && this.cachedInsights) {
      this.allPosts.next([...this.cachedBlogs, ...this.cachedInsights]);
      return of({
        blogs: this.cachedBlogs,
        insights: this.cachedInsights,
      });
    }

    this.isLoading.next(true);

    // Fetch all categories using forkJoin
    const blogs$ = this.fetchBlogs();
    const insights$ = this.fetchInsights();

    return forkJoin([blogs$, insights$]).pipe(
      map(([blogs, insights]) => {
        // Transform the array into the expected object
        const result = {
          blogs,
          insights
        };

        // Cache the results
        this.cachedBlogs = blogs;
        this.cachedInsights = insights;

        // Combine all posts
        const allPosts = [...blogs, ...insights].sort(
          (a, b) => new Date(b.modified).getTime() - new Date(a.modified).getTime()
        );
        this.allPosts.next(allPosts);
        this.isLoading.next(false);

        return result;
      }),
      catchError(() => {
        this.isLoading.next(false);
        return of({
          blogs: [],
          insights: [],
        });
      })
    );
  }

  /**
   * Fetch blogs from FSSAI API.
   */
  private fetchBlogs(): Observable<BlogPost[]> {
    return this.http.get<BlogPost[]>(`${this.FSSAI_URL}/fssai_updates`).pipe(
      map((posts) => this.transformPosts(posts, 'FSSAI')),
      catchError(() => of([]))
    );
  }

  /**
   * Fetch insights from FSW API.
   */
  private fetchInsights(): Observable<BlogPost[]> {
    return this.http.get<BlogPost[]>(`${this.FSW_URL}/insights`).pipe(
      map((posts) => this.transformPosts(posts, 'FSW')),
      catchError(() => of([]))
    );
  }

  /**
   * Fetch news from FSSAI API.
   */

  /**
   * Transform posts to a unified format with additional metadata.
   * @param posts The raw posts fetched from the API
   * @param source The source of the posts (e.g., 'FSSAI' or 'FSW')
   * @returns An array of transformed BlogPost objects
   */
  private transformPosts(posts: any[], source: string): BlogPost[] {
    return posts.map((post) => ({
      id: post.id,
      title: post.title,
      modified: post.modified,
      link: post.link,
      img: source === 'FSSAI' ? '../../../assets/img/FSSAI_logo.png' : '../../../assets/img/Favicon-1.png',
      source,
    }));
  }
}