import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-audit-chart-modal',
  templateUrl: './audit-chart-modal.component.html',
  styleUrls: ['./audit-chart-modal.component.css'],
})
export class AuditChartModalComponent implements OnInit {
  chartOptions: any;

  constructor(
    public dialogRef: MatDialogRef<AuditChartModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.setupChart();
  }

  setupChart() {
    const { auditNames, auditDates, auditScores, chartType, licenseNumber, businessName } = this.data;

    // const auditLabels: [string, string][] = auditNames.map((name: string, index: number) => {
    //   const auditDate = new Date(auditDates[index]);
    //   const shortDate = new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'Asia/Kolkata' }).format(auditDate);
    //   return [name, shortDate];
    // });

    const auditData = auditNames.map((name: string, index: number) => ({
      name,
      date: new Date(auditDates[index]), // Convert to Date object
      formattedDate: new Intl.DateTimeFormat('en-GB', { 
        day: '2-digit', 
        month: '2-digit', 
        year: 'numeric', 
        timeZone: 'Asia/Kolkata' 
      }).format(new Date(auditDates[index])),
      score: auditScores[index] // Ensure scores are correctly associated
    }));
  
    // Ensure all dates are valid before sorting
    auditData.forEach((item: { date: { getTime: () => number; }; }) => {
      if (isNaN(item.date.getTime())) {
        console.error("Invalid date detected:", item.date);
      }
    });
  
    // Sort the audits by date (converted Date object)
    auditData.sort((a: { date: { getTime: () => number; }; }, b: { date: { getTime: () => number; }; }) => a.date.getTime() - b.date.getTime());
  
    // Extract sorted labels and scores
    const auditLabels: [string, string][] = auditData.map((item: { name: any; formattedDate: any; }) => [item.name, item.formattedDate]);
    const sortedAuditScores = auditData.map((item: { score: any; }) => item.score); // Now aligned with sorted audits
  
    // Generate a readable timestamp
    const timestamp = formatDate(new Date(), 'MMMM d, yyyy - hh:mm:ss a', 'en-GB');
  
    this.chartOptions = {
      series: [
        {
          name: 'Audit Score',
          data: sortedAuditScores,
        },
      ],
      chart: {
        type: chartType,
        height: Math.min(window.innerHeight * 0.7, 600), // Scale height dynamically
        width: '100%',
        toolbar: {
          show: false,
        },
      },
      title: {
        text: `Audit Score Comparison License ${licenseNumber} - ${businessName}`,
        align: 'center',
        floating: true,  // Prevents title from pushing content down
        style: {
          fontSize: '16px',
          fontWeight: 'bold',
        },
      },
      subtitle: {
        text: `Timestamp: ${timestamp}`,
        floating: false, 
        align: 'right',
        offsetY: Math.min((window.innerHeight * 0.7) - 20, 580),
        style: {
          fontSize: '12px',
          fontWeight: 'normal',
          color: '#9699a2',
        },
      },
      xaxis: {
        categories: auditLabels,
        title: { text: 'Audits'},
        offsetY: -5,
        tooltip: {
          enabled: false, // **Hides the x-axis label at the bottom of the vertical dotted line**
        },
        
      },
      yaxis: {
        title: {
          text: 'Scores',
          style: { fontSize: '12px'},
          offsetX: 8 
        },
      },
      tooltip: {
        enabled: true,
        x: {
          formatter: (value: any, { dataPointIndex }: any) => {
            const label = auditLabels[dataPointIndex];  
            return label ? `${label[0]}<br>${label[1]}` : 'N/A';
          },
        },
      },
      dataLabels: {
        enabled: true, // Enable labels on markers
      },
      grid: {
        padding: {
          bottom: 60,
        },
      },
    };
  }

  exportToPDF() {
    const chartElement = document.querySelector('.apexcharts-canvas') as HTMLElement;
  
    if (chartElement) {
      html2canvas(chartElement).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        pdf.addImage(imgData, 'PNG', 10, 40, 180, 100);
        pdf.save(`Audit_Scores_License_${this.data.licenseNumber}.pdf`);
      });
    }
  }
}