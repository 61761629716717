import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/api/http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-pincode',
  templateUrl: './pincode.component.html',
  styleUrls: ['./pincode.component.css'],
})
export class PincodeComponent implements OnInit {
  isModify: boolean = false;
  userForm: FormGroup;
  states: string[] = [
    "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", 
    "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", 
    "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", 
    "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", 
    "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", 
    "Uttar Pradesh", "Uttarakhand", "West Bengal"
  ];

  constructor(
    private http: HttpService,
    private toast: ToastrService,
    public dialogRef: MatDialogRef<PincodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.userForm = this.fb.group({
      pincode: ['', [Validators.required, Validators.pattern('^[0-9]{6}$')]],
      district: ['', [Validators.required]],
      state: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    if (this.data && Object.keys(this.data).length) {
      this.isModify = true;
      this.userForm.patchValue({
        pincode: this.data.pincode || '',
        district: this.data.district || '',
        state: this.data.state || '',
      });
    }
  }

  close() {
    this.dialogRef.close();
  }

  addPincode() {
    if (this.userForm.valid) {
      this.http.addPincode(this.userForm.value).subscribe(
        (res : any) => {
          this.toast.success('Pincode added successfully');
          this.dialogRef.close(true);
        },
        (err: any) => {
          if (err.error && err.error.error === 'Pincode already exists.') {
            this.toast.error('This pincode already exists!', '', { timeOut: 3000 });
          } else {
            this.toast.error('Failed to add pincode. Please try again.', '', { timeOut: 2000 });
          }
        }
      );
    } else {
      this.toast.error('Please enter valid details', '', { timeOut: 2000 });
    }
  }

  modifyPincode() {
    if (this.userForm.valid) {
      const updatedData = {
        ...this.userForm.value,
      };

      this.http.updatePincode(this.data.id, updatedData).subscribe(
        (res : any) => {
          this.toast.success('Pincode updated successfully');
          this.dialogRef.close(true);
        },
        (err: any) => {
          this.toast.error('Failed to update pincode');
        }
      );
    }
  }
  
}