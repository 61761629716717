<div class="license_modify_main_div">
  <img
    class="license_close_img"
    src="../../../assets/img/close.svg"
    alt=""
    (click)="close()"
  />
  <div class="license_modify_header" *ngIf="!addNew && type =='new' " style="justify-content: space-between; padding-right: 3rem;">
    <div>
      Update License Details
    </div>

    <div style="display: flex; justify-content: space-between; margin-top: -0.35rem;" >
        <div class="view_license_span"  
          (click)="licensesUrl ? viewLicence() : null" 
          [ngClass]="{'disabled': !licensesUrl}" 
          style="margin-right: 1rem;"
          >
            View License
        </div>
      <div class="view_license_span" [ngClass]="{'disabled': !licensesUrl}" (click)="openDocumentHistory()" >License History</div>
    </div>
  </div>
    <div class="license_modify_header" *ngIf="(addNew || type=='existing')">Add License Details
      <div
      *ngIf="(addNew || type=='existing')"
      class="ces_sub_header add_user_header"
    >
      <div>
        <input
          type="radio"  
          name="typeOfUser"
          id=""
          checked
          (click)="changeLicense('new')"
        />
        <span>New License</span>
      </div>
      <div>
        <input
          type="radio"
          name="typeOfUser"
          id=""
          (click)="changeLicense('existing')"
        />
        <span>Existing License</span>
      </div>
    </div>
  </div>
  <!-- <div class="license_modify_header" *ngIf="type=='existing'">
    Add License Details
    <div
        *ngIf="(addNew || type=='existing')"
        class="ces_sub_header add_user_header"
      >
        <div>
          <input
            type="radio"  
            name="typeOfUser"
            id=""
            checked
            (click)="changeLicense('new')"
          />
          <span>New License</span>
        </div>
        <div>
          <input
            type="radio"
            name="typeOfUser"
            id=""
            (click)="changeLicense('existing')"
          />
          <span>Existing License</span>
        </div>
      </div>
  </div> -->

  <div class="license_modify_content_main_div">
    <div class="license_modify_sub_header">
      <div
        [ngClass]="selectedTab == 'LICENSE DETAILS'?'license_modify_sub_header_active':''"
        (click)="handleTabChange('LICENSE DETAILS')"
      >
        LICENSE
      </div>
      <div
        [ngClass]="[selectedTab == 'APPLICATION'?'license_modify_sub_header_active':'',tempLicenseNo == '' ? 'disabled' : '']"
        (click)="handleTabChange('APPLICATION')"
      >
        COMPANY
      </div>
      <div
        [ngClass]="[selectedTab == 'TESTING'?'license_modify_sub_header_active':'',tempLicenseNo == '' ? 'disabled' : '']"
        (click)="handleTabChange('TESTING')"
      >
        TESTING
      </div>
      <div
        [ngClass]="[selectedTab == 'TRAINING'?'license_modify_sub_header_active':'',tempLicenseNo == '' ? 'disabled' : '']"
        (click)="handleTabChange('TRAINING')"
      >
        TRAINING
      </div>
      <div
        [ngClass]="[selectedTab == 'HEALTH'?'license_modify_sub_header_active':'',tempLicenseNo == '' ? 'disabled' : '']"
        (click)="handleTabChange('HEALTH')"
      >
        HEALTH
      </div>
      <div
        [ngClass]="[selectedTab == 'CALIBRATION'?'license_modify_sub_header_active':'',tempLicenseNo == '' ? 'disabled' : '']"
        (click)="handleTabChange('CALIBRATION')"
      >
        CALIBRATION
      </div>
      <div
        [ngClass]="[selectedTab == 'OTHERS'?'license_modify_sub_header_active':'',tempLicenseNo == '' ? 'disabled' : '']"
        (click)="handleTabChange('OTHERS')"
      >
        OTHERS
      </div>
      <div
        [ngClass]="[selectedTab == 'AUDITS'?'license_modify_sub_header_active':'',tempLicenseNo == '' ? 'disabled' : '']"
        (click)="handleTabChange('AUDITS')"
      >
        AUDITS
      </div>
      <div
        [ngClass]="[selectedTab == 'SR'?'license_modify_sub_header_active':'',tempLicenseNo == '' ? 'disabled' : '']"
        (click)="handleTabChange('SR')"
      >
        SERVICE REQUESTS
      </div>
    </div>
    <div class="license_modify_inp_main_div">
      <div
        class="license_modify_sub_sub_header subHeader"
        *ngIf="selectedTab == 'APPLICATION'"
      >
        <div
          [ngClass]="selectedSubTab == 'APPLICATION'?'license_modify_sub_header_active':''"
          (click)="handleSubTabChange('APPLICATION')"
        >
          COMPANY DETAILS
        </div>
        <div
          [ngClass]="[selectedSubTab == 'EMPLOYEES'?'license_modify_sub_header_active':'',tempLicenseNo == '' ? 'disabled' : '']"
          (click)="handleSubTabChange('EMPLOYEES')"
        >
          EMPLOYEES
        </div>
        <div
          [ngClass]="[selectedSubTab == 'PRODUCT'?'license_modify_sub_header_active':'',tempLicenseNo == '' ? 'disabled' : '']"
          (click)="handleSubTabChange('PRODUCT')"
        >
          PRODUCT
        </div>
        <div
          [ngClass]="[selectedSubTab == 'EQIPMENTS'?'license_modify_sub_header_active':'',tempLicenseNo == '' ? 'disabled' : '']"
          (click)="handleSubTabChange('EQIPMENTS')"
        >
          EQUIPMENT
        </div>
      </div>
      <!-- ------------------------------------------ LICENSE DETAILS --------------------------------------------------- -->
      <div
        class="license_modify_pd_main_div"
        style="padding: 2rem"
        *ngIf="selectedTab == 'LICENSE DETAILS'"
      >
        <div
          [ngClass]="roleId == '3'?'license_modify_pd_inp_main_div':'license_modify_pd_inp_main_div'"
        >
          <!-- <div class="license_modify_pd_inp_sub_div" *ngIf="addNew">
                        <label for="pin_code">License type</label>
                        <div class="dropdown">
                            <button class="btn dropdown-toggle " [ngClass]="!addNew?'input_disable updateSelect':'selectable'" type="button"
                                data-bs-toggle="dropdown"  aria-expanded="false"
                                style="width: 100%;text-align: left;min-height: 48px;">
                                <span *ngIf="licenseType == ''">&nbsp;</span>
                                <span *ngIf="licenseType != ''">
                                    {{licenseType=='new'?'New License':'Existing License'}}</span>

                            </button>
                            <ul class="dropdown-menu" style="width: 100%;">
                                <li class="dropdown-item"
                                    (click)="setLicense('new')">
                                    New License</li>
                                <li class="dropdown-item"
                                    (click)="setLicense('old')">
                                    Existing License</li>
                            </ul>
                        </div>
                    </div> -->

          <!-- Removed Name as Requested -->
          <!-- <div class="license_modify_pd_inp_sub_div" *ngIf="addNew">
                        <label for="name">Name</label>
                        <input type="text" name="license_name" [(ngModel)]="name">
                    </div> -->
          
          <div class="license_modify_pd_inp_sub_div" *ngIf="!addNew">
            <div style="display: flex; align-items: baseline; justify-content: space-between; margin-right: 8;">
            <label for="pin_code">License Number</label>
            <!-- <span 
              class="view_license_span" 
              (click)="licensesUrl ? viewLicence() : null"
              [ngClass]="{'disabled': !licensesUrl}"
              style="bottom: 4;"
              >
              View License
            </span> -->
            </div>

            <input
              type="text"
              name="licenseNo"
              [(ngModel)]="licensesNo"
              [disabled]="role== '1' || role== '2' || role == '3' || role== '5'|| role == '6'?false:true"
              [ngClass]="role== '1' || role== '2' || role == '3' || role == '5'|| role == '6'?'':'input_disable'"
            />


          </div>
          <div class="license_modify_pd_inp_sub_div" *ngIf="addNew">
            <label for="state">Company Incorporation</label>
            <ng-select
              class="multi-select"
              [items]="allCompIncorpType"
              bindLabel="name"
              bindValue="id"
              [multiple]="false"
              groupBy="selectedAllGroup"
              [selectableGroup]="true"
              name="company_incorporation"
              placeholder="Select company incorporation"
              (change)="onMaterialGroupChange('state')"
              [(ngModel)]="selectedCompIncorp"
            >
              <ng-template
                ng-optgroup-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
              </ng-template>
            </ng-select>
          </div>
          
          <div class="license_modify_pd_inp_sub_div">
            <label for="name">Name of Business</label>
            <input type="text" name="name" [(ngModel)]="businessName" />
          </div>
          <!-- <div class="license_modify_pd_inp_sub_div" *ngIf="!addNew">
            <label for="state">Kind Of Business (KOB)</label>
            <ng-select
              class="multi-select"
              [items]="allKOB"
              bindLabel="name"
              bindValue="id"
              [multiple]="true"
              groupBy="selectedAllGroup"
              [selectableGroup]="true"
              name="KOB"
              placeholder="Select KOB"
              (change)="onMaterialGroupChange('kob')"
              [(ngModel)]="selectedKOBBusiness"
            >
              <ng-template
                ng-optgroup-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
              </ng-template>
            </ng-select>
          </div> -->


          <div class="license_modify_pd_inp_sub_div">
            <label for="state">Kind Of Business (KOB){{addNew ? ' (Optional)' : ''}}</label>
            <ng-select
              class="multi-select"
              [items]="allKOB"
              bindLabel="name"
              bindValue="id"
              [multiple]="true"
              groupBy="selectedAllGroup"
              [selectableGroup]="true"
              name="KOB"
              [placeholder]="addNew ? 'Select KOB (Optional)' : 'Select KOB'"
              (change)="onMaterialGroupChange('kob')"
              [(ngModel)]="selectedKOBBusiness"
              [required]="!addNew"
              [clearable]="true"
            >
              <ng-template
                ng-optgroup-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
              </ng-template>
            </ng-select>
          </div>

          <div class="license_modify_pd_inp_sub_div">
            <label for="state">Type Of License</label>
            <ng-select
              class="multi-select scrollable-select"
              [items]="allTypeOfLicence"
              bindLabel="name"
              bindValue="name"
              [multiple]="false"
              groupBy="selectedAllGroup"
              [selectableGroup]="true"
              name="typeOfLicence"
              placeholder="Select License Type"
              (change)="onMaterialGroupChange($event)"
              [(ngModel)]="selectedLicenceType"
            >
              <ng-template
                ng-optgroup-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
              </ng-template>
            </ng-select>
          </div>

          <div class="license_modify_pd_inp_sub_div">
            <label for="address">Address</label>
            <input type="text" name="address" [(ngModel)]="address" />
          </div>

          <div class="license_modify_pd_inp_sub_div">
            <label for="state">Nature of Business</label>
            <ng-select
              class="multi-select"
              [items]="allBusinessNature"
              bindLabel="name"
              bindValue="id"
              [multiple]="false"
              groupBy="selectedAllGroup"
              [selectableGroup]="false"
              name="business_nature"
              placeholder="Select Business Nature"
              (change)="onMaterialGroupChange('kob')"
              [(ngModel)]="selectedBusinessNature"
            >
              <ng-template
                ng-optgroup-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
              </ng-template>
            </ng-select>
          </div>

          <div class="license_modify_pd_inp_sub_div" *ngIf="addNew">
            <label for="state">Select Premise Address Document Type</label>

            <ng-select
              class="multi-select"
              [items]="allPremiseAddress"
              bindLabel="name"
              bindValue="id"
              [multiple]="true"
              groupBy="selectedAllGroup"
              [selectableGroup]="true"
              name="premise_address"
              placeholder="Select Document Type"
              (change)="onMaterialGroupChange('kob')"
              [(ngModel)]="selectedPremiseAddress"
            >
              <ng-template
                ng-optgroup-tmp
                let-item="item"
                let-item$="item$"
                let-index="index"
              >
              </ng-template>
            </ng-select>
          </div>

          <!-- <div *ngIf="(addNew)"></div> -->


          <div class="license_modify_pd_inp_sub_div" *ngIf="!addNew">
            <label for="pin_code">License Validity End Date</label>
            <input
              type="date"
              placeholder="DD/MM/YYYY"
              name="validity"
              (keyup)="changeDate(licensesExpire)"
              (change)="changeDate(licensesExpire)"
              [(ngModel)]="licensesExpire"
            />
          </div>

          <div class="license_modify_pd_inp_sub_div">
            <label for="state">PIN Code</label>
            <input
              type="text"
              maxlength="6"
              name="pinCode"
              (keydown)="keydown($event)"
              [(ngModel)]="pinCode"
              (keyup)="getPincode($event)"
            />
          </div>

          <div class="license_modify_pd_inp_sub_div">
            <label for="current_trunover">Current Turnover (in Lakhs)</label>
            <input
              type="number"
              name="current_trunover"
              [(ngModel)]="currentTurnover"
              onlyN
            />
          </div>

          <div class="license_modify_pd_inp_sub_div" *ngIf="addNew">
            <label for="upload" *ngIf="addNew">Upload Premise Address Document</label>
            <!-- <label for="upload" *ngIf="(type == 'existing')">Upload FSSAI License</label> -->
            <div class="license_upload_sub_div">
              <input
                type="file"
                accept="image/png,image/jpg,.pdf"
                (change)="onImageChange($event)"
                #fileInput_image
                hidden
                id="file_inp"
              />
              <div (click)="fileInput_image.click()">Choose file</div>
              <div>
                <span *ngIf="fileName == ''">No file chosen</span>
                <span *ngIf="fileName != ''">{{fileName}}</span>
              </div>
            </div>
          </div>

          <!-- <div *ngIf="( type =='existing')"></div> -->

          <div
            class="license_modify_pd_inp_sub_div"
            *ngIf="!addNew "
          >
            <label for="pin_code">Status</label>
            <div class="dropdown">
              <button
                class="btn dropdown-toggle"
                [ngClass]="role!='2' && role!='1' && role!='6'?'input_disable updateSelect':'selectable'"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                [disabled]="(txtStatus == 'Expired' || txtStatus == 'Active') || (role!='2' && role!='1' && role!='6')  ? true : false"
                style="width: 100%; text-align: left; min-height: 48px"
              >
                <span *ngIf="txtStatus == ''">&nbsp;</span>
                <span

                  [ngClass]="txtStatus == 'Active'?'colorGreen':txtStatus == 'Expired'?'colorRed':'colorBlue'"
                  [ngClass]="tempStatus == 'Active'?'colorGreen':tempStatus == 'Expired'?'colorRed':'colorBlue'"
                >
                  {{tempStatus}}</span
                >
              </button>
              <ul class="dropdown-menu" style="width: 100%">
                <li
                  class="dropdown-item"
                  style="color: #12c058"
                  [ngClass]="selectedFilter.active?'filterActiveCss':''"
                  (click)="tempStatus = 'Active'"
                >
                  Active
                </li>
                <li
                  class="dropdown-item"
                  style="color: #d43738"
                  [ngClass]="txtStatus == 'Requested' ? 'input_disable noSelect' : ''"
                  [ngClass]="selectedFilter.expired?'filterActiveCss':''"
                  (click)="tempStatus = 'Expired'"
                >
                  Expired
                </li>
                <li
                  class="dropdown-item"
                  style="color: #1968b1"
                  [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                  (click)="tempStatus = 'Requested'"
                >
                  Requested
                </li>
              </ul>
            </div>
          </div>
          
          <div class="license_modify_pd_inp_sub_div">
            <label for="state">City</label>
            <input
              class="blocked"
              type="text"
              name="city"
              disabled
              [(ngModel)]="selectedCity"
            />
          </div>

          <div class="license_modify_pd_inp_sub_div">
            <label for="expected_trunover">Expected Turnover (in Lakhs)</label>
            <input
              type="number"
              name="expected_trunover"
              [(ngModel)]="expectedTurnover"
            />
          </div>

          <div *ngIf="addNew"></div>

          <div class="license_modify_pd_inp_sub_div" *ngIf="!addNew">
            <label for="upload" *ngIf="!addNew && type != 'existing'">Update FSSAI License</label>
            <label for="upload" *ngIf="type =='existing'">Upload FSSAI License</label
            >
            <div class="license_upload_sub_div">
              <input
                type="file"
                accept="image/png,image/jpg,.pdf"
                (change)="onImageChange($event)"
                #fileInput_image
                hidden
                id="file_inp"
              />
              <div (click)="fileInput_image.click()">Choose file</div>
              <div>
                <span *ngIf="fileName == ''">No file chosen</span>
                <span *ngIf="fileName != ''">{{fileName}}</span>
              </div>
            </div>
          </div>

          <div class="license_modify_pd_inp_sub_div">
            <label for="state">State</label>
            <ng-select
              class="multi-select blockedd"
              [disabled]="true"
              [items]="allState"
              bindLabel="name"
              bindValue="name"
              [multiple]="false"
              groupBy="selectedAllGroup"
              [selectableGroup]="true"
              name="KOB"
              (change)="onMaterialGroupChange('state')"
              [(ngModel)]="selectedState"
            >
              <ng-template
                style="background-color: red"
                ng-optgroup-tmp
                let-item="item"
                class="blocked"
                let-item$="item$"
                let-index="index"
              >
              </ng-template>
            </ng-select>
          </div>


          <div class="license_modify_pd_inp_sub_div">
            <label for="email">Email Address</label>
            <input type="email" name="email" [(ngModel)]="emailId" />
          </div>
          <div class="grid grid-cols-2 gap-x-6 gap-y-2 w-96 text-sm" *ngIf="!addNew && (roleId==1 || roleId==6 || roleId==2)">
            <div class="flex items-center">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexCheckDefault1"
                [(ngModel)]="annualReportFilingFlag"
                [checked]="annualReportFilingFlag"
              />
              <label class="form-check-label license_term_condition ml-2 text-xs" for="flexCheckDefault1">
                Annual Return Filing
              </label>
            </div>
          
            <div class="flex items-center">
              <input
                class="form-check-input" 
                type="checkbox"
                id="flexCheckDefault2"
                [(ngModel)]="mandatoryAuditFlag"
                [checked]="mandatoryAuditFlag"
              />
              <label class="form-check-label license_term_condition ml-2 text-xs" for="flexCheckDefault2">
                Mandatory Audit
              </label>
            </div>
          
            <div class="flex items-center">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexCheckDefault3"
                [(ngModel)]="labReportUploadFlag"
                [checked]="labReportUploadFlag"
              />
              <label class="form-check-label license_term_condition ml-2 text-xs" for="flexCheckDefault3">
                Lab Report Upload
              </label>
            </div>
          </div>
          <div *ngIf="(addNew || type =='existing')"></div>
          <div></div>
          <div class="license_modify_pd_inp_sub_div">
            <label for="number">Mobile Number</label>
            <input type="number" name="phone" [(ngModel)]="mobileNo" />
          </div>


         
          <!-- <div class="license_modify_pd_inp_sub_div" *ngIf="roleId==3">
                        <label for="address">City</label>
                        <input type="text" name="address" [(ngModel)]="city"> -->
          <!-- </div> -->










          <!-- <div class="license_modify_pd_inp_sub_div">
                        <label for="pin_code">Progress (%)</label>
                        <input type="number" min="0" max="100">
                    </div> -->
        </div>
        
        <!-- <div style="margin: 2rem 0 0 0" *ngIf="data.sr!=true">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
            [(ngModel)]="tcFlag"
          />
          <label class="form-check-label license_term_condition" for="">
            I agree to the <span (click)="openTerms()">terms of services</span>
          </label>
        </div> -->

      </div>

      <!-- ------------------------------------------ APPLICATION  COMAPNY--------------------------------------------------- -->
      <div
        class="license_modify_pd_main_div"
        *ngIf="selectedTab == 'APPLICATION' && selectedSubTab == 'APPLICATION'"
      >
        <div class="license_table_main_div">
          <table
            mat-table
            [dataSource]="applicationDataSource"
            class="mat-elevation-z8"
            style="width: 100%; position: relative"
          >
            <!-- Sl NO -->
            <ng-container matColumnDef="sl_no">
              <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
              <td class="" mat-cell *matCellDef="let element; let i = index">
                {{i+1}}
              </td>
            </ng-container>

            <!-- Document Name  -->

            <ng-container matColumnDef="doc_name">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Document Name
              </th>
              <td
                class="common_table_data"
                mat-cell
                *matCellDef="let element"
                style="color: #7893ec; cursor: pointer"
                (click)="updateDoc('application',element)"
              >
                {{element.document_name}}
              </td>
            </ng-container>

            <!-- Description  -->

            <ng-container matColumnDef="description">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Description
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{element.description}}
              </td>
            </ng-container>

            <!--  View License -->

            <ng-container matColumnDef="view_doc">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                View Document
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <img
                [src]="element.documents ? '../../../assets/img/carbon_view.svg' : '../../../assets/img/eye-crossed.svg'"
                alt="View License"
                [style.cursor]="element.documents ? 'pointer' : 'not-allowed'"
                (click)="element.documents ? viewLicence(element.documents) : null"
              />
              </td>
            </ng-container>

            <!-- Status -->

            <ng-container matColumnDef="status">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Status
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span> {{element.status}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="sr">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Service Request ID
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span>
                  {{element.service_requests_id ?element.service_requests_id :
                  'None' }}</span
                >
              </td>
            </ng-container>

            <!-- Modify -->

            <ng-container matColumnDef="modify">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Modify
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <img
                  src="../../../assets/img/edit_icon.svg"
                  alt=""
                  style="cursor: pointer"
                  (click)="updateDoc('application',element)"
                />
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="applicationDisplayedColumns"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: applicationDisplayedColumns;"
            ></tr>
          </table>
        </div>
      </div>

      <!-- ------------------------------------------ APPLICATION  EMPLOYEES--------------------------------------------------- -->
      <div
        class="license_modify_pd_main_div"
        *ngIf="selectedTab == 'APPLICATION' && selectedSubTab == 'EMPLOYEES'"
      >
        <div class="license_table_main_div">
          <table
            mat-table
            [dataSource]="masterEmployeeDataSource"
            class="mat-elevation-z8"
            style="width: 100%; position: relative"
          >
            <!-- Employee ID  -->

            <ng-container matColumnDef="emp_id">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Employee ID
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{element.employee_id}}
              </td>
            </ng-container>

            <!-- Employee Name  -->

            <ng-container matColumnDef="emp_name">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Employee Name
              </th>
              <td
                class="common_table_data"
                mat-cell
                *matCellDef="let element"
                style="color: #7893ec; cursor: pointer"
                (click)="updateDoc('employees',element)"
              >
                {{element.employee_name}}
              </td>
            </ng-container>


            <!--  ID Proof Upload -->

            <ng-container matColumnDef="view_doc">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                ID Proof
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <img
                [src]="element.documents ? '../../../assets/img/carbon_view.svg' : '../../../assets/img/eye-crossed.svg'"
                alt="View License"
                [style.cursor]="element.documents ? 'pointer' : 'not-allowed'"
                (click)="element.documents ? viewLicence(element.documents) : null"
              />
              </td>
            </ng-container>

            <!-- Training Record -->

            <ng-container matColumnDef="training_record">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Training Record
              </th>
              <td
                class="common_table_data"
                mat-cell
                *matCellDef="let element"
                [ngStyle]="{ 
                  'color': element.hasTrainingRecord ? 'green' : 'red', 
                  'cursor': 'pointer' 
                }"
                (click)=" getTrainingRecordsForEmp(element.employee_id) "
              >
              {{ element.hasTrainingRecord ? 'Yes' : 'No' }}

              </td>
            </ng-container>
            
             <!-- Health Record -->

            <ng-container matColumnDef="health_record">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Health Record
              </th>
              <td
                class="common_table_data"
                mat-cell
                *matCellDef="let element"
                [ngStyle]="{ 
                  'color': element.hasHealthRecord ? 'green' : 'red', 
                  'cursor':'pointer' 
                }"
                (click)="getHealthRecordsForEmp(element.employee_id)"

              >
                {{element.hasHealthRecord ? 'Yes' : 'No'}}
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="masterEmployeeDisplayedColumns"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: masterEmployeeDisplayedColumns;"
            ></tr>
          </table>
        </div>
      </div>

      <!-- ------------------------------------------ APPLICATION  PRODUCT--------------------------------------------------- -->
      <div
        class="license_modify_pd_main_div"
        *ngIf="selectedTab == 'APPLICATION' && selectedSubTab == 'PRODUCT'"
      >
        <div class="license_table_main_div">
          <table
            mat-table
            [dataSource]="masterProductDataSource"
            class="mat-elevation-z8"
            style="width: 100%; position: relative"
          >
            <!-- Product ID  -->

            <ng-container matColumnDef="emp_id">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Product ID
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{element.product_id}}
              </td>
            </ng-container>

            <!-- Product Name  -->

            <ng-container matColumnDef="emp_name">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Product Name
              </th>
              <td
                class="common_table_data"
                mat-cell
                *matCellDef="let element"
                style="color: #7893ec; cursor: pointer"
                (click)="updateDoc('application',element)"
              >
                {{element.product_name}}
              </td>
            </ng-container>

            <!--  View License -->

            <ng-container matColumnDef="view_doc">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                View Document
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <img
                [src]="element.documents ? '../../../assets/img/carbon_view.svg' : '../../../assets/img/eye-crossed.svg'"
                alt="View License"
                [style.cursor]="element.documents ? 'pointer' : 'not-allowed'"
                (click)="element.documents ? viewLicence(element.documents) : null"
              />
              </td>
            </ng-container>

            <!-- Testing Record -->

            <ng-container matColumnDef="testing_record">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Testing Record
            </th>
            <td
              class="common_table_data"
              mat-cell
              *matCellDef="let element"
              [ngStyle]="{ 
                'color': element.hasTestingRecord ? 'green' : 'red', 
                'cursor': 'pointer' 
              }"
              (click)="getTestingRecordsForProd(element.product_id) "

            >
              {{element.hasTestingRecord ? 'Yes' : 'No'}}
            </td>
          </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="masterProductDisplayedColumns"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: masterProductDisplayedColumns;"
            ></tr>
          </table>
        </div>
      </div>

      <!-- ------------------------------------------ APPLICATION  EQIPMENT--------------------------------------------------- -->
      <div
        class="license_modify_pd_main_div"
        *ngIf="selectedTab == 'APPLICATION' && selectedSubTab == 'EQIPMENTS'"
      >
        <div class="license_table_main_div">
          <table
            mat-table
            [dataSource]="masterEqipmentDataSource"
            class="mat-elevation-z8"
            style="width: 100%; position: relative; "
          >

              <!-- Equipment ID  -->

              <ng-container matColumnDef="emp_id">
                <th class="common_table_data"   mat-header-cell *matHeaderCellDef>Equipment ID </th>
                <td class="common_table_data" mat-cell *matCellDef="let element"> {{element.instrument_id}}
                </td>
            </ng-container>

            <!-- Instrument Name  -->

              <ng-container matColumnDef="emp_name">
                  <th class="common_table_data" mat-header-cell *matHeaderCellDef>Instrument Name</th>
                  <td class="common_table_data" mat-cell *matCellDef="let element" style="color: #7893ec;cursor: pointer;" (click)="updateDoc('application',element)"> {{element.equipment_name}}
                  </td>
              </ng-container>



            <!--  View License -->

            <ng-container matColumnDef="view_doc">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                View Document
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <img
                [src]="element.documents ? '../../../assets/img/carbon_view.svg' : '../../../assets/img/eye-crossed.svg'"
                alt="View License"
                [style.cursor]="element.documents ? 'pointer' : 'not-allowed'"
                (click)="element.documents ? viewLicence(element.documents) : null"
              />
              </td>
            </ng-container>

            <ng-container matColumnDef="calibration_records">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Calibration Record
              </th>
              <td
                class="common_table_data"
                mat-cell
                *matCellDef="let element"
                [ngStyle]="{ 
                  'color': element.hasCalibrationRecord ? 'green' : 'red', 
                  'cursor': 'pointer'
                }"
                (click)="getCalibrationRecordsForEqmnt(element.instrument_id)"
  
              >
                {{element.hasCalibrationRecord ? 'Yes' : 'No'}}
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="masterEqipmentDisplayedColumns"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: masterEqipmentDisplayedColumns;"
            ></tr>
          </table>
        </div>
      </div>
      <!-- ------------------------------------------ TESTING --------------------------------------------------- -->
      <div class="license_modify_pd_main_div" *ngIf="selectedTab == 'TESTING'">
        <div  class="filteredResult" *ngIf="filteredTestingData != null" >
          Product ID- {{ tempId }}   
          <img
          class="clearFilter"
          src="../../../assets/img/close.svg"
          alt="close"
          (click)="restoreFilteredData()"
        />
        </div>
        <div class="license_table_main_div">
          <table
            mat-table
            [dataSource]="filteredTestingData ? filteredTestingData : testingDataSource"
            class="mat-elevation-z8"
            style="width: 100%; position: relative"
          >
            <!-- Sl NO -->
            <ng-container matColumnDef="sl_no">
              <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
              <td class="" mat-cell *matCellDef="let element; let i = index">
                {{i+1}}
              </td>
            </ng-container>

            <!-- Product Name  -->

            <ng-container matColumnDef="product_name">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Product Name
              </th>
              <td
                class="common_table_data"
                mat-cell
                *matCellDef="let element"
                (click)="updateDoc('testing',element)"
                style="color: #7893ec; cursor: pointer"
              >
                {{element.product_name}}
              </td>
            </ng-container>

            <!-- Product ID  -->

            <ng-container matColumnDef="product_id">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Product ID
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{element.product_id}}
              </td>
            </ng-container>

            <!-- Report Date  -->

            <ng-container matColumnDef="report_date">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Report Date
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{element.report_date}}
              </td>
            </ng-container>

            <!--  View License -->

            <ng-container matColumnDef="view_doc">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                View Document
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <img
                [src]="element.documents ? '../../../assets/img/carbon_view.svg' : '../../../assets/img/eye-crossed.svg'"
                alt="View License"
                [style.cursor]="element.documents ? 'pointer' : 'not-allowed'"
                (click)="element.documents ? viewLicence(element.documents) : null"
              />
              </td>
            </ng-container>

            <!-- Status -->

            <ng-container matColumnDef="status">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Status
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span> {{element.status}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="sr">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Service Request ID
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span>
                  {{element.service_requests_id ?element.service_requests_id :
                  'None' }}</span
                >
              </td>
            </ng-container>

            <!-- Modify -->

            <ng-container matColumnDef="modify">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Modify
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <img
                  src="../../../assets/img/edit_icon.svg"
                  alt=""
                  style="cursor: pointer"
                  (click)="updateDoc('testing',element)"
                />
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="testingDisplayedColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: testingDisplayedColumns;"
            ></tr>
          </table>
        </div>
      </div>
      <!-- ------------------------------------------ TRAINING --------------------------------------------------- -->
      <div class="license_modify_pd_main_div" *ngIf="selectedTab == 'TRAINING'">
        <div  class="filteredResult" *ngIf="filteredTrainingData != null" >
          Employee ID- {{ tempId }}   
          <img
          class="clearFilter"
          src="../../../assets/img/close.svg"
          alt="close"
          (click)="restoreFilteredData()"
        />
        </div>
        <div class="license_table_main_div">
          <table
            mat-table
            [dataSource]="filteredTrainingData? filteredTrainingData : trainingDataSource"
            class="mat-elevation-z8"
            style="width: 100%; position: relative"
          >
            <!-- Sl NO -->
            <ng-container matColumnDef="sl_no">
              <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
              <td class="" mat-cell *matCellDef="let element; let i = index">
                {{i+1}}
              </td>
            </ng-container>

            <!-- Emp. Name  -->

            <ng-container matColumnDef="emp_name">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Employee Name
              </th>
              <th></th>
              <td
                class="common_table_data"
                mat-cell
                *matCellDef="let element"
                (click)="updateDoc('training',element)"
                style="color: #7893ec; cursor: pointer"
              >
                {{element.employee_name}}
              </td>
            </ng-container>

            <!-- Emp. ID  -->

            <ng-container matColumnDef="emp_id">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Employee ID
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{element.employee_id}}
              </td>
            </ng-container>

            <!-- Training Date  -->

            <ng-container matColumnDef="training_date">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Training Date
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{element.training_date}}
              </td>
            </ng-container>

            <!--  View Document -->

            <ng-container matColumnDef="view_doc">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                View Document
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <img
                [src]="element.documents ? '../../../assets/img/carbon_view.svg' : '../../../assets/img/eye-crossed.svg'"
                alt="View License"
                [style.cursor]="element.documents ? 'pointer' : 'not-allowed'"
                (click)="element.documents ? viewLicence(element.documents) : null"
              />
              </td>
            </ng-container>

            <!-- Status -->

            <ng-container matColumnDef="status">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Status
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span> {{element.status}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="sr">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Service Request ID
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span>
                  {{element.service_requests_id ?element.service_requests_id :
                  'None' }}</span
                >
              </td>
            </ng-container>
            <!-- Modify -->

            <ng-container matColumnDef="modify">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Modify
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <img
                  src="../../../assets/img/edit_icon.svg"
                  alt=""
                  style="cursor: pointer"
                  (click)="updateDoc('training',element)"
                />
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="trainingDisplayedColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: trainingDisplayedColumns;"
            ></tr>
          </table>
        </div>
      </div>
      <!-- ------------------------------------------ HEALTH --------------------------------------------------- -->
      <div class="license_modify_pd_main_div" *ngIf="selectedTab == 'HEALTH'">
        <div  class="filteredResult" *ngIf="filteredHealthData != null" >
          Employee ID- {{ tempId }}   
          <img
          class="clearFilter"
          src="../../../assets/img/close.svg"
          alt="close"
          (click)="restoreFilteredData()"
        />
        </div>
        <div class="license_table_main_div">
          <table
            mat-table
            [dataSource]="filteredHealthData? filteredHealthData : healthDataSource"
            class="mat-elevation-z8"
            style="width: 100%; position: relative"
          >
            <!-- Sl NO -->
            <ng-container matColumnDef="sl_no">
              <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
              <td class="" mat-cell *matCellDef="let element; let i = index">
                {{i+1}}
              </td>
            </ng-container>

            <!-- Emp. Name  -->

            <ng-container matColumnDef="emp_name">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Employee Name
              </th>
              <th></th>
              <td
                class="common_table_data"
                mat-cell
                *matCellDef="let element"
                (click)="updateDoc('health',element)"
                style="color: #7893ec; cursor: pointer"
              >
                {{element.employee_name}}
              </td>
            </ng-container>

            <!-- Emp. ID  -->

            <ng-container matColumnDef="emp_id">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Employee ID
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{element.employee_id}}
              </td>
            </ng-container>

            <!-- Report Date  -->

            <ng-container matColumnDef="report_date">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Report Date
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{element.report_date}}
              </td>
            </ng-container>

            <!--  View Document -->

            <ng-container matColumnDef="view_doc">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                View Document
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <img
                [src]="element.documents ? '../../../assets/img/carbon_view.svg' : '../../../assets/img/eye-crossed.svg'"
                alt="View License"
                [style.cursor]="element.documents ? 'pointer' : 'not-allowed'"
                (click)="element.documents ? viewLicence(element.documents) : null"
              />
              </td>
            </ng-container>

            <!-- Status -->

            <ng-container matColumnDef="status">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Status
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span> {{element.status}}</span>
              </td>
            </ng-container>

            <!-- Modify -->
            <ng-container matColumnDef="sr">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Service Request ID
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span>
                  {{element.service_requests_id ?element.service_requests_id :
                  'None' }}</span
                >
              </td>
            </ng-container>

            <ng-container matColumnDef="modify">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Modify
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <img
                  src="../../../assets/img/edit_icon.svg"
                  alt=""
                  style="cursor: pointer"
                  (click)="updateDoc('health',element)"
                />
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="healthDisplayedColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: healthDisplayedColumns;"
            ></tr>
          </table>
        </div>
      </div>
      <!-- ------------------------------------------ CALIBRATION --------------------------------------------------- -->
      <div
        class="license_modify_pd_main_div"
        *ngIf="selectedTab == 'CALIBRATION'"
      >
      <div  class="filteredResult" *ngIf="filteredCalibrationData != null" >
        Equipment ID- {{ tempId  }}   
        <img
        class="clearFilter"
        src="../../../assets/img/close.svg"
        alt="close"
        (click)="restoreFilteredData()"
      />
      </div>
        <div class="license_table_main_div">
          <table
            mat-table
            [dataSource]="filteredCalibrationData? filteredCalibrationData : calibrationDataSource"
            class="mat-elevation-z8"
            style="width: 100%; position: relative"
          >
            <!-- Sl NO -->
            <ng-container matColumnDef="sl_no">
              <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
              <td class="" mat-cell *matCellDef="let element; let i = index">
                {{i+1}}
              </td>
            </ng-container>

            <!-- Instrument Name  -->

            <ng-container matColumnDef="instrument_name">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Instrument Name
              </th>
              <th></th>
              <td
                class="common_table_data"
                mat-cell
                *matCellDef="let element"
                (click)="updateDoc('calibiration',element)"
                style="color: #7893ec; cursor: pointer"
              >
                {{element.instrument_name}}
              </td>
            </ng-container>

            <!-- Equipment ID  -->

            <ng-container matColumnDef="equipment_id">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Equipment ID
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{element.equipment_id}}
              </td>
            </ng-container>

            <!-- Calibration Date  -->

            <ng-container matColumnDef="calibration_date">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Calibration Date
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{element.calibration_date}}
              </td>
            </ng-container>

            <!--  View Document -->

            <ng-container matColumnDef="view_doc">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                View Document
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <img
                [src]="element.documents ? '../../../assets/img/carbon_view.svg' : '../../../assets/img/eye-crossed.svg'"
                alt="View License"
                [style.cursor]="element.documents ? 'pointer' : 'not-allowed'"
                (click)="element.documents ? viewLicence(element.documents) : null"
              />
              </td>
            </ng-container>

            <!-- Status -->

            <ng-container matColumnDef="status">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Status
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span> {{element.status}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="sr">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Service Request ID
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span>
                  {{element.service_requests_id ?element.service_requests_id :
                  'None' }}</span
                >
              </td>
            </ng-container>
            <!-- Modify -->

            <ng-container matColumnDef="modify">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Modify
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <img
                  src="../../../assets/img/edit_icon.svg"
                  alt=""
                  style="cursor: pointer"
                  (click)="updateDoc('calibiration',element)"
                />
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="calibrationDisplayedColumns"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: calibrationDisplayedColumns;"
            ></tr>
          </table>
        </div>
      </div>
      <!-- ------------------------------------------ SR --------------------------------------------------- -->
      <div class="license_modify_pd_main_div" *ngIf="selectedTab == 'SR'">
        <div class="license_table_main_div">
          <table
            mat-table
            [dataSource]="srDataSource"
            class="mat-elevation-z8"
            style="width: 100%; position: relative"
          >
            <!-- Sl NO -->
            <ng-container matColumnDef="sl_no">
              <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
              <td class="" mat-cell *matCellDef="let element; let i = index">
                {{i+1}}
              </td>
            </ng-container>

            <!-- Document Name  -->

            <ng-container matColumnDef="id">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                ID
              </th>
              <th></th>
              <td
                class="common_table_data"
                mat-cell
                *matCellDef="let element"
                style="color: #6386fa; cursor: pointer"
                (click)="modifyAppUser(element.id)"
              >
                {{element.id}}
              </td>
            </ng-container>

            <!-- Description  -->

            <ng-container matColumnDef="services">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Service Request Name
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{element.services}}
              </td>
            </ng-container>

            <!-- Expiry Date  -->

            <ng-container matColumnDef="fssai_regd_no">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                License Number
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{element.fssai_regd_no}}
              </td>
            </ng-container>

            <!--  View Document -->

            <ng-container matColumnDef="business_name">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Business Name
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{element.business_name}}
              </td>
            </ng-container>

            <!-- Status -->

            <ng-container matColumnDef="state">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                State
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span> {{element.state}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="am">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Account Manager
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span>
                  {{element.account_manager ? element.account_manager :
                  'N/A'}}</span
                >
              </td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Created Date
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span> {{element.date.split('-').reverse().join('/')}}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="updateddate">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Updated Date
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span>
                  {{element.last_modified?.datetime.split('T')[0].split('-').reverse().join('/')}}</span
                >
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Status
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span> {{element.status.replace('_',' ')}}</span>
              </td>
            </ng-container>

            <!-- Modify -->

            <!-- <ng-container matColumnDef="modify">
                            <th class="common_table_data" mat-header-cell *matHeaderCellDef> Modify</th>
                            <td class="common_table_data" mat-cell *matCellDef="let element">
                                <img src="../../../assets/img/edit_icon.svg" alt="" style="cursor: pointer;"
                                (click)="modifyAppUser(element.id)">
                            </td>
                        </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="srDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: srDisplayedColumns;"></tr>
          </table>
        </div>
      </div>
      <!-- ------------------------------------------ OTHER --------------------------------------------------- -->
      <div class="license_modify_pd_main_div" *ngIf="selectedTab == 'OTHERS'">
        <div class="license_table_main_div">
          <table
            mat-table
            [dataSource]="otherDataSource"
            class="mat-elevation-z8"
            style="width: 100%; position: relative"
          >
            <!-- Sl NO -->
            <ng-container matColumnDef="sl_no">
              <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
              <td class="" mat-cell *matCellDef="let element; let i = index">
                {{i+1}}
              </td>
            </ng-container>

            <!-- Document Name  -->

            <ng-container matColumnDef="doc_name">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Document Name
              </th>
              <th></th>
              <td
                class="common_table_data"
                mat-cell
                *matCellDef="let element"
                (click)="updateDoc('others',element)"
                style="color: #7893ec; cursor: pointer"
              >
                {{element.document_name}}
              </td>
            </ng-container>

            <!-- Description  -->

            <ng-container matColumnDef="description">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Description
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{element.description}}
              </td>
            </ng-container>

            <!-- Expiry Date  -->

            <ng-container matColumnDef="expiry_date">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Expiry Date
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{element.expiration_date}}
              </td>
            </ng-container>

            <!--  View Document -->

            <ng-container matColumnDef="view_doc">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                View Document
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <img
                [src]="element.documents ? '../../../assets/img/carbon_view.svg' : '../../../assets/img/eye-crossed.svg'"
                alt="View License"
                [style.cursor]="element.documents ? 'pointer' : 'not-allowed'"
                (click)="element.documents ? viewLicence(element.documents) : null"
              />
              </td>
            </ng-container>

            <!-- Status -->

            <ng-container matColumnDef="status">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Status
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span> {{element.status}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="sr">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Service Request ID
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span>
                  {{element.service_requests_id ?element.service_requests_id :
                  'None' }}</span
                >
              </td>
            </ng-container>
            <!-- Modify -->

            <ng-container matColumnDef="modify">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Modify
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <img
                  src="../../../assets/img/edit_icon.svg"
                  alt=""
                  style="cursor: pointer"
                  (click)="updateDoc('others',element)"
                />
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="otherDisplayedColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: otherDisplayedColumns;"
            ></tr>
          </table>
        </div>
      </div>
      <!-- ------------------------------------------ AUDITS --------------------------------------------------- -->
      <div class="license_modify_pd_main_div" *ngIf="selectedTab == 'AUDITS'">
        <div class="license_table_main_div">
          <table
            mat-table
            [dataSource]="auditsDataSource"
            class="mat-elevation-z8"
            style="width: 100%; position: relative"
          >
            <!-- Sl NO -->
            <ng-container matColumnDef="sl_no">
              <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
              <td class="" mat-cell *matCellDef="let element; let i = index">
                {{i+1}}
              </td>
            </ng-container>

            <!-- Document Name  -->

            <ng-container matColumnDef="doc_name">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Audit Name
              </th>
              <td
                class="common_table_data"
                mat-cell
                *matCellDef="let element"
                style="color: #7893ec; cursor: pointer"
                (click)="updateDoc('audits',element)"
              >
                {{element.audit_name}}
              </td>
            </ng-container>

            <!-- Description  -->

            <ng-container matColumnDef="description">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Audit Id
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{element.audit_id}}
              </td>
            </ng-container>

            <!--  View License -->

            <ng-container matColumnDef="date">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Audit Date
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{element.audit_date}}
              </td>
            </ng-container>

            <ng-container matColumnDef="view_doc">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                View Document
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <img
                [src]="element.documents ? '../../../assets/img/carbon_view.svg' : '../../../assets/img/eye-crossed.svg'"
                alt="View License"
                [style.cursor]="element.documents ? 'pointer' : 'not-allowed'"
                (click)="element.documents ? viewLicence(element.documents) : null"
              />
              </td>
            </ng-container>
            <!-- Status -->

            <ng-container matColumnDef="status">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Status
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span> {{element.status}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="score">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Score
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span> {{element.score }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="sr">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Service Request ID
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span>
                  {{element.service_requests_id ?element.service_requests_id :
                  'None' }}</span
                >
              </td>
            </ng-container>
            <!-- Modify -->

            <ng-container matColumnDef="modify">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Modify
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <img
                  src="../../../assets/img/edit_icon.svg"
                  alt=""
                  style="cursor: pointer"
                  (click)="updateDoc('audits',element)"
                />
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="auditsDisplayedColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: auditsDisplayedColumns;"
            ></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div style="flex: 1">&nbsp;</div>
  <div
    class="license_modify_btn_main_div"
    *ngIf="selectedTab == 'LICENSE DETAILS'"
    style="display: flex; flex-direction: row;"
  >
  <!-- <div style="margin: 0.5rem; " *ngIf="data.sr!=true">
    
    <input
      class="form-check-input"
      type="checkbox"
      value=""
      id="flexCheckDefault"
      [(ngModel)]="tcFlag"
    />
    <label class="form-check-label license_term_condition" for="">
      I agree to the <span (click)="openTerms()">terms of services</span>
    </label>
  </div> -->
  <div class="m-2" *ngIf="data.sr!=true">
    <div class="d-flex align-items-center gap-2">
      <div class="form-check form-check-inline" style="padding-top: 3px;">
        <input
          class="form-check-input"
          type="radio"
          name="termsAgreement"
          id="agreeYes"
          [value]="true"
          [(ngModel)]="tcFlag"
          (change)="updateTermsAgreement(true)"
        />
        <label class="form-check-label" for="agreeYes" style="font-size: 13px;">
          Yes
        </label>
      </div>
      
      <div class="form-check form-check-inline" style="padding-top: 3px;">
        <input
          class="form-check-input"
          type="radio"
          name="termsAgreement"
          id="agreeNo"
          [value]="false"
          [(ngModel)]="tcFlag"
          (change)="updateTermsAgreement(false)"
        />
        <label class="form-check-label" for="agreeNo" style="font-size: 13px;">
          No
        </label>
      </div>
      
      <label class="form-check-label license_term_condition mb-0">
        I agree to the <span (click)="openTerms()">terms of services</span>
      </label>
    </div>
  </div>
    <div class="license_modify_pd_btn_div">
      <!-- <div (click)="close()">close</div> -->
      <div
        *ngIf="!addNew && type =='new' && data.sr!=true"
        (click)="updatePersonalDtls()"
      >
        UPDATE
      </div>
      <div *ngIf="addNew" (click)="addNewLicenseDtls()">Submit Request</div>
      <div *ngIf="!addNew && type =='existing'" (click)="addNewLicenseDtls()">
        UPLOAD
      </div>
    </div>
  </div>
  <div
  class="license_modify_btn_main_div"
  *ngIf="selectedTab != 'LICENSE DETAILS' 
          && selectedTab != 'SR' 
          && selectedSubTab != 'EMPLOYEES' 
          && selectedSubTab != 'PRODUCT' 
          && selectedSubTab != 'EQIPMENTS' 
          && (roleId == 1 || roleId == 2 || roleId == 3 || roleId == 5 || roleId == 6)">
  
    <div 
    class="license_modify_pd_btn_div" 
    [ngStyle]="{'display': 'flex', 'align-items': 'center', 'justify-content': selectedTab == 'AUDITS' ? 'space-between' : 'flex-end'}"
    >
    
    <!-- Graph Icons (only visible in 'AUDITS' tab) -->
    <div *ngIf="selectedTab == 'AUDITS'" style="background-color: transparent; padding: 0; gap: 0px;">
      <img class="graphIcons" src="../../../assets/img/line_graph.svg" alt="Line Graph" (click)="openChart('line')" />
      <img class="graphIcons" src="../../../assets/img/bar_graph.svg" alt="Bar Graph" (click)="openChart('bar')" />
      <img class="graphIcons" src="../../../assets/img/curve_graph.svg" alt="Curve Graph" (click)="openChart('area')" style="padding: 0px;" />
    </div>
    
    <!-- Add New Document Button -->
    <div *ngIf="!(roleId == 3 || roleId == 5 || roleId == 7) || selectedTab != 'APPLICATION'" >
      <div
        class="license_modify_pd_btn_div" style="justify-content: flex-end"
        *ngIf="!(roleId == 3 || roleId == 5 || roleId == 7) || selectedTab != 'APPLICATION'"
        (click)="tempLicenseNo != '' ? addSupportingDocs(selectedTab) : addLicenseToast()"
      >
        Add New Document
      </div>
    </div>

  </div>
</div>
<!-- <div
class="license_modify_btn_main_div"
*ngIf="selectedTab != 'LICENSE DETAILS' && (roleId==1 || roleId==3 || roleId==5 || roleId==2) && selectedTab != 'SR'  && ( selectedSubTab =='EMPLOYEES' ||  selectedSubTab =='PRODUCT' || selectedSubTab =='EQIPMENTS')" 
>

</div> -->
  <div
    class="license_modify_btn_main_div"
    *ngIf="selectedTab != 'LICENSE DETAILS' && (roleId==1 || roleId==3 || roleId==5 || roleId==2 || roleId==6) && selectedTab != 'SR'  && ( selectedSubTab =='EMPLOYEES' ||  selectedSubTab =='PRODUCT' || selectedSubTab =='EQIPMENTS')"
  >
  <div class="license_modify_pd_btn_div" style="display: flex; justify-content: space-between; align-items: center;">
    <!-- Bulk Upload (Left-aligned) -->
    <div>
      <div (click)=" tempLicenseNo != '' ? addSupportingDocs(selectedTab+'_BULK') : addLicenseToast()">
        Bulk Upload {{ selectedSubTab == 'EMPLOYEES' ? 'Employee' : selectedSubTab == 'EQIPMENTS' ? 'Equipment' : 'Product' }}
      </div>
    </div>
  
    <!-- Add New (Right-aligned) -->
    <div>
      <div (click)="tempLicenseNo != '' ? addSupportingDocs(selectedTab) : addLicenseToast()">
        Add New {{ selectedSubTab == 'EMPLOYEES' ? 'Employee' : selectedSubTab == 'EQIPMENTS' ? 'Equipment' : 'Product' }}
      </div>
    </div>
  </div>

  </div>
  <div
    class="license_modify_btn_main_div"
    *ngIf="selectedTab != 'LICENSE DETAILS' && (roleId==1 || roleId==6 ||  roleId==3 || roleId==5 || roleId==2 || roleId == 7) && selectedTab == 'SR' "
  > 
  <div class="license_modify_pd_btn_div" style="justify-content: flex-end">
    <div
    (click)="tempLicenseNo != '' ? addRequest() : addLicenseToast()"
    >
      Create New Service Request
    </div>
  </div>
</div>
</div>

<style>
  .add_user_header {
    display: flex;
    gap: 26px;
    color: #484a46;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: 0.28px;
  }

  .add_user_header input[type="radio"] {
    cursor: pointer;
    accent-color: #0a9b45;
  }

  .add_user_header span {
    margin-left: 10px;
  }
  .license_table_main_div {
    max-height: 35rem;
  }
</style>
