<div class="web_user_main_div">
  <div style="    
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;">
    <div class="header">USER MANAGEMENT</div>
    <div class="adding_btn_main_div">
      <!-- <button (click)="addNewUser()"><img src="../../../assets/img/plus_icon.svg" alt="">
                <span>ADD USERS</span>
            </button> -->
  </div>
  <div class="swiching_web_user_btn_main_div">
    <button
      class="swiching_web_user_btn"
      [ngClass]="selectedTable == 'eu' ? 'swiching_web_user_btn_active' : ''"
      (click)="handleSwicthWebUser('eu')"
    >
      ENTERPRISE USER
    </button>
    <button
      class="swiching_web_user_btn"
      [ngClass]="selectedTable == 'ces' ? 'swiching_web_user_btn_active' : ''"
      (click)="handleSwicthWebUser('ces')"
    >
      RETAIL USER
    </button>
    <button
      class="swiching_web_user_btn"
      [ngClass]="selectedTable == 'bm' ? 'swiching_web_user_btn_active' : ''"
      (click)="handleSwicthWebUser('bm')"
    >
      BUSINESS MANAGER
    </button>
  </div>
  </div>

  <div class="web_user_content_main_div">
    <!-------------------------------------------- ENTERPRISE TABLE -------------------------------------------------------->

    <div *ngIf="selectedTable == 'eu'">
      <div class="web_user_search_main_div">
        <div class="web_user_search_sub_div">
          <div class="search_icon_inp_div">
            <img
              src="../../../assets/img/search_icon.svg"
              alt=""
              class="search_icon_inp_icon"
            />
            <input
              type="text"
              class="search_icon_inp"
              placeholder="Search"
              (keyup)="applyFilter($event)"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="dropdown">
          <button
            class="btn filter_btn"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="../../../assets/img/../img/filter_icon.svg"
              alt=""
            />Filters
          </button>
          <ul class="dropdown-menu">
            <li
              class="dropdown-item"
              style="color: #5f6370"
              [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
              (click)="filterByStatusEU('all')"
            >
              All
            </li>
            <li
              class="dropdown-item"
              style="color: #12c058"
              [ngClass]="selectedFilter.active ? 'filterActiveCss' : ''"
              (click)="filterByStatusEU('active')"
            >
              Active Enterprise User
            </li>
            <li
              class="dropdown-item"
              style="color: #d43738"
              [ngClass]="selectedFilter.expired ? 'filterActiveCss' : ''"
              (click)="filterByStatusEU('inactive')"
            >
              Inactive Enterprise User
            </li>
            <li
              class="dropdown-item"
              style="color: #406cde"
              [ngClass]="selectedFilter.unassigned ? 'filterActiveCss' : ''"
              (click)="filterByStatusEU('unassigned')"
            >
              Unassigned Enterprise User
            </li>
          </ul>
        </div>
        <div
          class="filterApplied"
          (click)="filterByStatusEU('all')"
          *ngIf="selectedFilterDropdown != 'all'"
        >
          {{ selectedFilterDropdown }}
          <img
            style="width: 15px; height: 15px; margin-left: 20px"
            src="../../../assets/img/xmark.svg"
          />
        </div>
      </div>

      <div class="web_user_table_main_div">
        <table
          mat-table
          [dataSource]="dataSourceEU"
          matSort
          class="mat-elevation-z8"
          style="width: 100%; position: relative"
        >
          <!-- Sl NO -->
          <ng-container matColumnDef="sl_no">
            <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
            <td class="" mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </td>
          </ng-container>

          <!-- ENTERPRISE USER Name  -->
          <ng-container matColumnDef="name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 3rem;
                "
              >
                <p mat-sort-header>Enterprise User Name</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EuNameFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ EuNameFilter ? EuNameFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="EuNameFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="EuApplyFilter('', 'name', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of EuNameDropdown"
                      (click)="EuApplyFilter(status, 'name', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td
              class="common_table_data"
              mat-cell
              *matCellDef="let element"
              (click)="editEnterpriseUser(element.id)"
              style="color: #7893ec; cursor: pointer"
            >
              {{ element.name }}
            </td>
          </ng-container>

          <!-- Company Name-->
          <ng-container matColumnDef="company_name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 3rem;
  
                "
              >
                <p mat-sort-header>Company Name</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EuCompanyFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ EuCompanyFilter ? EuCompanyFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="EuCompanyFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="EuApplyFilter('', 'company', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of EuCompanyDropdown"
                      (click)="EuApplyFilter(status, 'company', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.company_name }}
            </td>
          </ng-container>

          <!-- No. of Licenses -->
          <ng-container matColumnDef="licenses">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div style="display: flex; flex-direction: column; height: 3rem;">
                <p mat-sort-header>No. of Licenses</p>
                <!-- <div class="dropdown">
                  <button class="btn btnn filter_btn multiFilterBtn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img *ngIf="!EuLicensesFilter" src="../../../assets/img/../img/Group.svg" alt=""/>
                    {{ EuLicensesFilter ? EuLicensesFilter : "All" }}
                    <img style="width: 20px" *ngIf="EuLicensesFilter" src="../../../assets/img/../img/close-svgrepo-com.svg" alt=""
                      (click)="EuApplyFilter('', 'licenses', 1)"/>
                  </button>
                  <ul class="dropdown-menu">
                    <li class="dropdown-item" style="color: #5f6370" [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of EuLicensesDropdown" (click)="EuApplyFilter(status, 'licenses', 0)">
                      {{ status }}
                    </li>
                  </ul>
                </div> -->
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element" style="text-align: center;">
              {{ element.licenses}}
            </td>
          </ng-container>

          <!-- City -->
          <ng-container matColumnDef="state">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 3rem;
                "
              >
                <p mat-sort-header>State</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EuCityFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ EuCityFilter ? EuCityFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="EuCityFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="EuApplyFilter('', 'city', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of EuCityDropdown"
                      (click)="EuApplyFilter(status, 'city', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.state }}
            </td>
          </ng-container>

          <!-- Date -->
          <ng-container matColumnDef="date">
            <th
              class="common_table_data"
              style="width: 6rem"
              mat-header-cell
              *matHeaderCellDef
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 3rem;
                "
              >
                <p mat-sort-header>Date</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EuDateFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ EuDateFilter ? EuDateFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="EuDateFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="EuApplyFilter('', 'date', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of EuDateDropdown"
                      (click)="EuApplyFilter(status, 'date', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.date }}
            </td>
          </ng-container>

          <!-- Payment Due Date -->
          <ng-container matColumnDef="payment_due_date">
            <th
              class="common_table_data"
              style="width: 8rem"
              mat-header-cell
              *matHeaderCellDef
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 3rem;
                "
              >
                <p mat-sort-header>Payment Due Date</p>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.payment_due_date ? (element.payment_due_date | date:'dd/MM/yyyy') : 'N/A' }}
            </td>
          </ng-container>

          <!-- Assign Business Manager -->
          <ng-container matColumnDef="assign_business_manager">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 3rem;
                "
              >
                <p mat-sort-header>Bussines Manager</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EuBmFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ EuBmFilter ? EuBmFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="EuBmFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="EuApplyFilter('', 'bm', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of EuBMDropdown"
                      (click)="EuApplyFilter(status, 'bm', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{
                element.assign_business_manager
                  ? element.assign_business_manager.name
                  : "N/A"
              }}
            </td>
          </ng-container>

          <!-- Status -->
          <ng-container matColumnDef="status">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 3rem;
                "
              >
                <p mat-sort-header>Status</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!EuStatusFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ EuStatusFilter ? EuStatusFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="EuStatusFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="EuApplyFilter('', 'status', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of EuStatusDropdown"
                      (click)="EuApplyFilter(status, 'status', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <span
                [ngClass]="
                  element.status == 'Active'
                    ? 'colorGreen'
                    : element.status == 'Inactive'
                      ? 'colorRed'
                      : 'colorBlue'
                "
              >
                {{ element.status }}</span
              >
            </td>
          </ng-container>

          <!-- Reset Password -->
          <ng-container matColumnDef="reset_password">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Reset Password
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <img
                src="../../../assets/img/reset_icon.svg"
                alt=""
                srcset=""
                (click)="resetPasswordBM(element.id, selectedTable)"
                style="cursor: pointer"
              />
            </td>
          </ng-container>
          <!-- Modify -->
          <ng-container matColumnDef="modify">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Modify
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <div style="display: flex">
                <img
                  src="../../../assets/img/remove_icon.svg"
                  alt=""
                  style="cursor: pointer"
                  (click)="handleDeleteUser(element.id)"
                />
                <!-- <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
                                <img (click)="editEnterpriseUser(element.id)" style="cursor: pointer;" src="../../../assets/img/edit_icon.svg" alt=""> -->
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsEU"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsEU"></tr>
        </table>
      </div>
    </div>

    <!-- --------------------------------------Retail User-------------------------------------- ---------------- -->

    <div *ngIf="selectedTable == 'ces'">
      <div class="web_user_search_main_div">
        <div class="web_user_search_sub_div">
          <div class="search_icon_inp_div">
            <img
              src="../../../assets/img/search_icon.svg"
              alt=""
              class="search_icon_inp_icon"
            />
            <input
              type="text"
              class="search_icon_inp"
              placeholder="Search"
              (keyup)="applyFilter($event)"
              autocomplete="off"
            />
          </div>
        </div>

        <div class="dropdown">
          <button
            class="btn filter_btn"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="../../../assets/img/../img/filter_icon.svg"
              alt=""
            />Filters
          </button>
          <ul class="dropdown-menu">
            <li
              class="dropdown-item"
              style="color: #5f6370"
              [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
              (click)="filterByStatusRU('All')"
            >
              All
            </li>
            <li
              class="dropdown-item"
              style="color: #12c058"
              [ngClass]="selectedFilter.active ? 'filterActiveCss' : ''"
              (click)="filterByStatusRU('active')"
            >
              Active Retail User
            </li>
            <li
              class="dropdown-item"
              style="color: #d43738"
              [ngClass]="selectedFilter.expired ? 'filterActiveCss' : ''"
              (click)="filterByStatusRU('inactive')"
            >
              Inactive Retail User
            </li>
            <!-- <li class="dropdown-item" style="color: #1968B1;"
                            [ngClass]="selectedFilter.requested?'filterActiveCss':''"
                            (click)="filterByStatusRU('Requested')">
                            Requested</li> -->
          </ul>
        </div>
        <div
          class="filterApplied"
          (click)="filterByStatusRU('all')"
          *ngIf="selectedFilterDropdown != 'all'"
        >
          {{ selectedFilterDropdown }}
          <img
            style="width: 15px; height: 15px; margin-left: 20px"
            src="../../../assets/img/xmark.svg"
          />
        </div>
      </div>
      <div class="web_user_table_main_div">
        <table
          mat-table
          [dataSource]="dataSourceCES"
          matSort
          class="mat-elevation-z8"
          style="width: 100%; position: relative"
        >
          <!-- Sl NO -->
          <ng-container matColumnDef="sl_no">
            <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
            <td class="" mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </td>
          </ng-container>

          <!-- Retail User Name  -->
          <ng-container matColumnDef="name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 3rem;
                "
              >
                <p mat-sort-header>Name</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RUNameFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ RUNameFilter ? RUNameFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="RUNameFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="RuApplyFilter('', 'name', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of RUNameDropdown"
                      (click)="RuApplyFilter(status, 'name', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td
              class="common_table_data"
              mat-cell
              *matCellDef="let element"
              (click)="editRetailUser(element.id)"
              style="color: #7893ec; cursor: pointer"
            >
              {{ element.name == null ? "N/A" : element.name }}
            </td>
          </ng-container>

          <!-- Company name -->
          <ng-container matColumnDef="company_name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 3rem;
                "
              >
                <p mat-sort-header>Company Name</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RuCompanyFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ RuCompanyFilter ? RuCompanyFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="RuCompanyFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="RuApplyFilter('', 'company', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of RUCompanyDropdown"
                      (click)="RuApplyFilter(status, 'company', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.company_name == null ? "N/A" : element.company_name }}
            </td>
          </ng-container>

            <!-- No. of Licenses -->
            <ng-container matColumnDef="licenses">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div style="display: flex; flex-direction: column; height: 3rem;">
                  <p mat-sort-header>No. of Licenses</p>
                  <!-- <div class="dropdown">
                    <button class="btn btnn filter_btn multiFilterBtn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img *ngIf="!EuLicensesFilter" src="../../../assets/img/../img/Group.svg" alt=""/>
                      {{ EuLicensesFilter ? EuLicensesFilter : "All" }}
                      <img style="width: 20px" *ngIf="EuLicensesFilter" src="../../../assets/img/../img/close-svgrepo-com.svg" alt=""
                        (click)="EuApplyFilter('', 'licenses', 1)"/>
                    </button>
                    <ul class="dropdown-menu">
                      <li class="dropdown-item" style="color: #5f6370" [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                        *ngFor="let status of EuLicensesDropdown" (click)="EuApplyFilter(status, 'licenses', 0)">
                        {{ status }}
                      </li>
                    </ul>
                  </div> -->
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element" style="text-align: center;">
                {{ element.licenses}}
              </td>
            </ng-container>

          <!-- Assign AM -->
          <ng-container matColumnDef="state">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 3rem;
                "
              >
                <p mat-sort-header>State</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RuCityFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ RuCityFilter ? RuCityFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="RuCityFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="RuApplyFilter('', 'city', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of RUCityDropdown"
                      (click)="RuApplyFilter(status, 'city', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.state == null ? "N/A" : element.state }}
            </td>
          </ng-container>

          <!-- Date -->
          <ng-container matColumnDef="date">
            <th
              class="common_table_data"
              style="width: 6rem"
              mat-header-cell
              *matHeaderCellDef
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 3rem;
                "
              >
                <p mat-sort-header>Date</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RuDateFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ RuDateFilter ? RuDateFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="RuDateFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="RuApplyFilter('', 'date', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of RUDateDropdown"
                      (click)="RuApplyFilter(status, 'date', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.date }}
            </td>
          </ng-container>

          <!-- Status -->
          <ng-container matColumnDef="status">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 3rem;
                "
              >
                <p mat-sort-header>Status</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!RuStatusFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ RuStatusFilter ? RuStatusFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="RuStatusFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="RuApplyFilter('', 'status', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of RUStatusDropdown"
                      (click)="RuApplyFilter(status, 'status', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <span
                [ngClass]="
                  element.status == 'Active'
                    ? 'colorGreen'
                    : element.status == 'Inactive'
                      ? 'colorRed'
                      : 'colorBlue'
                "
              >
                {{ element.status == null ? "N/A" : element.status }}
              </span>
            </td>
          </ng-container>

          <!-- Reset Password -->
          <ng-container matColumnDef="reset_password">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Reset Password
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <img
                src="../../../assets/img/reset_icon.svg"
                alt=""
                srcset=""
                (click)="resetPasswordBM(element.id, selectedTable)"
                style="cursor: pointer"
              />
            </td>
          </ng-container>
          <!-- Modify -->
          <ng-container matColumnDef="modify">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Modify
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <div style="display: flex">
                <img
                  src="../../../assets/img/remove_icon.svg"
                  alt=""
                  style="cursor: pointer"
                  (click)="handleDeleteUser(element.id)"
                />
                <!-- <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
                                <img src="../../../assets/img/edit_icon.svg" alt=""
                                    (click)="editRetailUser(element.id)" style="cursor: pointer;"> -->
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsCES"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsCES"></tr>
        </table>
      </div>
    </div>

    <!-- --------------------------------------Business Manager-------------------------------------- ---------------- -->
    <div *ngIf="selectedTable == 'bm'">
      <div class="web_user_search_main_div">
        <div class="web_user_search_sub_div">
          <div class="search_icon_inp_div">
            <img
              src="../../../assets/img/search_icon.svg"
              alt=""
              class="search_icon_inp_icon"
            />
            <input
              type="text"
              class="search_icon_inp"
              placeholder="Search"
              (keyup)="applyFilter($event)"
              autocomplete="off"
            />
          </div>
        </div>

        <div class="dropdown">
          <button
            class="btn filter_btn"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              src="../../../assets/img/../img/filter_icon.svg"
              alt=""
            />Filters
          </button>
          <ul class="dropdown-menu">
            <li
              class="dropdown-item"
              style="color: #5f6370"
              [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
              (click)="filterByStatus('all')"
            >
              All
            </li>
            <li
              class="dropdown-item"
              style="color: #12c058"
              [ngClass]="selectedFilter.active ? 'filterActiveCss' : ''"
              (click)="filterByStatus('active')"
            >
              Active Business Manager
            </li>
            <li
              class="dropdown-item"
              style="color: #d43738"
              [ngClass]="selectedFilter.expired ? 'filterActiveCss' : ''"
              (click)="filterByStatus('inactive')"
            >
              Inactive Business Manager
            </li>
          </ul>
        </div>
        <div
          class="filterApplied"
          (click)="filterByStatus('all')"
          *ngIf="selectedFilterDropdown != 'all'"
        >
          {{ selectedFilterDropdown }}
          <img
            style="width: 15px; height: 15px; margin-left: 20px"
            src="../../../assets/img/xmark.svg"
          />
        </div>
      </div>
      <div class="web_user_table_main_div">
        <table
          mat-table
          [dataSource]="dataSourceBM"
          matSort
          class="mat-elevation-z8"
          style="width: 100%; position: relative"
        >
          <!-- Sl NO -->
          <ng-container matColumnDef="sl_no">
            <th class="" mat-header-cell matSort *matHeaderCellDef>SN.</th>
            <td class="" mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </td>
          </ng-container>

          <!-- Retail User Name  -->
          <ng-container matColumnDef="name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 3rem;
                "
              >
                <p mat-sort-header>Business Manager Name</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!BMNameFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ BMNameFilter ? BMNameFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="BMNameFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="BMApplyFilter('', 'name', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of BMNameDropdown"
                      (click)="BMApplyFilter(status, 'name', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td
              class="common_table_data"
              mat-cell
              *matCellDef="let element"
              (click)="editBusinessManager(element.id)"
              style="color: #7893ec; cursor: pointer"
            >
              {{ element.name == null ? "N/A" : element.name }}
            </td>
          </ng-container>

          <!-- Company name -->
          <ng-container matColumnDef="company_name">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 3rem;
                "
              >
                <p mat-sort-header>Company Name</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!BMCompanyFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ BMCompanyFilter ? BMCompanyFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="BMCompanyFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="BMApplyFilter('', 'company', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of BMCompanyDropdown"
                      (click)="BMApplyFilter(status, 'company', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.company_name == null ? "N/A" : element.company_name }}
            </td>
          </ng-container>

        

          <!-- City name -->
          <ng-container matColumnDef="state">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 3rem;
                "
              >
                <p mat-sort-header>State</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!BMCityFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ BMCityFilter ? BMCityFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="BMCityFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="BMApplyFilter('', 'city', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of BMCityDropdown"
                      (click)="BMApplyFilter(status, 'city', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              {{ element.state == null ? "N/A" : element.state }}
            </td>
          </ng-container>

          <!-- Status -->
          <ng-container matColumnDef="status">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  height: 3rem;
                "
              >
                <p mat-sort-header>Status</p>
                <div class="dropdown">
                  <button
                    class="btn btnn filter_btn multiFilterBtn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      *ngIf="!BMStatusFilter"
                      src="../../../assets/img/../img/Group.svg"
                      alt=""
                    />{{ BMStatusFilter ? BMStatusFilter : "All" }}
                    <img
                      style="width: 20px"
                      *ngIf="BMStatusFilter"
                      src="../../../assets/img/../img/close-svgrepo-com.svg"
                      alt=""
                      (click)="BMApplyFilter('', 'status', 1)"
                    />
                  </button>
                  <ul class="dropdown-menu">
                    <li
                      class="dropdown-item"
                      style="color: #5f6370"
                      [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
                      *ngFor="let status of BMStatusDropdown"
                      (click)="BMApplyFilter(status, 'status', 0)"
                    >
                      {{ status }}
                    </li>
                  </ul>
                </div>
              </div>
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <span
                [ngClass]="
                  element.status == 'Active'
                    ? 'colorGreen'
                    : element.status == 'Inactive'
                      ? 'colorRed'
                      : 'colorBlue'
                "
              >
                {{ element.status == null ? "N/A" : element.status }}</span
              >
            </td>
          </ng-container>

          <!-- Reset Password -->
          <ng-container matColumnDef="reset_password">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Reset Password
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <img
                src="../../../assets/img/reset_icon.svg"
                alt=""
                srcset=""
                (click)="resetPasswordBM(element.id, selectedTable)"
                style="cursor: pointer"
              />
            </td>
          </ng-container>
          <!-- Modify -->
          <ng-container matColumnDef="modify">
            <th class="common_table_data" mat-header-cell *matHeaderCellDef>
              Modify
            </th>
            <td class="common_table_data" mat-cell *matCellDef="let element">
              <div style="display: flex">
                <img
                  src="../../../assets/img/remove_icon.svg"
                  alt=""
                  style="cursor: pointer"
                  (click)="handleDeleteUser(element.id)"
                />
                <!-- <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
                                <img src="../../../assets/img/edit_icon.svg" alt=""
                                    (click)="editBusinessManager(element.id)" style="cursor: pointer;"> -->
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsBM"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsBM"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
