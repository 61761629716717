<div>
  <div class="u2_user_management">
    <label>USER MANAGEMENT</label>
    <div
      class="u2_management_outer_container"
    >
      <div class="dash_acc_manager_count_main_div">
        <div
          class="dash_user_count_sub_div_sa bussManager"
          (click)="moveTab('webUser','bm')"
        >
          <div class="dash_user_header">
            <img src="../../../assets/img/business_manager-icon.svg" alt="" />
            <div>Business Manager</div>
          </div>
          <div class="dash_user_body">
            <div class="dash_user_status_counts">
              <div>Total Business Managers</div>
              <div class="dash_users_statusCount">
                {{businessManagerCount.total_business_manager}}
              </div>
            </div>
            <div class="dash_user_status_counts">
              <div>
                <img src="../../../assets/img/pajamas_status-green.svg" />
                Active Users
              </div>
              <div class="dash_users_statusCount">
                {{businessManagerCount.total_active_business_manager}}
              </div>
            </div>
            <div class="dash_user_status_counts">
              <div>
                <img src="../../../assets/img/pajamas_status-red.svg" />
                In Active Users
              </div>
              <div class="dash_users_statusCount">
                {{businessManagerCount.total_inactive_business_manager}}
              </div>
            </div>
          </div>
        </div>
        <div
          class="dash_user_count_sub_div_sa enterUser"
          (click)="moveTab('webUser')"
        >
          <div class="dash_user_header">
            <img src="../../../assets/img/enterpriser-icon.svg" alt="" />
            <div>Enterprise User</div>
          </div>
          <div class="dash_user_body">
            <div class="dash_user_status_counts">
              <div>Total Enterprise Users</div>
              <div class="dash_users_statusCount">
                {{enterpriserCount.total_enterprise_user}}
              </div>
            </div>
            <div class="dash_user_status_counts">
              <div>
                <img src="../../../assets/img/pajamas_status-green.svg" />
                Active Users
              </div>
              <div class="dash_users_statusCount">
                {{enterpriserCount.total_active_enterprise_user}}
              </div>
            </div>
            <div class="dash_user_status_counts">
              <div>
                <img src="../../../assets/img/pajamas_status-red.svg" />
                In Active Users
              </div>
              <div class="dash_users_statusCount">
                {{enterpriserCount.total_inactive_enterprise_user}}
              </div>
            </div>
            <div class="dash_user_status_counts">
              <div>
                <img src="../../../assets/img/pajamas_status-blue.svg" />
                Unassigned Users
              </div>
              <div class="dash_users_statusCount">
                {{enterpriserCount.total_unassigned_enterprise_user}}
              </div>
            </div>
          </div>
        </div>
        <div
          class="dash_user_count_sub_div_sa retailUser"
          (click)="moveTab('webUser','Retail')"
        >
          <div class="dash_user_header">
            <img src="../../../assets/img/retail_user-icon.svg" alt="" />
            <div>Retail User</div>
          </div>
          <div class="dash_user_body">
            <div class="dash_user_status_counts">
              <div>Total Retail Users</div>
              <div class="dash_users_statusCount">
                {{retailUserCount.total_retail_user}}
              </div>
            </div>
            <div class="dash_user_status_counts">
              <div>
                <img src="../../../assets/img/pajamas_status-green.svg" />
                Active Users
              </div>
              <div class="dash_users_statusCount">
                {{retailUserCount.total_active_retail_user}}
              </div>
            </div>
            <div class="dash_user_status_counts">
              <div>
                <img src="../../../assets/img/pajamas_status-red.svg" />
                In Active Users
              </div>
              <div class="dash_users_statusCount">
                {{retailUserCount.total_inactive_retail_user}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dash_user_end_div">&nbsp;</div>

  <!-- ------------------------------License Requests ---------------------------- -->
  <div class="u2_license_detail_container">
    <div class="dash_main_header_sa">LICENSE DETAILS</div>
    <label class="license_header"> ENTERPRISE </label>
    <div
      class="dash_user_count_main_div"
    >
      <div
        class="dash_user_count_sub_div u3_license_count"
        (click)="moveTab('license','Enterprise','All')"
      >
        <div>
          <img src="../../../assets/img/total_licenses-icon.svg" alt="" />
        </div>
        <div class="dash_user_count_div">
          <div>Total Licenses</div>
          <div>{{allLicenseCount.enterprise_user_data?.total_licenses}}</div>
        </div>
      </div>
      <div
        class="dash_user_count_sub_div u3_license_count"
        (click)="moveTab('license','Enterprise','Active')"
      >
        <div>
          <img src="../../../assets/img/actice_license.svg" alt="" />
        </div>
        <div class="dash_user_count_div">
          <div>Active Licenses</div>
          <div>{{allLicenseCount.enterprise_user_data?.active_licenses}}</div>
        </div>
      </div>
      <!-- <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('license','Enterprise','Expired')">
				<div>
					<img src="../../../assets/img/expired_license.svg" alt="">
				</div>
				<div class="dash_user_count_div">
					<div>Expired Licenses</div>
					<div>{{allLicenseCount.enterprise_user_data?.expired_licenses}}</div>
				</div>
			</div> -->
      <div
        class="dash_user_count_sub_div u3_license_count"
        (click)="moveTab('license','Enterprise','Requested')"
      >
        <div>
          <img src="../../../assets/img/requested_license.svg" alt="" />
        </div>
        <div class="dash_user_count_div">
          <div>Requested Licenses</div>
          <div>
            {{allLicenseCount.enterprise_user_data?.requested_licenses}}
          </div>
        </div>
      </div>
      <div
        class="dash_user_count_sub_div u3_license_count"
        (click)="moveTab('license','Enterprise','90')"
      >
        <div>
          <img src="../../../assets/img/expired_license.svg" alt="" />
        </div>
        <div class="dash_user_count_div">
          <div>Expiring in 90 Days</div>
          <div>
            {{allLicenseCount.enterprise_user_data?.expiring_in_less_than_90_days}}
          </div>
        </div>
      </div>
      <div
        class="dash_user_count_sub_div u3_license_count"
        (click)="moveTab('license','Enterprise','60')"
      >
        <div>
          <img src="../../../assets/img/expired_license.svg" alt="" />
        </div>
        <div class="dash_user_count_div">
          <div>Expiring in 60 Days</div>
          <div>
            {{allLicenseCount.enterprise_user_data?.expiring_in_less_than_60_days}}
          </div>
        </div>
      </div>
      <div
        class="dash_user_count_sub_div u3_license_count"
        (click)="moveTab('license','Enterprise','30')"
      >
        <div>
          <img src="../../../assets/img/expired_license.svg" alt="" />
        </div>
        <div class="dash_user_count_div">
          <div>Expiring in 30 Days</div>
          <div>
            {{allLicenseCount.enterprise_user_data?.expiring_in_less_than_30_days}}
          </div>
        </div>
      </div>
      <div
        class="dash_user_count_sub_div u3_license_count"
        (click)="moveTab('license','Enterprise','Expired')"
      >
        <div>
          <img src="../../../assets/img/expired_license.svg" alt="" />
        </div>
        <div class="dash_user_count_div">
          <div>Expired Licenses</div>
          <div>{{allLicenseCount.enterprise_user_data?.expired_licenses}}</div>
        </div>
      </div>
    </div>
    <label class="license_header"> RETAIL </label>
    <div
      class="dash_user_count_main_div"
    >
      <div
        class="dash_user_count_sub_div u3_license_count"
        (click)="moveTab('license','Retail','All')"
      >
        <div>
          <img src="../../../assets/img/total_licenses-icon.svg" alt="" />
        </div>
        <div class="dash_user_count_div">
          <div>Total Licenses</div>
          <div>{{allLicenseCount.retail_user_data?.total_licenses}}</div>
        </div>
      </div>
      <div
        class="dash_user_count_sub_div u3_license_count"
        (click)="moveTab('license','Retail','Active')"
      >
        <div>
          <img src="../../../assets/img/actice_license.svg" alt="" />
        </div>
        <div class="dash_user_count_div">
          <div>Active Licenses</div>
          <div>{{allLicenseCount.retail_user_data?.active_licenses}}</div>
        </div>
      </div>
      <!-- <div class="dash_user_count_sub_div u3_license_count" (click)="moveTab('license','Retail','Expired')">
				<div>
					<img src="../../../assets/img/expired_license.svg" alt="">
				</div>
				<div class="dash_user_count_div">
					<div>Expired Licenses</div>
					<div>{{allLicenseCount.retail_user_data.expired_licenses}}</div>
				</div>
			</div> -->
      <div
        class="dash_user_count_sub_div u3_license_count"
        (click)="moveTab('license','Retail','Requested')"
      >
        <div>
          <img src="../../../assets/img/requested_license.svg" alt="" />
        </div>
        <div class="dash_user_count_div">
          <div>Requested Licenses</div>
          <div>{{allLicenseCount.retail_user_data?.requested_licenses}}</div>
        </div>
      </div>
      <div
        class="dash_user_count_sub_div u3_license_count"
        (click)="moveTab('license','Retail','90')"
      >
        <div>
          <img src="../../../assets/img/expired_license.svg" alt="" />
        </div>
        <div class="dash_user_count_div">
          <div>Expiring in 90 Days</div>
          <div>
            {{allLicenseCount.retail_user_data?.expiring_in_less_than_90_days}}
          </div>
        </div>
      </div>
      <div
        class="dash_user_count_sub_div u3_license_count"
        (click)="moveTab('license','Retail','60')"
      >
        <div>
          <img src="../../../assets/img/expired_license.svg" alt="" />
        </div>
        <div class="dash_user_count_div">
          <div>Expiring in 60 Days</div>
          <div>
            {{allLicenseCount.retail_user_data?.expiring_in_less_than_60_days}}
          </div>
        </div>
      </div>
      <div
        class="dash_user_count_sub_div u3_license_count"
        (click)="moveTab('license','Retail','30')"
      >
        <div>
          <img src="../../../assets/img/expired_license.svg" alt="" />
        </div>
        <div class="dash_user_count_div">
          <div>Expiring in 30 Days</div>
          <div>
            {{allLicenseCount.retail_user_data?.expiring_in_less_than_30_days}}
          </div>
        </div>
      </div>
      <div
        class="dash_user_count_sub_div u3_license_count"
        (click)="moveTab('license','Retail','Expired')"
      >
        <div>
          <img src="../../../assets/img/expired_license.svg" alt="" />
        </div>
        <div class="dash_user_count_div">
          <div>Expired Licenses</div>
          <div>{{allLicenseCount.retail_user_data?.expired_licenses}}</div>
        </div>
      </div>
    </div>
    <div class="dash_pie_sub_div" style="padding-bottom: 5rem">
      <div class="dash_pie_header">Total Licenses</div>
      <div style="display: grid; ">
        <div
          class="dash_pie_wraper_div"
          style="display: flex; justify-content: center"
        >
          <div>
            <apx-chart
              #chart
              [series]="totalLicenseData"
              [chart]="pieCharts"
              [labels]="labelLicense"
              [dataLabels]="dataLabels"
              [legend]="pieLegend"
              [colors]="totalLicensecolors"
            ></apx-chart>
          </div>
          <div
            style="
              width: 184px;
              display: flex;
              flex-direction: column;
              justify-content: center;
            "
          >
            <div class="pie_chart_lengend_label pie_chart_lengend_label_total">
              <div class="" style="padding-left: 0">Total Licenses</div>
              <div class="">{{allLicenseCount.total_licenses}}</div>
            </div>
            <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
              <div>
                <div
                  class="active_legend_circle"
                  style="background-color: #7cc3b5"
                ></div>
                Active Licenses
              </div>
              <div>{{allLicenseCount.active_licenses}}</div>
            </div>
            <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
              <div>
                <div
                  class="inactive_legend_circle"
                  style="background-color: #e0473e"
                ></div>
                Expired Licenses
              </div>
              <div>{{allLicenseCount.expired_licenses}}</div>
            </div>
          </div>
        </div>
        <div
          class="dash_pie_wraper_div"
          style="display: flex; justify-content: center"
        >
          <div>
            <apx-chart
              #chart
              [series]="expiringLicenseData"
              [chart]="pieCharts"
              [labels]="labelExpLicense"
              [dataLabels]="dataLabels"
              [legend]="pieLegend"
              [colors]="expLicenseColors"
            ></apx-chart>
          </div>
          <div
            style="
              width: 184px;
              display: flex;
              flex-direction: column;
              justify-content: center;
            "
          >
            <div class="pie_chart_lengend_label pie_chart_lengend_label_total">
              <div class="" style="padding-left: 0">Expiring Licenses</div>
              <div class="">&nbsp;</div>
            </div>
            <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
              <div>
                <div
                  class="active_legend_circle"
                  style="background-color: #e0473e"
                ></div>
                Expiring in less than 30 days
              </div>
              <div>{{expiringLicenseCount.expiring_in_less_than_30_days}}</div>
            </div>
            <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
              <div>
                <div
                  class="inactive_legend_circle"
                  style="background-color: #f59e5f"
                ></div>
                Expiring in 60 days
              </div>
              <div>{{expiringLicenseCount.expiring_in_less_than_60_days}}</div>
            </div>
            <div class="pie_chart_lengend_label pie_chart_lengend_label_data">
              <div>
                <div
                  class="inactive_legend_circle"
                  style="background-color: #f5dd5f"
                ></div>
                Expiring in 90 days
              </div>
              <div>{{expiringLicenseCount.expiring_in_less_than_90_days}}</div>
            </div>
          </div>
        </div>
      </div>

      <div style="display: flex; justify-content: center; margin-top: 8rem">
        <div class="chart_y_axis_header">No. Licenses</div>
        <div class="dash_chart_main_div">
          <div><img src="../../../assets/img/line_verical.svg" alt="" /></div>
          <div id="chart_div" class="dash_chart_sub_div">
            <div id="chart_su_div">
              <apx-chart
                #chart
                [series]="licenseRequestData"
                [chart]="barCharts"
                [xaxis]="xaxisLebels"
                [dataLabels]="dataLabels"
                [plotOptions]="plotOptions"
                [legend]="legend"
              ></apx-chart>
            </div>

            <img
              src="../../../assets/img/line_horizontal.svg"
              alt=""
              class="horizontal_line"
            />
            <div class="chart_x_axis_header">Weeks</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dash_user_end_div">&nbsp;</div>

    <!-- ----------------------------------- Documents --------------------------- -->


    <div style="width: 100%; height: 1px; background-color: darkgray; margin-bottom: 10px; margin-top: 15px;"></div>
    <div class="category-title" style=" color: darkgrey; justify-self: center; margin-top: 5px; font-size: 14px;">DOCUMENT DETAILS</div>
    <div class="dash_service_count_main_div">
      <div
        class="dash_user_count_sub_div u3_license_count"
        (click)="navigateWithFilters([
        { category: 'status', value: ['EXPIRED'] }
        ])"
      >
        <div>
          <img src="../../../assets/img/expired_license.svg" alt="" />
        </div>
        <div class="dash_user_count_div">
          <div>Expired Documents</div>
          <div>{{documentsCount.expired_documents}}</div>
        </div>
      </div>
      <div
        class="dash_user_count_sub_div u3_license_count"
        (click)="navigateWithFilters([
        { category: 'status', value: ['REQUESTED', 'RESUBMIT'] }
        ])"
  
      >
        <div>
          <img src="../../../assets/img/document_need_to_upload.svg" alt="" />
        </div>
        <div class="dash_user_count_div">
          <div>Documents to Upload</div>
          <div>{{documentsCount.documents_need_to_upload}}</div>
        </div>
      </div>
    </div>
    <div class="category-title" style="margin-top: 15px;">COMPANY DETAILS</div>
    <div class="dash_service_count_main_div"
    >
      <div
        class="dash_user_count_sub_div u3_license_count"
        (click)="navigateWithFilters([
        { category: 'document_type', value: ['COMPANY DETAILS'] },
        { category: 'status', value: ['EXPIRED'] }
        ])"
      >
        <div>
          <img src="../../../assets/img/expired_license.svg" alt="" />
        </div>
        <div class="dash_user_count_div">
          <div>Expired Documents</div>
          <div>{{documentsCount.expired_documents_by_type.ApplicationDocuments}}</div>
        </div>
      </div>
      <div
        class="dash_user_count_sub_div u3_license_count"
        (click)="navigateWithFilters([
        { category: 'document_type', value: ['COMPANY DETAILS'] },
        { category: 'status', value: ['REQUESTED', 'RESUBMIT'] }
        ])"
      >
        <div>
          <img src="../../../assets/img/document_need_to_upload.svg" alt="" />
        </div>
        <div class="dash_user_count_div">
          <div>Documents to Upload</div>
          <div>{{documentsCount.documents_need_to_upload_by_type.ApplicationDocuments}}</div>
        </div>
      </div>
    </div>
    <div class="document-dashboard">
      <!-- Testing Documents -->
      <div class="category-column">
        <div class="category-title">TESTING</div>
        <div class="dash_user_count_sub_div"
        (click)="navigateWithFilters([
            { category: 'document_type', value: ['TESTING'] },
            { category: 'status', value: ['EXPIRED'] }
          ])"      
        >
            <img src="../../../assets/img/expired_license.svg" alt="Expired" />
          <div class="dash_user_count_div">
            <div class="label">Expired Documents</div>
            <div class="count">{{documentsCount.expired_documents_by_type.TestDocuments}}</div>
          </div>
        </div>
        <div class="dash_user_count_sub_div"
        (click)="navigateWithFilters([
        { category: 'document_type', value: ['TESTING'] },
        { category: 'status', value: ['REQUESTED', 'RESUBMIT'] }
        ])">
            <img src="../../../assets/img/document_need_to_upload.svg" alt="Upload" />
          <div class="dash_user_count_div">
            <div class="label">Documents To Upload</div>
            <div class="count">{{documentsCount.documents_need_to_upload_by_type.TestDocuments}}</div>
          </div>
        </div>
      </div>
    
      <!-- Training Documents -->
      <div class="category-column">
        <div class="category-title">TRAINING</div>
        <div class="dash_user_count_sub_div"
        (click)="navigateWithFilters([
        { category: 'document_type', value: ['TRAINING'] },
        { category: 'status', value: ['EXPIRED'] }
        ])" >
            <img src="../../../assets/img/expired_license.svg" alt="Expired" />
          <div class="dash_user_count_div">
            <div class="label">Expired Documents</div>
            <div class="count">{{documentsCount.expired_documents_by_type.TrainingDocuments}}</div>
          </div>
        </div>
        <div class="dash_user_count_sub_div"
        (click)="navigateWithFilters([
        { category: 'document_type', value: ['TRAINING'] },
        { category: 'status', value: ['REQUESTED', 'RESUBMIT'] }
        ])">
            <img src="../../../assets/img/document_need_to_upload.svg" alt="Upload" />
          <div class="dash_user_count_div">
            <div class="label">Documents To Upload</div>
            <div class="count">{{documentsCount.documents_need_to_upload_by_type.TrainingDocuments}}</div>
          </div>
        </div>
      </div>
    
      <!-- Health Documents -->
      <div class="category-column">
        <div class="category-title">HEALTH</div>
        <div class="dash_user_count_sub_div" 
        (click)="navigateWithFilters([
        { category: 'document_type', value: ['HEALTH'] },
        { category: 'status', value: ['EXPIRED'] }
        ])">
            <img src="../../../assets/img/expired_license.svg" alt="Expired" />
          <div class="dash_user_count_div">
            <div class="label">Expired Documents</div>
            <div class="count">{{documentsCount.expired_documents_by_type.HealthDocuments}}</div>
          </div>
        </div>
        <div class="dash_user_count_sub_div" 
        (click)="navigateWithFilters([
        { category: 'document_type', value: ['HEALTH'] },
        { category: 'status', value: ['REQUESTED', 'RESUBMIT'] }
        ])">
            <img src="../../../assets/img/document_need_to_upload.svg" alt="Upload" />
          <div class="dash_user_count_div">
            <div class="label">Documents To Upload</div>
            <div class="count">{{documentsCount.documents_need_to_upload_by_type.HealthDocuments}}</div>
          </div>
        </div>
      </div>
    
      <!-- Calibration Documents -->
      <div class="category-column">
        <div class="category-title">CALIBRATION</div>
        <div class="dash_user_count_sub_div" 
        (click)="navigateWithFilters([
        { category: 'document_type', value: ['CALIBRATION'] },
        { category: 'status', value: ['EXPIRED'] }
        ])">
            <img src="../../../assets/img/expired_license.svg" alt="Expired" />
          <div class="dash_user_count_div">
            <div class="label">Expired Documents</div>
            <div class="count">{{documentsCount.expired_documents_by_type.CalibrationDocuments}}</div>
          </div>
        </div>
        <div class="dash_user_count_sub_div" 
        (click)="navigateWithFilters([
        { category: 'document_type', value: ['CALIBRATION'] },
        { category: 'status', value: ['REQUESTED', 'RESUBMIT'] }
        ])">
            <img src="../../../assets/img/document_need_to_upload.svg" alt="Upload" />
          <div class="dash_user_count_div">
            <div class="label">Documents To Upload</div>
            <div class="count">{{documentsCount.documents_need_to_upload_by_type.CalibrationDocuments}}</div>
          </div>
        </div>
      </div>
    
      <!-- Audits Documents -->
      <div class="category-column">
        <div class="category-title">AUDITS</div>
        <div class="dash_user_count_sub_div" 
        (click)="navigateWithFilters([
        { category: 'document_type', value: ['AUDIT'] },
        { category: 'status', value: ['EXPIRED'] }
        ])">
            <img src="../../../assets/img/expired_license.svg" alt="Expired" />
          <div class="dash_user_count_div">
            <div class="label">Expired Documents</div>
            <div class="count">{{documentsCount.expired_documents_by_type.AuditDocuments}}</div>
          </div>
        </div>
        <div class="dash_user_count_sub_div" 
        (click)="navigateWithFilters([
        { category: 'document_type', value: ['AUDIT'] },
        { category: 'status', value: ['REQUESTED', 'RESUBMIT'] }
        ])">
          <img src="../../../assets/img/document_need_to_upload.svg" alt="Upload" />
          <div class="dash_user_count_div">
            <div class="label">Documents To Upload</div>
            <div class="count">{{documentsCount.documents_need_to_upload_by_type.AuditDocuments}}</div>
          </div>
        </div>
      </div>
    
      <!-- Others Documents -->
      <div class="category-column">
        <div class="category-title">OTHERS</div>
        <div class="dash_user_count_sub_div" 
        (click)="navigateWithFilters([
        { category: 'document_type', value: ['OTHERS'] },
        { category: 'status', value: ['EXPIRED'] }
        ])">
            <img src="../../../assets/img/expired_license.svg" alt="Expired" />
          <div class="dash_user_count_div">
            <div class="label">Expired Documents</div>
            <div class="count">{{documentsCount.expired_documents_by_type.OthersDocuments}}</div>
          </div>
        </div>
        <div class="dash_user_count_sub_div"
        (click)="navigateWithFilters([
        { category: 'document_type', value: ['OTHERS'] },
        { category: 'status', value: ['REQUESTED', 'RESUBMIT'] }
        ])">
            <img src="../../../assets/img/document_need_to_upload.svg" alt="Upload" />
          <div class="dash_user_count_div">
            <div class="label">Documents To Upload</div>
            <div class="count">{{documentsCount.documents_need_to_upload_by_type.OthersDocuments}}</div>
          </div>
        </div>
      </div>
    </div>
  <!-- ----------------------------------- Service Requests --------------------------- -->
  <div class="u2_user_management">
    <label class="license_header"> SERVICE REQUESTS </label>
    <label class="license_header"> ENTERPRISE </label>
    <div
      class="dash_user_count_main_div"
      style="grid-template-columns: 1fr 1fr"
      (click)="moveTab('service','Enterprise')"
    >
      <div class="dash_user_count_sub_div u3_license_count">
        <div>
          <img src="../../../assets/img/pending_service_requests.svg" alt="" />
        </div>
        <div class="dash_user_count_div">
          <div>Total Service Requests</div>
          <div>{{serviceRequestCount.eu_total_service_requests}}</div>
        </div>
      </div>
      <div class="dash_user_count_sub_div u3_license_count">
        <div>
          <img src="../../../assets/img/pending_service_requests.svg" alt="" />
        </div>
        <div class="dash_user_count_div">
          <div>Pending Service Requests</div>
          <div>{{serviceRequestCount.eu_pending_service_requests}}</div>
        </div>
      </div>
    </div>
    <label class="license_header"> RETAIL </label>
    <div
      class="dash_user_count_main_div"
      style="grid-template-columns: 1fr 1fr"
      (click)="moveTab('service','Retail')"
    >
      <div class="dash_user_count_sub_div u3_license_count">
        <div>
          <img src="../../../assets/img/pending_service_requests.svg" alt="" />
        </div>
        <div class="dash_user_count_div">
          <div>Total Service Requests</div>
          <div>{{serviceRequestCount.ru_total_service_requests}}</div>
        </div>
      </div>
      <div class="dash_user_count_sub_div u3_license_count">
        <div>
          <img src="../../../assets/img/pending_service_requests.svg" alt="" />
        </div>
        <div class="dash_user_count_div">
          <div>Pending Service Requests</div>
          <div>{{serviceRequestCount.ru_pending_service_requests}}</div>
        </div>
      </div>
    </div>
    <div class="dash_sub_box_main_div" style="margin-top: 1rem">
      <div class="dash_header_main_div">
        <div class="box_main_heading">Total Service Requests</div>
        <div class="dateFilter">
          <div class="dash_filter_main_div">
            <div>Month</div>
            <div>
              <select
                name="monthName"
                id=""
                [(ngModel)]="selectedServiceMonth"
                (change)="serviceRequestMonthHander($event)"
              >
                <option value="Jan" selected>Jan</option>
                <option value="Feb">Feb</option>
                <option value="Mar">Mar</option>
                <option value="Apr">Apr</option>
                <option value="May">May</option>
                <option value="Jun">Jun</option>
                <option value="Jul">Jul</option>
                <option value="Aug">Aug</option>
                <option value="Sep">Sep</option>
                <option value="Oct">Oct</option>
                <option value="Nov">Nov</option>
                <option value="Dec">Dec</option>
              </select>
            </div>
          </div>
          <div class="dash_filter_main_div">
            <div>Year</div>
            <div>
              <select
                name="monthName"
                id=""
                [(ngModel)]="currentYear"
                (change)="serviceRequestMonthHander($event)"
              >
                <option *ngFor="let year of yearDropDown" [value]="year">
                  {{year}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div style="display: flex; justify-content: center">
        <div class="chart_y_axis_header">No. Services</div>
        <div class="dash_chart_main_div">
          <div><img src="../../../assets/img/line_verical.svg" alt="" /></div>
          <div id="chart_div" class="dash_chart_sub_div">
            <div id="chart_su_div">
              <apx-chart
                #chart
                [series]="serviceRequestData"
                [chart]="barCharts"
                [xaxis]="xaxis"
                [dataLabels]="dataLabels"
                [plotOptions]="plotOptions"
                [legend]="legend"
              ></apx-chart>
            </div>

            <img
              src="../../../assets/img/line_horizontal.svg"
              alt=""
              class="horizontal_line"
            />
            <div class="chart_x_axis_header">Weeks</div>
          </div>
        </div>
      </div>
      <div class="box_main_heading" style="margin-top: 4rem">
        Service Requests
      </div>
      <div>
        <div class="dash_pie_sub_div">
          <div
            class="dash_pie_wraper_div"
            style="display: flex; justify-content: center"
          >
            <div>
              <apx-chart
                #chart
                [series]="totalServiceRequestData"
                [chart]="pieCharts"
                [labels]="labelServiceRequest"
                [dataLabels]="dataLabels"
                [legend]="pieLegend"
                [colors]="serviceRequestColors"
              ></apx-chart>
            </div>
            <div
              style="
                width: 700px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                max-height: 200px;
                flex-flow: wrap;
                gap: 8rem;
              "
            >
              <div>
                <div
                  style="max-width: 287px; gap: 60px"
                  class="pie_chart_lengend_label pie_chart_lengend_label_total"
                >
                  <div class="" style="padding-left: 0; text-align: left">
                    Total Service Requests
                  </div>
                  <div class="">
                    {{serviceRequestCount.total_service_requests}}
                  </div>
                </div>
                <div
                  style="max-width: 287px; gap: 60px"
                  class="pie_chart_lengend_label pie_chart_lengend_label_data"
                >
                  <div>
                    <div
                      class="active_legend_circle"
                      style="background-color: #7cc3b5"
                    ></div>
                    Renew License
                  </div>
                  <div>{{serviceRequestCount['Renew license']}}</div>
                </div>
                <div
                  style="max-width: 287px; gap: 60px"
                  class="pie_chart_lengend_label pie_chart_lengend_label_data"
                >
                  <div>
                    <div
                      class="inactive_legend_circle"
                      style="background-color: #9fc4fb"
                    ></div>
                    Modify License
                  </div>
                  <div>{{serviceRequestCount['Modify license']}}</div>
                </div>
                <div
                  style="max-width: 287px; gap: 60px"
                  class="pie_chart_lengend_label pie_chart_lengend_label_data"
                >
                  <div>
                    <div
                      class="inactive_legend_circle"
                      style="background-color: #f66d44"
                    ></div>
                    File Annual Returns
                  </div>
                  <div>{{serviceRequestCount['File Annual Returns']}}</div>
                </div>
                <div
                  style="max-width: 287px; gap: 60px"
                  class="pie_chart_lengend_label pie_chart_lengend_label_data"
                >
                  <div>
                    <div
                      class="inactive_legend_circle"
                      style="background-color: #feae65"
                    ></div>
                    Health Check
                  </div>
                  <div>{{serviceRequestCount['Health Check']}}</div>
                </div>
                <div
                  style="max-width: 287px; gap: 60px"
                  class="pie_chart_lengend_label pie_chart_lengend_label_data"
                >
                  <div>
                    <div
                      class="inactive_legend_circle"
                      style="background-color: #e6f69d"
                    ></div>
                    Upload Test Reports
                  </div>
                  <div>{{serviceRequestCount['Upload Test Reports']}}</div>
                </div>
              </div>
              <div style="padding-top: 24px">
                <div
                  style="max-width: 287px; gap: 60px"
                  class="pie_chart_lengend_label pie_chart_lengend_label_data"
                >
                  <div>
                    <div
                      class="inactive_legend_circle"
                      style="background-color: #aadea7"
                    ></div>
                    Product Testing
                  </div>
                  <div>{{serviceRequestCount['Product Testing']}}</div>
                </div>
                <div
                  style="max-width: 287px; gap: 60px"
                  class="pie_chart_lengend_label pie_chart_lengend_label_data"
                >
                  <div>
                    <div
                      class="inactive_legend_circle"
                      style="background-color: #64c2a6"
                    ></div>
                    Water Testing
                  </div>
                  <div>{{serviceRequestCount['Water Testing']}}</div>
                </div>
                <div
                  style="max-width: 287px; gap: 60px"
                  class="pie_chart_lengend_label pie_chart_lengend_label_data"
                >
                  <div>
                    <div
                      class="inactive_legend_circle"
                      style="background-color: #2d87bb"
                    ></div>
                    Pest Control
                  </div>
                  <div>{{serviceRequestCount['Pest Control']}}</div>
                </div>
                <div
                  style="max-width: 287px; gap: 60px"
                  class="pie_chart_lengend_label pie_chart_lengend_label_data"
                >
                  <div>
                    <div
                      class="inactive_legend_circle"
                      style="background-color: #6aad41"
                    ></div>
                    Equipment Callibration
                  </div>
                  <div>{{serviceRequestCount['Equipment Callibration']}}</div>
                </div>
                <div
                  style="max-width: 287px; gap: 60px"
                  class="pie_chart_lengend_label pie_chart_lengend_label_data"
                >
                  <div>
                    <div
                      class="inactive_legend_circle"
                      style="background-color: #6aad41"
                    ></div>
                    Training
                  </div>
                  <div>{{serviceRequestCount['Training']}}</div>
                </div>
                <div
                  style="max-width: 287px; gap: 60px"
                  class="pie_chart_lengend_label pie_chart_lengend_label_data"
                >
                  <div>
                    <div
                      class="inactive_legend_circle"
                      style="background-color: #6aad41"
                    ></div>
                    Internal Audit
                  </div>
                  <div>{{serviceRequestCount['Internal Audit']}}</div>
                </div>
                <div
                  style="max-width: 287px; gap: 60px"
                  class="pie_chart_lengend_label pie_chart_lengend_label_data"
                >
                  <div>
                    <div
                      class="inactive_legend_circle"
                      style="background-color: #6aad41"
                    ></div>
                    Third-party Audits
                  </div>
                  <div>{{serviceRequestCount['Third-party Audits']}}</div>
                </div>
                <div
                  style="max-width: 287px; gap: 60px"
                  class="pie_chart_lengend_label pie_chart_lengend_label_data"
                >
                  <div>
                    <div
                      class="inactive_legend_circle"
                      style="background-color: #6aad41"
                    ></div>
                    New license
                  </div>
                  <div>{{serviceRequestCount['New license']}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
