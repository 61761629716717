<div class="side_nav_main_div"      
[ngClass]="{ 'collapsed': isCollapsed }" 
(mouseenter)="expandSidebar()" 
(mouseleave)="collapseSidebar()">
  <div>
    <div>
      <div class="side_nav_header_main_div pd_l_1">
        <img src="../../../assets/img/logo.svg" alt="" />
        <span class="side_nav_header_txt">myFssai</span>
      </div>
      <div>
        <div style="position: relative; text-align: end">&nbsp;</div>
      </div>
      <div class="side_nav_bar_list">
        <div
          class="side_nav_bar_ele_main_div pd_l_1"
          [ngClass]="activeValidater.dashboard ? 'side_bar_active' : ''"
          (click)="navigate('/dashboard')"
        >
          <div class="side_nav_bar_ele">
            <img *ngIf="!activeValidater.dashboard" src="../../../assets/img/dashboard.svg"/> 
            <img *ngIf="activeValidater.dashboard" src="../../../assets/img/dashboard_green.svg" style="width: 33px; height: 33px;"/> 
            <span *ngIf="showText" @slideInOutAnimation > Dashboard</span>
          </div>
        </div>
        <!-- <div class="side_nav_bar_ele_main_div pd_l_1 USER_MANAGEMENT_txt_main_div">
                    <div class="USER_MANAGEMENT_txt"> USER MANAGEMENT </div>
                </div> -->
        <div
          class="side_nav_bar_ele_main_div pd_l_1"
          [ngClass]="activeValidater.webUser ? 'side_bar_active' : ''"
          (click)="navigate('/webUser')"
          *ngIf="sideNavActivate.webUser"
        >
          <div class="side_nav_bar_ele">
            <img *ngIf="!activeValidater.webUser" src="../../../assets/img/user_mgmt.svg"/> 
            <img *ngIf="activeValidater.webUser" src="../../../assets/img/user_mgmt_green.svg" style="width: 33px; height: 33px;"/> 
            <span *ngIf="showText" @slideInOutAnimation > User Management</span>
          </div>
        </div>
        <div
          class="side_nav_bar_ele_main_div pd_l_1"
          [ngClass]="activeValidater.appUser ? 'side_bar_active' : ''"
          (click)="navigate('/appUser')"
          *ngIf="sideNavActivate.appUser"
        >
          <div class="side_nav_bar_ele">
            App User
          </div>
        </div>
        <div
          class="side_nav_bar_ele_main_div pd_l_1"
          [ngClass]="activeValidater.license ? 'side_bar_active' : ''"
          (click)="navigate('/license')"
          *ngIf="sideNavActivate.license"

        >
          <div class="side_nav_bar_ele">
            <img *ngIf="!activeValidater.license" src="../../../assets/img/licenses.svg"/> 
            <img *ngIf="activeValidater.license" src="../../../assets/img/licenses_green.svg" style="width: 33px; height: 33px;"/> 
            <span *ngIf="showText" @slideInOutAnimation > Licenses</span>
            
          </div>
        </div>
        <div
          class="side_nav_bar_ele_main_div pd_l_1"
          [ngClass]="activeValidater.admin ? 'side_bar_active' : ''"
          (click)="navigate('/admin')"
          *ngIf="sideNavActivate.admin"
        >
          <div class="side_nav_bar_ele">
            <img *ngIf="!activeValidater.admin" src="../../../assets/img/admin.svg"/> 
            <img *ngIf="activeValidater.admin" src="../../../assets/img/admin_green.svg" style="width: 33px; height: 33px;"/> 
            <span *ngIf="showText" @slideInOutAnimation > Admin</span>
          </div>
        </div>
        <div
          class="side_nav_bar_ele_main_div pd_l_1"
          [ngClass]="activeValidater.am ? 'side_bar_active' : ''"
          (click)="navigate('/am')"
          *ngIf="sideNavActivate.accountManager"
        >
          <div class="side_nav_bar_ele">
            <img *ngIf="!activeValidater.accountManager" src="../../../assets/img/am.svg"/> 
            <img *ngIf="activeValidater.accountManager" src="../../../assets/img/am_green.svg" style="width: 33px; height: 33px;"/> 
            <span *ngIf="showText" @slideInOutAnimation > Account Manager</span>
          </div>
        </div>
        <div
          class="side_nav_bar_ele_main_div pd_l_1"
          [ngClass]="activeValidater.bm ? 'side_bar_active' : ''"
          (click)="navigate('/bm')"
          *ngIf="sideNavActivate.bussinessManager"
        >
          <div class="side_nav_bar_ele">
            <img *ngIf="!activeValidater.bussinessManager" src="../../../assets/img/bm.svg"/> 
            <img *ngIf="activeValidater.bussinessManager" src="../../../assets/img/bm_green.svg" style="width: 33px; height: 33px;"/> 
            <span *ngIf="showText" @slideInOutAnimation > Business Manager</span>
          </div>
        </div>
        <div
          class="side_nav_bar_ele_main_div pd_l_1"
          [ngClass]="activeValidater.associate ? 'side_bar_active' : ''"
          (click)="navigate('/asc')"
          *ngIf="sideNavActivate.associate"
        >
          <div class="side_nav_bar_ele">
            <img *ngIf="!activeValidater.associate" src="../../../assets/img/asc.svg"/> 
            <img *ngIf="activeValidater.associate" src="../../../assets/img/asc_green.svg" style="width: 33px; height: 33px;"/> 
            <span *ngIf="showText" @slideInOutAnimation > Associates</span>
          </div>
        </div>
        <div
          class="side_nav_bar_ele_main_div pd_l_1"
          [ngClass]="activeValidater.supplier ? 'side_bar_active' : ''"
          (click)="navigate('/supplier')"
          *ngIf="sideNavActivate.supplier"
        >
          <div class="side_nav_bar_ele">
            <img *ngIf="!activeValidater.supplier" src="../../../assets/img/supplier.svg"/> 
            <img *ngIf="activeValidater.supplier" src="../../../assets/img/supplier_green.svg" style="width: 33px; height: 33px;"/> 
            <span *ngIf="showText" @slideInOutAnimation > Suppliers</span>
          </div>
        </div>
        <div
          class="side_nav_bar_ele_main_div pd_l_1"
          [ngClass]="activeValidater.service ? 'side_bar_active' : ''"
          (click)="navigate('/service')"
        >
          <div class="side_nav_bar_ele">
            <img *ngIf="!activeValidater.service" src="../../../assets/img/sr.svg"/> 
            <img *ngIf="activeValidater.service" src="../../../assets/img/sr_green.svg" style="width: 33px; height: 33px;"/> 
            <span *ngIf="showText" @slideInOutAnimation > Service Requests</span>
          </div>
        </div>
        <div
          class="side_nav_bar_ele_main_div pd_l_1"
          [ngClass]="activeValidater.report ? 'side_bar_active' : ''"
          (click)="navigate('/report')"
          *ngIf="sideNavActivate.report"
        >
          <div class="side_nav_bar_ele">
            <img *ngIf="!activeValidater.report" src="../../../assets/img/reports.svg"/> 
            <img *ngIf="activeValidater.report" src="../../../assets/img/reports_green.svg" style="width: 33px; height: 33px;"/> 
            <span *ngIf="showText" @slideInOutAnimation > Reports</span>
          </div>
        </div>
        <!-- <div class="side_nav_bar_ele_main_div pd_l_1"  [ngClass]="activeValidater.settings?'side_bar_active':''"
                    (click)="navigate('/settings')" *ngIf="sideNavActivate.settings">
                <div class="side_nav_bar_ele">Settings</div>
                </div> -->
      </div>
    </div>
  </div>
  <div class="side_nav_bottom_main_div">
    <div>
      <!-- <div class="logout_div" (click)="navigate('/login')"><span>Logout</span> <img src="../../../assets/img/logout.svg" alt="" > </div>
            <div style="border-top: 2px solid #e6e9e3;padding-top: 12px;">
                <div class="side_nav_bottom_content_div" style="border: none;" [ngClass]="activeValidater.profile?'side_bar_active':''"
                    (click)="navigate('/profile')">
                    <img [src] = "photo" alt="" srcset="" class="side_nav_bottom_dp">
                    <span class="side_nav_bottom_name">{{username}}</span>
                   
                </div>
            </div> -->
    </div>
  </div>
</div>
