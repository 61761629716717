<div class="side_bar_common_css">
  <app-sidenav-bar></app-sidenav-bar>
  <div class="settings_main_div">
    <div class="notificationBar">
      <img
        *ngIf="role == 1"
        src="../../../assets/img/setting.png"
        (click)="navigate('/settings')"
        style="width: 35px; border-radius: 25px; cursor: pointer"
        alt=""
        srcset=""
        class="side_nav_bottom_dp"
      />
      <img
        *ngIf="!isNewNotifications"
        (click)="toggleNotif()"
        src="../../../assets/img/NotifiationBell.svg"
      />
      <img
        *ngIf="isNewNotifications"
        (click)="toggleNotif()"
        src="../../../assets/img/notificationIcon.svg"
      />
      <div class="shwoNotififications" *ngIf="showNotif">
        <div class="d-flex justify-content-end">
          <img
            style="width: 20px"
            (click)="toggleNotif()"
            src="../../../assets/img/carbon_close-filled.svg"
          />
        </div>
        <div *ngIf="notifications.length == 0">
          <div class="notificationHeader">No Notifications Found</div>
        </div>
        <div
          *ngFor="let notif of notifications"
          class="notif"
          (click)="readNotifications(notif)"
        >
          <div class="notificationHeader">
            {{ notif.type.toUpperCase().split("_").join(" ") }}
          </div>
          <div class="notificationContent">
            {{ notif.remarks }}
          </div>
        </div>
      </div>
      <button mat-button [matMenuTriggerFor]="menu" style="padding: 0">
        <img
          [src]="photo"
          style="width: 40px; height: 40px; border-radius: 35px"
          alt=""
          srcset=""
          class="side_nav_bottom_dp"
        />
      </button>
      <mat-menu #menu="matMenu">
        <div
          style="
            color: black;
            padding: 10px;
            font-weight: 700;
            border-bottom: 1px solid #a3c17e;
            width: 100%;
            max-width: 10rem;
          "
        >
          {{ username.toUpperCase() }}
        </div>
        <div style="text-align: center; font-weight: 700; color: grey; font-size: 12px; -webkit-text-stroke: 0.3px grey; border-bottom: 1px solid #a3c17e; padding-inline: 0.5rem;">
          {{ roleId == 1 ? 'Super Admin' : roleId == 2 ? 'Account Manager' : roleId == 3 ? 'Business Manager' : roleId == 5 ? 'Enterprise User' : roleId == 6 ? 'Admin User' : roleId == 7 ? 'Associate User' : roleId == 8 ? 'Supplier User': '' }}
        </div>
        <button mat-menu-item (click)="navigate('/profile')">
          <img style="width: 25px" [src]="tPhoto" alt="" /> Profile
        </button>
        <button mat-menu-item (click)="navigate('/login')">
          <img
            style="width: 25px"
            src="../../../assets/img/logout.svg"
            alt=""
          />
          Logout
        </button>
      </mat-menu>
    </div>
    <div class="header">SETTINGS</div>
    <div class="tabs">
      <div class="leftTab">
        <div
          class="selectorTab"
          (click)="selectorTab('storage')"
          [ngClass]="selectorTabData == 'storage' ? 'borderBottom' : ''"
        >
          STORAGE
        </div>
        <div
          class="selectorTab"
          (click)="selectorTab('license')"
          [ngClass]="selectorTabData == 'license' ? 'borderBottom' : ''"
        >
          LICENSES
        </div>
        <div
          class="selectorTab"
          (click)="selectorTab('pincode')"
          [ngClass]="selectorTabData == 'pincode' ? 'borderBottom' : ''"
        >
          PINCODES
        </div>
      </div>
    </div>
    <div class="settings_content_main_div">
      <div
        class="storageContainer"
        style="height: 40%"
        *ngIf="selectorTabData == 'storage'"
      >
        <div>
          <div class="storage_input_container">
            <label>Individual Retail User Account Storage Limit : </label>
            <input
              type="number"
              name="individualStorage"
              [(ngModel)]="retailStorage"
            />
            <label>mb</label>
          </div>
          <div class="storage_input_container">
            <label>Individual Upload File Size Limit (all accounts) : </label>
            <input
              type="number"
              name="individualStorage"
              [(ngModel)]="individualStorage"
            />
            <label>mb</label>
          </div>
        </div>
        <div style="align-self: flex-end">
          <div class="storageSubmit" (click)="confirmStorageSubmit('storage')">
            SUBMIT
          </div>
        </div>
      </div>
      <div
        class="storageContainer"
        style="height: 40%"
        *ngIf="selectorTabData == 'license'"
      >
        <div>
          <div class="storage_input_container">
            <label>Retail Users License Limit : </label>
            <input
              type="number"
              name="individualStorage"
              [(ngModel)]="retailLicenseCount"
            />
          </div>
        </div>
        <div style="align-self: flex-end">
          <div class="storageSubmit" (click)="confirmStorageSubmit('license')">
            SUBMIT
          </div>
        </div>
      </div>
      <div
      class="storageContainer"
      style="height: 100%;justify-content: flex-start;"
      *ngIf="selectorTabData == 'pincode'"
    >
    <div style="display: flex;align-items: center;justify-content: space-between;margin: 0 1rem 0 1rem">
        <div class="search-container">
          <input
          matInput
          type="number"
          placeholder="Search Pincode"
          [(ngModel)]="searchQuery"
          style="width: 300px;"
          />
          <button (click)="onSearch()">Search</button>
          <button *ngIf="searchQuery" (click)="clearSearch()">Clear</button>
        </div>
        <div class="adding_btn_main_div">
          <button (click)="addPincode()">
            <img src="../../../assets/img/plus_icon.svg" alt="" /><span
              >ADD PINCODE</span
            >
          </button>
    </div>
    </div>
    <table
    mat-table
    [dataSource]="pincodes"
    matSort
    class="mat-elevation-z8"
    style="width: 100%; position: relative"
  >
    <!-- Sl NO -->
    <ng-container matColumnDef="sl_no">
      <th mat-header-cell *matHeaderCellDef>SN.</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{element.id}}
      </td>
    </ng-container>
  
    <ng-container matColumnDef="pincode">
      <th mat-header-cell *matHeaderCellDef>Pincode</th>
      <td mat-cell *matCellDef="let element">
        {{element.pincode}}
      </td>
    </ng-container>
  
    <ng-container matColumnDef="district">
      <th mat-header-cell *matHeaderCellDef>City</th>
      <td mat-cell *matCellDef="let element">
        {{element.district}}
      </td>
    </ng-container>
  
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef>State</th>
      <td mat-cell *matCellDef="let element">
        {{element.state}}
      </td>
    </ng-container>
  
    <ng-container matColumnDef="modify">
      <th mat-header-cell *matHeaderCellDef>Modify</th>
      <td mat-cell *matCellDef="let element">
        <div class="edit_btn_main_div">
          <!-- <button (click)="modifyPincode(element)">Edit</button> -->
          <!-- <button (click)="modifyPincode(element)"> -->
            <img src="../../../assets/img/edit_icon.svg" alt="Edit" style="cursor: pointer;" style="width: 40px; height: 25px;"
            (click)="modifyPincode(element)"> 
          <!-- </button> -->
        </div>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  
  <mat-paginator
    [length]="totalItems"
    [pageSize]="pageSize"
    (page)="onPageChange($event)"
  ></mat-paginator>
    </div>
    </div>
  </div>
</div>
