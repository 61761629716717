<div class="modal-content">
    <mat-dialog-content style="overflow: hidden;">
      <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [title]="chartOptions.title"
        [xaxis]="chartOptions.xaxis"
        [yaxis]="chartOptions.yaxis"
        [subtitle]="chartOptions.subtitle"
        [dataLabels]='chartOptions.dataLabels' 
        [tooltip]="chartOptions.tooltip "
      ></apx-chart>
    </mat-dialog-content>
    <mat-dialog-actions class="action-buttons">
        <button class="buttons left-button" (click)="exportToPDF()">Download PDF</button>
        <button class="buttons right-button" mat-button (click)="dialogRef.close()">Close</button>
    </mat-dialog-actions>
  </div>