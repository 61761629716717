import { map } from 'rxjs';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  OnDestroy,
  ElementRef,
} from '@angular/core';

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
  ApexNonAxisChartSeries,
} from 'ng-apexcharts';
import { title } from 'process';
import { DataService } from 'src/app/services/data-share/data.service';
import * as CryptoJS from 'crypto-js';
import { HttpService } from 'src/app/services/api/http.service';
import { ToastrService } from 'ngx-toastr';
import { AES, enc } from 'crypto-js';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NotificationsComponent } from '../common_dialog/notifications/notifications.component';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterService } from '../reports/filter.service';
// import { BlogPost } from '../blog-scroll/blog-scroll.component';
// import { BlogService} from '../../services/blog.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  @Output() messageEmitter = new EventEmitter<any>();

  // blogPosts: BlogPost[] = [];
  // isLoading = false;

  constructor(
    private dataShare: DataService,
    private router: Router,
    private http: HttpService,
    private toast: ToastrService,
    private dialogueRef: MatDialog,
    private filterService: FilterService
    // private blogService: BlogService
  ) {}


  role: any;
  notifications: any = [];
  isNewNotifications: boolean = false;
  showNotif: boolean = false;
  username: any = 'user';
  photo: any = '../../../assets/img/default_dp.png';
  tPhoto: any = '../../../assets/img/default_dp.png';
  navigate(route: string) {
    if (this.router.url == route) return;
    else this.router.navigate([route]);
  }
// roleId: any; 
  ngOnInit() {
    this.getNotifications();
    const roleIdEncoded = localStorage.getItem('role');
    if (roleIdEncoded) {
      const roleId = AES.decrypt(
        roleIdEncoded,
        this.dataShare.secretKey,
      ).toString(enc.Utf8);
      this.role = roleId;
      console.log(this.role, roleId);
    }
    setTimeout(async () => {
      this.photo = await localStorage.getItem('photo');
      this.username = await localStorage.getItem('username');
    }, 3000);
    // this.isLoading = true;
    // this.blogService.loadInitialPosts().subscribe({
    //   next: (posts) => {
    //     this.blogPosts = posts;
    //     this.isLoading = false;
    //   },
    //   error: () => {
    //     this.isLoading = false;
    //   }
    // });
  }

  navigateWithFilters(filters: FilterMapping[]) {
    // Initialize empty filter object
    const filterValues: Record<FilterCategory, string[]> = {
      document_type: [] as Array<string>,
      status: [] as Array<string>,
      license_number: [] as Array<string>,
    };
  
    // Apply each filter to its proper category
    filters.forEach(filter => {
      if (filterValues[filter.category]) {
        filterValues[filter.category].push(...filter.value.map(v => v.toLowerCase())); // Spread operator for multiple values
      }
    });
  
    // Store the filter values
    this.filterService.setFilters(filterValues);
    
    // Navigate to documents page
    this.router.navigate(['/report']);
  }
  
  getNotifications() {
    this.http.getNotifications(37).subscribe(
      (res: any) => {
        if (res['status']) {
          this.notifications = res['result'];
          const newNotf = this.notifications.filter(
            (data: any) => data.is_read === false,
          );
          console.log(this.notifications, newNotf);
          if (newNotf.length > 0) this.isNewNotifications = true;
          else this.isNewNotifications = false;
        } else {
          this.notifications = [];
          this.isNewNotifications = false;
        }
      },
      (err: any) => {
        this.toast.error('Oops!, Somrthing went wrong Please try again', '', {
          timeOut: 2000,
        });
      },
    );
  }
  moveTab(tab: any, to: any = 'Enterprise') {
    if(tab == 'reports')
      {
        this.router.navigate(['/report']);
      }  
    else{
      this.messageEmitter.emit(tab);
      this.dataShare.prefilter = to;
    }

  }
  readNotifications(element: any) {
    this.moveTab('service', element.type == 'eu' ? 'Enterprise' : 'Retail');
    this.dataShare.dialogRef(element.service_request_id, element.type);
    let data = {
      id: element.id,
      is_read: 1,
    };
    this.http.readNotification(data).subscribe(
      (res: any) => {
        if (res['status']) {
          this.getNotifications();
        } else {
          this.toast.error(res['message'], '', { timeOut: 2000 });
        }
      },
      (err: any) => {
        this.toast.error('Oops!, Somrthing went wrong Please try again', '', {
          timeOut: 2000,
        });
      },
    );
  }
  toggleNotif() {
    // this.showNotif = !this.showNotif
    this.dialogueRef
      .open(NotificationsComponent, {
        data: this.notifications,
        // position: {
        //   top: `5rem`,
        //   right: `25rem`,
        // },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.getNotifications();
        }
      });
  }
  changeTab(tab: any, to: any = 'Enterprise') {
    this.dataShare.prefilter = to;
    this.router.navigate(['/' + tab]);
  }
}
@Component({
  selector: 'app-u2-dashboard',
  templateUrl: './u_2.dashboard.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardU2Component implements OnInit {
  @Output() messageEmitter = new EventEmitter<any>();

  totalUsersStatus: any;
  totalLicenseStatus: any;
  totalExpiringLicensesStatus: any;
  totalServiceRequests: any;
  serviceRequest: any = {};
  totalDocumentData: any;
  role: any;

  constructor(
    private dataShare: DataService,
    private http: HttpService,
    private router: Router,
    private filterService: FilterService
  ) {}
  ngOnInit(): void {
    const roleIdEncoded = localStorage.getItem('role');
    if (roleIdEncoded) {
      const roleId = AES.decrypt(
        roleIdEncoded,
        this.dataShare.secretKey,
      ).toString(enc.Utf8);
      this.role = roleId;
      console.log(this.role, roleId);
    }
    this.getUsersData();
    this.getLicenseStatus();
    this.getExpiringLicenseStatus();
    this.getDocumentsCount();
    this.serviceChatMonthHander('1');
    this.getServiceRequests();
    this.getLicenseData();
    window.dispatchEvent(new Event('resize'));
    for (var i = 4; i > 0; i--) {
      this.yearDropDown.push(this.currentYear - i);
    }
    this.yearDropDown.push(this.currentYear);
  }
  yearDropDown: any = [];
  currentYear: any = new Date().getFullYear();
  totalLicenseData: ApexNonAxisChartSeries = [];
  lableLicense: any = ['Active Licenses', 'Expired Licenses'];
  colorsLicense: any = ['#7CC3B5', '#E0473E'];
  pieChats: ApexChart = {
    type: 'pie',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    height: 250,
  };
  pieLegend: ApexLegend = {
    show: false,
  };
  colors: any = ['#7CC3B5', '#C6372E'];
  dataLabels: ApexDataLabels = {
    enabled: false,
  };
  totalExpiringLicenseData: ApexNonAxisChartSeries = [];
  lableExpiringLicense: any = [
    'Expiring in less than 30 days',
    'Expiring in 60 days',
    'Expiring in 90 days',
  ];
  colorsExpiringLicense: any = ['#E0473E', '#F59E5F', '#F5DD5F'];
  // License request
  licenseRequestData: ApexAxisChartSeries = [
    {
      name: 'License Requested',
      data: [0, 0, 0],
      color: '#247DD0',
    },
    {
      name: 'Solved Requested',
      data: [0, 0, 0],
      color: '#6AAD41',
    },
  ];

  // service request
  serviceRequestData: ApexAxisChartSeries = [
    {
      name: 'Raised Service Request',
      data: [76, 85, 80],
      color: '#B75FDC',
    },
    {
      name: 'Solved Requested',
      data: [44, 30, 57],
      color: '#6AAD41',
    },
  ];
  //common bar chart config
  chats: ApexChart = {
    type: 'bar',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    height: 268,
  };
  plotOptions: ApexPlotOptions = {
    bar: {
      columnWidth: '80%',
    },
  };
  totalServiceRequestData: ApexNonAxisChartSeries = [];
  serviceXaxisCount: number = 0;
  serviceXAxisLabel = ['30 Days', '60 Days', '90 Days'];
  serviceXaxis: ApexXAxis = {
    categories: this.serviceXAxisLabel,
  };
  yaxis!: {
    min: 0;
    max: 200;
    tickAmount: 4;
  };
  legend: ApexLegend = {
    position: 'right',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '13px',
    markers: {
      width: 25,
      height: 25,
    },
    horizontalAlign: 'center',
    onItemClick: {
      toggleDataSeries: false,
    },
  };
  //Service Data
  totalServiceData: ApexNonAxisChartSeries = [];
  lableService: any = [
    'Renew License',
    'Modify License',
    'File Annual Returns',
    'Health check',
    'Upload Test Reports',
    'Product Testing',
    'Water Testing',
    'Pest Control',
    'Equipment Callibration',
    'Training',
    'Internal Audit',
    'Third-party Audits',
    'New license',
  ];
  colorsService: any = [
    '#7CC3B5',
    '#9FC4FB',
    '#F66D44',
    '#FEAE65',
    '#E6F69D',
    '#AADEA7',
    '#64C2A6',
    '#2D87BB',
    '#6AAD41',
  ];

  allMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  selectedServiceMonth: any = '1';
  serviceChatMonthHander(data: any) {
    console.log(data);
    this.http
      .getServiceChatMonthHander(data, this.currentYear)
      .subscribe((res: any) => {
        if (res['status']) {
          let temp = res['results'];
          console.log(typeof temp);
          var v: any;
          let raised_requests = [];
          let solved_requests = [];
          for (v of Object.values(temp)) {
            raised_requests.push(v['raised_requests']);
            solved_requests.push(v['solved_requests']);
          }

          this.serviceRequestData = [
            {
              name: 'Raised Service Request',
              data: raised_requests,
              color: '#B75FDC',
            },
            {
              name: 'Solved Requested',
              data: solved_requests,
              color: '#6AAD41',
            },
          ];
        }
      });
  }

  navigateWithFilters(filters: FilterMapping[]) {
    // Initialize empty filter object
    const filterValues: Record<FilterCategory, string[]> = {
      document_type: [] as Array<string>,
      status: [] as Array<string>,
      license_number: [] as Array<string>,
    };
  
    // Apply each filter to its proper category
    filters.forEach(filter => {
      if (filterValues[filter.category]) {
        filterValues[filter.category].push(...filter.value.map(v => v.toLowerCase())); // Spread operator for multiple values
      }
    });
  
    // Store the filter values
    this.filterService.setFilters(filterValues);
    
    // Navigate to documents page
    this.router.navigate(['/report']);
  }

  moveTab(tab: any, to: any = 'Enterprise', filter: any = 'all') {
    if(tab == 'reports')
      {
        this.router.navigate(['/report']);
      }  
      else{
        this.messageEmitter.emit(tab);
        this.dataShare.prefilter = to;
        this.dataShare.preStatusFilter = filter;
      }

  }
  getUsersData() {
    this.http.getUserStatusData().subscribe((res: any) => {
      if (res['status']) {
        this.totalUsersStatus = res['results'];
      }
    });
  }
  getLicenseData() {
    this.http.getBMExpiringLicenseCount().subscribe((res: any) => {
      if (res['status']) {
        const data = res['results'];
        this.licenseRequestData[0].data = [];
        this.licenseRequestData[1].data = [];
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            const element = data[key];
            this.licenseRequestData[0].data.push(element.license_requests);
            this.licenseRequestData[1].data.push(element.solved_requests);
          }
        }
        this.licenseRequestData = JSON.parse(
          JSON.stringify(this.licenseRequestData),
        );
      }
    });
  }
  documentsCount: any = {};
  getDocumentsCount() {
    if (this.role === undefined || this.role === null) {
      console.error("Role is undefined, cannot fetch document count");
      return;
    }
    this.http.getDasDocumentsCount(this.role)?.subscribe((res: any) => {
      if (res['status']) {
        this.documentsCount = res['results'];
        this.totalDocumentData.push(this.documentsCount.active_documents);
        this.totalDocumentData.push(this.documentsCount.expired_documents);
        this.totalDocumentData.push(
          this.documentsCount.documents_need_to_upload,
        );
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.ApplicationDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.ApplicationDocuments);
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.TestDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.TestDocuments); 
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.TrainingDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.TrainingDocuments);
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.HealthDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.HealthDocuments);
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.CalibrationDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.CalibrationDocuments);
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.OthersDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.OthersDocuments);
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.AuditDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.AuditDocuments);        

      }
      (error: any) => {
        console.error("Error fetching document count:", error);
      }
      // this.totalDocumentDataPie = this.totalDocumentData.slice(0, 3);
    });
  }
  getLicenseStatus() {
    this.http.getLicenseStatus().subscribe((res: any) => {
      if (res['status']) {
        this.totalLicenseStatus = res['results'];
        console.log(this.totalLicenseStatus);

        this.totalLicenseData = [
          this.totalLicenseStatus.active_licenses,
          this.totalLicenseStatus.expired_licenses,
        ];
      }
    }); 
  }
  getExpiringLicenseStatus() {
    this.http.getExpiringLicenseStatus().subscribe((res: any) => {
      if (res['status']) {
        console.log(res['results']);
        // totalExpiringLicenseData

        this.totalExpiringLicensesStatus = res['results'];
        this.totalExpiringLicenseData = [
          this.totalExpiringLicensesStatus.expiring_in_less_than_30_days,
          this.totalExpiringLicensesStatus.expiring_in_less_than_60_days,
          this.totalExpiringLicensesStatus.expiring_in_less_than_90_days,
        ];
      }
    });
  }
  getServiceRequests() {
    this.http.getServiceRequests().subscribe((res: any) => {
      if (res['status']) {
        let t = res['results'];
        let v: any;
        for (v of Object.values(t)) {
          switch (Object.keys(v)[0]) {
            case 'total_service_requests':
              this.serviceRequest.total_service_requests = Object.values(v)[0];
              break;
            case 'pending_service_requests':
              this.serviceRequest.pending_service_requests =
                Object.values(v)[0];
              break;
            case 'Renew license':
              this.serviceRequest.renew_license = Object.values(v)[0];
              break;
            case 'Modify license':
              this.serviceRequest.modify_license = Object.values(v)[0];
              break;
            case 'File Annual Returns':
              this.serviceRequest.file_annual_returns = Object.values(v)[0];
              break;
            case 'Product Testing':
              this.serviceRequest.product_testing = Object.values(v)[0];
              break;
            case 'Water Testing':
              this.serviceRequest.water_testing = Object.values(v)[0];
              break;
            case 'Pest Control':
              this.serviceRequest.pest_control = Object.values(v)[0];
              break;
            case 'Health Check':
              this.serviceRequest.health_check = Object.values(v)[0];
              break;
            case 'Equipment Callibration':
              this.serviceRequest.equ_callibration = Object.values(v)[0];
              break;
            case 'Upload Test Reports':
              this.serviceRequest.upload_test_reports = Object.values(v)[0];
              break;
            case 'Training':
              this.serviceRequest.training = Object.values(v)[0];
              break;
            case 'Internal Audit':
              this.serviceRequest.audit = Object.values(v)[0];
              break;
            case 'Third-party Audits':
              this.serviceRequest.third = Object.values(v)[0];
              break;
            case 'New license':
              this.serviceRequest.new = Object.values(v)[0];
              break;
          }
        }
        this.totalServiceRequests = res['results'];
        this.totalServiceRequestData = [
          this.serviceRequest.renew_license,
          this.serviceRequest.modify_license,
          this.serviceRequest.file_annual_returns,
          this.serviceRequest.health_check,
          this.serviceRequest.upload_test_reports,
          this.serviceRequest.product_testing,
          this.serviceRequest.water_testing,
          this.serviceRequest.pest_control,
          this.serviceRequest.equ_callibration,
          this.serviceRequest.training,
          this.serviceRequest.audit,
          this.serviceRequest.third,
          this.serviceRequest.new,
        ];
      }
    });
  }
}

@Component({
  selector: 'app-u0-dashboard',
  templateUrl: './u_0.dashboard.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardU0Component implements OnInit {
  @Output() messageEmitter = new EventEmitter<any>();

  @ViewChild('chart') chart!: ChartComponent;
  totalDocumentData: any;
  constructor(
    private dataShare: DataService,
    private httpSv: HttpService,
    private toster: ToastrService,
    private router :Router,
    private filterService: FilterService
  ) {}

  ngOnInit(): void {
    // Decrypt the value using AES decryption with the secret key
    const role = localStorage.getItem('role');
    if (role) {
      const bytes = CryptoJS.AES.decrypt(role, this.dataShare.secretKey);
      // Convert the decrypted bytes back to a string
      const decrypted = bytes.toString(CryptoJS.enc.Utf8);
      console.log(decrypted);
    }

    const roleIdEncoded = localStorage.getItem('role');
    if (roleIdEncoded) {
      const role = AES.decrypt(
        roleIdEncoded,
        this.dataShare.secretKey,
      ).toString(enc.Utf8);
      this.roleId = role;
      console.log(this.roleId, role);
    }
    for (var i = 4; i > 0; i--) {
      this.yearDropDown.push(this.currentYear - i);
    }
    this.yearDropDown.push(this.currentYear);
    this.selectedServiceMonth = this.allMonths[new Date().getMonth()];

    this.getAccManagerCount();
    this.getBusinessManagerCount();
    this.getEnterpriserCount();
    this.getRetailUsersCount();

    this.getDocumentsCount();
    this.getAllLicenseCount();
    this.getExpiringLicenseCount();
    this.getSAExpiringLicenseCount();

    this.serviceRequestMonthHander(this.data);
    this.getServiceRequestCount();
    window.dispatchEvent(new Event('resize'));
  }
  roleId: any;
  currentYear: any = new Date().getFullYear();
  yearDropDown: any = [];
  accountManagerData: any = [];
  businessManagerData: any = [];
  enterpriserData: any = [];
  retailUserData: any = [];
  allLicenseData: any = [];
  // expiringLicenseData: any = [];
  // totalServiceRequestData: any = [];

  totalAccountManager: any = 10;
  licenseLabel: any = ['30 Days', '60 Days', '90 Days'];
  //PIE CHART BELOW
  xaxisLebels: ApexXAxis = {
    categories: this.licenseLabel,
  };
  pieCharts: ApexChart = {
    type: 'pie',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    height: 250,
  };
  pieLegend: ApexLegend = {
    show: false,
  };

  // pieChartsa: ApexChart = {
  //   type: "pie",
  //   zoom: {
  //     enabled: false
  //   },
  //   toolbar: {
  //     show: false
  //   },
  //   height: 250,
  // }
  // pieLegenda: ApexLegend = {
  //   show: false
  // }

  //SALicense Data
  totalLicenseData: ApexNonAxisChartSeries = [];
  labelLicense: any = ['Active', 'In active'];
  totalLicensecolors: any = ['#7CC3B5', '#C6372E'];

  //SAExpiringLicense Data
  expiringLicenseData: ApexNonAxisChartSeries = [];
  labelExpLicense: any = [
    'Expiring in 30 days',
    'Expiring in 60 days',
    'Expiring in 90 days',
  ];
  expLicenseColors: any = ['#E0473E', '#F59E5F', '#F5DD5F'];

  //SATotalServiceRequest Data
  totalServiceRequestData: ApexNonAxisChartSeries = [];
  labelServiceRequest: any = [
    'Renew License',
    'Modify license',
    'File Annual Returns',
    'Product Testing',
    'Water Testing',
    'Pest Control',
    'Health_check',
    'Equipment Callibration',
    'Upload Test Reports',
    'Training',
    'Internal Audit',
    'Third-party Audits',
    'New license',
  ];
  serviceRequestColors: any = [
    '#7CC3B5',
    '#9FC4FB',
    '#F66D44',
    '#AADEA7',
    '#64C2A6',
    '#2D87BB',
    '#FEAE65',
    '#6AAD41',
    '#E6F69D',
    '#2D87BB',
    '#FEAE65',
    '#6AAD41',
    '#AADEA7',
  ];

  // labelServiceRequest: any = [];

  // BAR CHART BELOW
  // service request
  licenseRequestData: ApexAxisChartSeries = [
    {
      name: 'License Requested',
      data: [0, 0, 0, 0],
      color: '#247DD0',
    },
    {
      name: 'Solved Requested',
      data: [0, 0, 0, 0],
      color: '#6AAD41',
    },
  ];
  // Licence request data
  data: ApexAxisChartSeries = [
    {
      name: 'License Requested',
      data: [44, 30, 57, 56, 61],
      color: '#247DD0',
    },
    {
      name: 'Solved Requested',
      data: [76, 85, 80, 98, 87],
      color: '#6AAD41',
    },
  ];
  // service request
  serviceRequestData: ApexAxisChartSeries = [
    {
      name: 'Service Requests Raised',
      data: [0, 0, 0, 0],
      color: '#B75FDC',
    },
    {
      name: 'Service Requests Resolved',
      data: [0, 0, 0, 0],
      color: '#6AAD41',
    },
  ];
  //common bar chart config
  barCharts: ApexChart = {
    type: 'bar',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    height: 250,
  };
  dataLabels: ApexDataLabels = {
    enabled: false,
  };
  legend: ApexLegend = {
    position: 'right',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '13px',
    markers: {
      width: 25,
      height: 25,
    },
    horizontalAlign: 'center',
    onItemClick: {
      toggleDataSeries: false,
    },
  };

  xaxisCount: number = 0;
  xAxisLabel = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'];
  xaxis: ApexXAxis = {
    categories: this.xAxisLabel,
  };
  plotOptions: ApexPlotOptions = {
    bar: {
      columnWidth: '80%',
    },
  };

  // serviceRequestData: ApexAxisChartSeries = [
  //   {
  //     name: "Service Requested",
  //     data: [76, 85, 80, 98, 87],
  //     color: '#B75FDC'
  //   },
  //   {
  //     name: "Solved Requested",
  //     data: [44, 30, 57, 56, 61],
  //     color: '#6AAD41'
  //   },
  // ]

  // User Management

  accountManagerCount: any = {};
  getAccManagerCount() {
    this.httpSv.getAccManagerCount().subscribe((res: any) => {
      if (res['status']) {
        this.accountManagerCount = res['results'];
        this.accountManagerData.push(
          this.accountManagerCount.total_account_manager,
        );
        this.accountManagerData.push(
          this.accountManagerCount.total_active_account_manager,
        );
        this.accountManagerData.push(
          this.accountManagerCount.total_inactive_account_manager,
        );
        this.accountManagerData.push(
          this.accountManagerCount.total_unassigned_account_manager,
        );
      }
    });
  }

  navigateWithFilters(filters: FilterMapping[]) {
    // Initialize empty filter object
    const filterValues: Record<FilterCategory, string[]> = {
      document_type: [] as Array<string>,
      status: [] as Array<string>,
      license_number: [] as Array<string>,
    };
  
    // Apply each filter to its proper category
    filters.forEach(filter => {
      if (filterValues[filter.category]) {
        filterValues[filter.category].push(...filter.value.map(v => v.toLowerCase())); // Spread operator for multiple values
      }
    });
  
    // Store the filter values
    this.filterService.setFilters(filterValues);
    
    // Navigate to documents page
    this.router.navigate(['/report']);
  }

  moveTab(tab: any, to: any = 'Enterprise', filter: any = 'all') {
    if(tab == 'reports')
      {
        this.router.navigate(['/report']);
      }  
      else{
        this.messageEmitter.emit(tab);
        this.dataShare.prefilter = to;
        this.dataShare.preStatusFilter = filter;
      }

  }
  businessManagerCount: any = {};
  getBusinessManagerCount() {
    this.httpSv.getBusinessManagerCount().subscribe((res: any) => {
      if (res['status']) {
        this.businessManagerCount = res['results'];
        this.businessManagerData.push(
          this.businessManagerCount.total_business_manager,
        );
        this.businessManagerData.push(
          this.businessManagerCount.total_active_business_manager,
        );
        this.businessManagerData.push(
          this.businessManagerCount.total_inactive_business_manager,
        );
      }
    });
  }

  enterpriserCount: any = {};
  getEnterpriserCount() {
    this.httpSv.getEnterpriserCount().subscribe((res: any) => {
      if (res['status']) {
        this.enterpriserCount = res['results'];
        this.enterpriserData.push(this.enterpriserCount.total_enterprise_user);
        this.enterpriserData.push(
          this.enterpriserCount.total_active_enterprise_user,
        );
        this.enterpriserData.push(
          this.enterpriserCount.total_inactive_enterprise_user,
        );
      }
    });
  }

  retailUserCount: any = {};
  getRetailUsersCount() {
    this.httpSv.getRetailUserCount().subscribe((res: any) => {
      if (res['status']) {
        this.retailUserCount = res['results'];
        this.retailUserData.push(this.retailUserCount.total_retail_user);
        this.retailUserData.push(this.retailUserCount.total_active_retail_user);
        this.retailUserData.push(
          this.retailUserCount.total_inactive_retail_user,
        );
      }
    });
  }

  // Licenses

  allLicenseCount: any = {};
  getAllLicenseCount() {
    this.httpSv.getAllLicenseCount().subscribe((res: any) => {
      if (res['status']) {
        // this.allLicenseCount = res['results']
        // this.allLicenseData.push(this.allLicenseCount.total_licenses)
        // this.allLicenseData.push(this.allLicenseCount.active_licenses)
        // this.allLicenseData.push(this.allLicenseCount.expired_licenses)
        // this.allLicenseData.push(this.allLicenseCount.requested_licenses)
        // this.totalLicenseData.push(this.allLicenseCount.active_licenses)
        // this.totalLicenseData.push(this.allLicenseCount.expired_licenses)

        this.allLicenseCount = res['results'];
        let arr1: any = [];
        arr1.push(this.allLicenseCount['total_licenses']);
        arr1.push(this.allLicenseCount['active_licenses']);
        arr1.push(this.allLicenseCount['expired_licenses']);
        arr1.push(this.allLicenseCount['requested_licenses']);

        let arr: any = [];
        arr.push(this.allLicenseCount['active_licenses']);
        arr.push(this.allLicenseCount['expired_licenses']);
        setTimeout(() => {
          this.totalLicenseData = arr;
          this.allLicenseData = arr1;
        }, 0);
      }
    });
  }

  expiringLicenseCount: any = {};
  getExpiringLicenseCount() {
    this.httpSv.getExpiringLicenseCount().subscribe((res: any) => {
      if (res['status']) {
        this.expiringLicenseCount = res['results'];
        let arr: any = [];
        arr.push(this.expiringLicenseCount.expiring_in_less_than_30_days);
        arr.push(this.expiringLicenseCount.expiring_in_less_than_60_days);
        arr.push(this.expiringLicenseCount.expiring_in_less_than_90_days);
        this.expiringLicenseData = arr;
        // this.expiringLicenseData.push(this.expiringLicenseCount.expiring_in_less_than_30_days)
        // this.expiringLicenseData.push(this.expiringLicenseCount.expiring_in_less_than_60_days)
        // this.expiringLicenseData.push(this.expiringLicenseCount.expiring_in_less_than_90_days)
      }
    });
  }
  getSAExpiringLicenseCount() {
    this.httpSv.getSAExpiringLicenseCount().subscribe((res: any) => {
      if (res['status']) {
        const data = res['results'];
        this.licenseRequestData[0].data = [];
        this.licenseRequestData[1].data = [];
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            const element = data[key];
            this.licenseRequestData[0].data.push(element.license_requests);
            this.licenseRequestData[1].data.push(element.solved_requests);
          }
        }
        this.licenseRequestData = JSON.parse(
          JSON.stringify(this.licenseRequestData),
        );
      }
    });
  }

  // Service Request

  allMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  selectedServiceMonth: any;
  serviceRequestMonthHander(currentmonth: any) {
    // console.log(this.selectedServiceMonth, "showSelectedMonth")

    const month = this.allMonths.findIndex(
      (ele: any) => ele == this.selectedServiceMonth,
    );

    this.getServiceRequestByMonth(month);
  }
  getServiceRequestByMonth(monthData: any = null) {
    let month: any;

    if (!(monthData == null || monthData == undefined)) month = monthData;
    else month = new Date().getMonth();

    this.httpSv
      .getServiceRequestByMonth(Number(month) + Number(1), this.currentYear)
      .subscribe((res: any) => {
        if (res['status']) {
          this.selectedServiceMonth = this.allMonths[month];
          const data = res['results'];
          this.serviceRequestData[0].data = [];
          this.serviceRequestData[1].data = [];
          for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
              const element = data[key];
              this.serviceRequestData[0].data.push(element.raised_requests);
              this.serviceRequestData[1].data.push(element.solved_requests);
            }
          }
          this.serviceRequestData = JSON.parse(
            JSON.stringify(this.serviceRequestData),
          );
        }
      });
  }
  documentsCount: any = {};
  getDocumentsCount() {
    this.httpSv.getDasDocumentsCount(this.roleId)?.subscribe((res: any) => {
      if (res['status']) {
        this.documentsCount = res['results'];
        this.totalDocumentData.push(this.documentsCount.active_documents);
        this.totalDocumentData.push(this.documentsCount.expired_documents);
        this.totalDocumentData.push(
          this.documentsCount.documents_need_to_upload,
        );
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.ApplicationDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.ApplicationDocuments);
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.TestDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.TestDocuments); 
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.TrainingDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.TrainingDocuments);
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.HealthDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.HealthDocuments);
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.CalibrationDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.CalibrationDocuments);
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.OthersDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.OthersDocuments);
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.AuditDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.AuditDocuments);        

      }
      // this.totalDocumentDataPie = this.totalDocumentData.slice(0, 3);
    });
  }

  serviceRequestCount: any = {};
  getServiceRequestCount() {
    this.httpSv.getSAServiceRequestCount().subscribe((res: any) => {
      if (res['status']) {
        res['results'].map((ele: any) => {
          this.serviceRequestCount[Object.keys(ele)[0]] =
            ele[Object.keys(ele)[0]];
        });
        let arr = [];
        // arr.push(this.serviceRequestCount.total_service_requests)
        // arr.push(this.serviceRequestCount.pending_service_requests)
        arr.push(this.serviceRequestCount['Renew license']);
        arr.push(this.serviceRequestCount['Modify license']);
        arr.push(this.serviceRequestCount['File Annual Returns']);
        arr.push(this.serviceRequestCount['Product Testing']);
        arr.push(this.serviceRequestCount['Water Testing']);
        arr.push(this.serviceRequestCount['Pest Control']);
        arr.push(this.serviceRequestCount['Health Check']);
        arr.push(this.serviceRequestCount['Equipment Callibration']);
        arr.push(this.serviceRequestCount['Upload Test Reports']);
        arr.push(this.serviceRequestCount['Training']);
        arr.push(this.serviceRequestCount['Internal Audit']);
        arr.push(this.serviceRequestCount['Third-party Audits']);
        arr.push(this.serviceRequestCount['New license']);
        this.totalServiceRequestData = arr;
      }
    });
  }
}

@Component({
  selector: 'app-u1-dashboard',
  templateUrl: './u_1.dashboard.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardU1Component implements OnInit {
  @Output() messageEmitter = new EventEmitter<any>();

  @ViewChild('chart') chart!: ChartComponent;
  @Output() chartDataChange = new EventEmitter<any>();
  totalDocumentData: any;
  constructor(
    private dataShare: DataService,
    private httpSv: HttpService,
    private toster: ToastrService,
    private router :Router,
    private filterService: FilterService
  ) {}

  ngOnInit(): void {
    // Decrypt the value using AES decryption with the secret key
    const role = localStorage.getItem('role');
    if (role) {
      const bytes = CryptoJS.AES.decrypt(role, this.dataShare.secretKey);
      // Convert the decrypted bytes back to a string
      const decrypted = bytes.toString(CryptoJS.enc.Utf8);
      console.log(decrypted);
    }

    const roleIdEncoded = localStorage.getItem('role');
    if (roleIdEncoded) {
      const role = AES.decrypt(
        roleIdEncoded,
        this.dataShare.secretKey,
      ).toString(enc.Utf8);
      this.roleId = role;
      console.log(this.roleId, role);
    }

    this.selectedServiceMonth = this.allMonths[new Date().getMonth()];
    for (var i = 4; i > 0; i--) {
      this.yearDropDown.push(this.currentYear - i);
    }
    this.yearDropDown.push(this.currentYear);
    this.getBusinessManagerCount();
    this.getEnterpriserCount();
    this.getRetailUsersCount();
    this.getDocumentsCount();
    this.getAllLicenseCount();
    this.getExpiringLicenseCount();
    this.getAMExpiringLicenseCount();
    this.serviceRequestMonthHander(this.data);
    this.getServiceRequestCount();
    window.dispatchEvent(new Event('resize'));
    window.dispatchEvent(new Event('resize'));
  }
  roleId: any;
  yearDropDown: any = [];
  currentYear: any = new Date().getFullYear();
  accountManagerData: any = [];
  businessManagerData: any = [];
  enterpriserData: any = [];
  retailUserData: any = [];
  allLicenseData: any = [];
  // expiringLicenseData: any = [];
  // totalServiceRequestData: any = [];

  totalAccountManager: any = 10;

  //PIE CHART BELOW

  pieCharts: ApexChart = {
    type: 'pie',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    height: 250,
  };
  pieLegend: ApexLegend = {
    show: false,
  };

  // pieChartsa: ApexChart = {
  //   type: "pie",
  //   zoom: {
  //     enabled: false
  //   },
  //   toolbar: {
  //     show: false
  //   },
  //   height: 250,
  // }
  // pieLegenda: ApexLegend = {
  //   show: false
  // }

  //SALicense Data
  totalLicenseData: ApexNonAxisChartSeries = [];
  labelLicense: any = ['Active', 'In active'];
  totalLicensecolors: any = ['#7CC3B5', '#C6372E'];

  //SAExpiringLicense Data
  expiringLicenseData: ApexNonAxisChartSeries = [];
  labelExpLicense: any = [
    'Expiring in 30 days',
    'Expiring in 60 days',
    'Expiring in 90 days',
  ];
  expLicenseColors: any = ['#E0473E', '#F59E5F', '#F5DD5F'];

  //SATotalServiceRequest Data
  totalServiceRequestData: ApexNonAxisChartSeries = [];
  labelServiceRequest: any = [
    'Renew License',
    'Modify license',
    'File Annual Returns',
    'Product Testing',
    'Water Testing',
    'Pest Control',
    'Health_check',
    'Equipment Callibration',
    'Upload Test Reports',
    'Training',
    'Internal Audit',
    'Third-party Audits',
    'New license',
  ];
  serviceRequestColors: any = [
    '#7CC3B5',
    '#9FC4FB',
    '#F66D44',
    '#AADEA7',
    '#64C2A6',
    '#2D87BB',
    '#FEAE65',
    '#6AAD41',
    '#E6F69D',
  ];

  licenseLabel: any = ['30 Days', '60 Days', '90 Days'];
  // labelServiceRequest: any = [];

  // BAR CHART BELOW

  // Licence request data
  data: ApexAxisChartSeries = [
    {
      name: 'License Requested',
      data: [44, 30, 57, 56, 61],
      color: '#247DD0',
    },
    {
      name: 'Solved Requested',
      data: [76, 85, 80, 98, 87],
      color: '#6AAD41',
    },
  ];
  // service request
  serviceRequestData: ApexAxisChartSeries = [
    {
      name: 'Service Request Raised',
      data: [0, 0, 0, 0],
      color: '#B75FDC',
    },
    {
      name: 'Service Request Resolved',
      data: [0, 0, 0, 0],
      color: '#6AAD41',
    },
  ];
  // service request
  licenseRequestData: ApexAxisChartSeries = [
    {
      name: 'License Requested',
      data: [0, 0, 0],
      color: '#247DD0',
    },
    {
      name: 'Solved Requested',
      data: [0, 0, 0],
      color: '#6AAD41',
    },
  ];
  //common bar chart config
  barCharts: ApexChart = {
    type: 'bar',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    height: 250,
  };
  dataLabels: ApexDataLabels = {
    enabled: false,
  };
  legend: ApexLegend = {
    position: 'right',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '13px',
    markers: {
      width: 25,
      height: 25,
    },
    horizontalAlign: 'center',
    onItemClick: {
      toggleDataSeries: false,
    },
  };

  xaxisCount: number = 0;
  xAxisLabel = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'];
  xaxis: ApexXAxis = {
    categories: this.xAxisLabel,
  };
  xaxisLebels: ApexXAxis = {
    categories: this.licenseLabel,
  };
  plotOptions: ApexPlotOptions = {
    bar: {
      columnWidth: '80%',
    },
  };

  // serviceRequestData: ApexAxisChartSeries = [
  //   {
  //     name: "Service Requested",
  //     data: [76, 85, 80, 98, 87],
  //     color: '#B75FDC'
  //   },
  //   {
  //     name: "Solved Requested",
  //     data: [44, 30, 57, 56, 61],
  //     color: '#6AAD41'
  //   },
  // ]

  // User Management

  navigateWithFilters(filters: FilterMapping[]) {
    // Initialize empty filter object
    const filterValues: Record<FilterCategory, string[]> = {
      document_type: [] as Array<string>,
      status: [] as Array<string>,
      license_number: [] as Array<string>,
    };
  
    // Apply each filter to its proper category
    filters.forEach(filter => {
      if (filterValues[filter.category]) {
        filterValues[filter.category].push(...filter.value.map(v => v.toLowerCase())); // Spread operator for multiple values
      }
    });
  
    // Store the filter values
    this.filterService.setFilters(filterValues);
    
    // Navigate to documents page
    this.router.navigate(['/report']);
  }

  moveTab(tab: any, to: any = 'Enterprise', filter: any = 'all') {
    if(tab == 'reports')
      {
        this.router.navigate(['/report']);
      }  
      else{
        this.messageEmitter.emit(tab);
        this.dataShare.prefilter = to;
        this.dataShare.preStatusFilter = filter;
      }

  }
  businessManagerCount: any = {};
  getBusinessManagerCount() {
    this.httpSv.getAMBusinessManagerCount().subscribe((res: any) => {
      if (res['status']) {
        this.businessManagerCount = res['results'];
        this.businessManagerData.push(
          this.businessManagerCount.total_business_manager,
        );
        this.businessManagerData.push(
          this.businessManagerCount.total_active_business_manager,
        );
        this.businessManagerData.push(
          this.businessManagerCount.total_inactive_business_manager,
        );
      }
    });
  }

  enterpriserCount: any = {};
  getEnterpriserCount() {
    this.httpSv.getAMEnterpriserCount().subscribe((res: any) => {
      if (res['status']) {
        this.enterpriserCount = res['results'];
        this.enterpriserData.push(this.enterpriserCount.total_enterprise_user);
        this.enterpriserData.push(
          this.enterpriserCount.total_active_enterprise_user,
        );
        this.enterpriserData.push(
          this.enterpriserCount.total_inactive_enterprise_user,
        );
        this.enterpriserData.push(
          this.enterpriserCount.total_unassigned_enterprise_user,
        );
      }
    });
  }

  retailUserCount: any = {};
  getRetailUsersCount() {
    this.httpSv.getAMRetailUserCount().subscribe((res: any) => {
      if (res['status']) {
        this.retailUserCount = res['results'];
        this.retailUserData.push(this.retailUserCount.total_retail_user);
        this.retailUserData.push(this.retailUserCount.total_active_retail_user);
        this.retailUserData.push(
          this.retailUserCount.total_inactive_retail_user,
        );
      }
    });
  }

  // Licenses

  allLicenseCount: any = {};
  getAllLicenseCount() {
    this.httpSv.getAMAllLicenseCount().subscribe((res: any) => {
      if (res['status']) {
        // this.allLicenseCount = res['results']
        // this.allLicenseData.push(this.allLicenseCount.total_licenses)
        // this.allLicenseData.push(this.allLicenseCount.active_licenses)
        // this.allLicenseData.push(this.allLicenseCount.expired_licenses)
        // this.allLicenseData.push(this.allLicenseCount.requested_licenses)
        // this.totalLicenseData.push(this.allLicenseCount.active_licenses)
        // this.totalLicenseData.push(this.allLicenseCount.expired_licenses)
        this.allLicenseCount = res['results'];
        let arr1: any = [];
        arr1.push(this.allLicenseCount['total_licenses']);
        arr1.push(this.allLicenseCount['active_licenses']);
        arr1.push(this.allLicenseCount['expired_licenses']);
        arr1.push(this.allLicenseCount['requested_licenses']);

        let arr: any = [];
        arr.push(this.allLicenseCount['active_licenses']);
        arr.push(this.allLicenseCount['expired_licenses']);
        setTimeout(() => {
          this.totalLicenseData = arr;
          this.allLicenseData = arr1;
        }, 0);
      }
    });
  }

  documentsCount: any = {};
  getDocumentsCount() {
    this.httpSv.getDasDocumentsCount(this.roleId)?.subscribe((res: any) => {
      if (res['status']) {
        this.documentsCount = res['results'];
        this.totalDocumentData.push(this.documentsCount.active_documents);
        this.totalDocumentData.push(this.documentsCount.expired_documents);
        this.totalDocumentData.push(
          this.documentsCount.documents_need_to_upload,
        );
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.ApplicationDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.ApplicationDocuments);
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.TestDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.TestDocuments); 
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.TrainingDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.TrainingDocuments);
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.HealthDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.HealthDocuments);
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.CalibrationDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.CalibrationDocuments);
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.OthersDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.OthersDocuments);
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.AuditDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.AuditDocuments);        

      }
      // this.totalDocumentDataPie = this.totalDocumentData.slice(0, 3);
    });
  }

  expiringLicenseCount: any = {};
  getExpiringLicenseCount() {
    this.httpSv.getAMExpiringLicenseCount().subscribe((res: any) => {
      if (res['status']) {
        // this.expiringLicenseCount = res['results']
        // this.expiringLicenseData.push(this.expiringLicenseCount.expiring_in_less_than_30_days)
        // this.expiringLicenseData.push(this.expiringLicenseCount.expiring_in_less_than_60_days)
        // this.expiringLicenseData.push(this.expiringLicenseCount.expiring_in_less_than_90_days)
        this.expiringLicenseCount = res['results'];
        let arr: any = [];
        arr.push(this.expiringLicenseCount.expiring_in_less_than_30_days);
        arr.push(this.expiringLicenseCount.expiring_in_less_than_60_days);
        arr.push(this.expiringLicenseCount.expiring_in_less_than_90_days);
        this.expiringLicenseData = arr;
      }
    });
  }

  getAMExpiringLicenseCount() {
    this.httpSv.getAMExpiringLicenseCountDashboard().subscribe((res: any) => {
      if (res['status']) {
        const data = res['results'];
        this.licenseRequestData[0].data = [];
        this.licenseRequestData[1].data = [];
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            const element = data[key];
            this.licenseRequestData[0].data.push(element.license_requests);
            this.licenseRequestData[1].data.push(element.solved_requests);
          }
        }
        this.licenseRequestData = JSON.parse(
          JSON.stringify(this.licenseRequestData),
        );
      }
    });
  }

  // Service Request

  allMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  selectedServiceMonth: any;
  serviceRequestMonthHander(currentmonth: any) {
    // console.log(this.selectedServiceMonth, "showSelectedMonth")

    const month = this.allMonths.findIndex(
      (ele: any) => ele == this.selectedServiceMonth,
    );

    this.getServiceRequestByMonth(month);
  }

  getServiceRequestByMonth(monthData: any = null) {
    let month: any;

    if (!(monthData == null || monthData == undefined)) month = monthData;
    else month = new Date().getMonth();

    this.httpSv
      .getServiceRequestByMonth(Number(month) + Number(1), this.currentYear)
      .subscribe((res: any) => {
        if (res['status']) {
          this.selectedServiceMonth = this.allMonths[month];
          const data = res['results'];
          this.serviceRequestData[0].data = [];
          this.serviceRequestData[1].data = [];
          for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
              const element = data[key];
              this.serviceRequestData[0].data.push(element.raised_requests);
              this.serviceRequestData[1].data.push(element.solved_requests);
            }
          }
          this.serviceRequestData = JSON.parse(
            JSON.stringify(this.serviceRequestData),
          );
        }
      });
  }

  serviceRequestCount: any = {};
  getServiceRequestCount() {
    this.httpSv.getAMServiceRequestCount().subscribe((res: any) => {
      if (res['status']) {
        res['results'].map((ele: any) => {
          this.serviceRequestCount[Object.keys(ele)[0]] =
            ele[Object.keys(ele)[0]];
        });
        let arr: any[] = [];
        // arr.push(this.serviceRequestCount['total_service_requests'])
        // arr.push(this.serviceRequestCount['pending_service_requests'])

        arr.push(this.serviceRequestCount['Renew license']);
        arr.push(this.serviceRequestCount['Modify license']);
        arr.push(this.serviceRequestCount['File Annual Returns']);
        arr.push(this.serviceRequestCount['Product Testing']);
        arr.push(this.serviceRequestCount['Water Testing']);
        arr.push(this.serviceRequestCount['Pest Control']);
        arr.push(this.serviceRequestCount['Health Check']);
        arr.push(this.serviceRequestCount['Equipment Callibration']);
        arr.push(this.serviceRequestCount['Upload Test Reports']);
        arr.push(this.serviceRequestCount['Training']);
        arr.push(this.serviceRequestCount['Internal Audit']);
        arr.push(this.serviceRequestCount['Third-party Audits']);
        arr.push(this.serviceRequestCount['New license']);

        this.totalServiceRequestData = arr;
      }
    });
  }
}

  // Define a type for filter categories
  type FilterCategory = 'document_type' | 'status' | 'license_number';

  // Create an interface for filter mapping
  interface FilterMapping {
    category: FilterCategory;    // document_type, status, etc.
    value: string[];       // the actual value to filter
  }

@Component({
  selector: 'app-u3-dashboard',
  templateUrl: './u_3.dashboard.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardU3Component implements OnInit {
  @Output() messageEmitter = new EventEmitter<any>();
  @ViewChild('chart') chart!: ChartComponent;
  @Output() chartDataChange = new EventEmitter<any>();
  constructor(
    private dataShare: DataService,
    private httpSv: HttpService,
    private toster: ToastrService,
    private router :Router,
    private filterService: FilterService,
  ) {}
  ngOnInit(): void {
    // // Decrypt the value using AES decryption with the secret key
    // const role = localStorage.getItem('role')
    // if (role) {
    //   const bytes = CryptoJS.AES.decrypt(role, this.dataShare.secretKey);
    //   // Convert the decrypted bytes back to a string
    //   const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    //   console.log(decrypted)
    // }
    const roleIdEncoded = localStorage.getItem('role');
    if (roleIdEncoded) {
      const role = AES.decrypt(
        roleIdEncoded,
        this.dataShare.secretKey,
      ).toString(enc.Utf8);
      this.roleId = role;
      console.log(this.roleId, role);
    }
    this.getLicenseCount();
    this.getDocumentsCount();
    this.getExpiringLicenseCount();
    this.getAllServiceCount();
    this.getServiceWeekly();
    window.dispatchEvent(new Event('resize'));
    for (var i = 4; i > 0; i--) {
      this.yearDropDown.push(this.currentYear - i);
    }
    this.yearDropDown.push(this.currentYear);
  }




navigateWithFilters(filters: FilterMapping[]) {
  // Initialize empty filter object
  const filterValues: Record<FilterCategory, string[]> = {
    document_type: [] as Array<string>,
    status: [] as Array<string>,
    license_number: [] as Array<string>,
  };

  // Apply each filter to its proper category
  filters.forEach(filter => {
    if (filterValues[filter.category]) {
      filterValues[filter.category].push(...filter.value.map(v => v.toLowerCase())); // Spread operator for multiple values
    }
  });

  // Store the filter values
  this.filterService.setFilters(filterValues);
  
  // Navigate to documents page
  this.router.navigate(['/report']);
}

  roleId: any;
  yearDropDown: any = [];
  currentYear: any = new Date().getFullYear();
  //PIE CHART BELOW
  //Document Data
  totalDocumentData: ApexNonAxisChartSeries = []; // Your original data
  totalDocumentDataPie: ApexNonAxisChartSeries = []; 
  lableDocument: any = [
    'Active documents',
    'Expired documents',
    'Documents to Upload',
  ];
  colorsDocument: any = ['#12C058', '#C6372E', '#DFA823'];
  //License Data
  totalLicenseData: ApexNonAxisChartSeries = [];
  lableLicense: any = [
    'Active Licenses',
    'Expired Licenses',
    'Requseted Licenses',
  ];
  colorsLicense: any = ['#7CC3B5', '#E0473E', '#247DD0'];
  //Expiring License Data
  totalExpiringLicenseData: ApexNonAxisChartSeries = [];
  lableExpiringLicense: any = [
    'Expiring in less than 30 days',
    'Expiring in 60 days',
    'Expiring in 90 days',
  ];
  colorsExpiringLicense: any = ['#E0473E', '#F59E5F', '#F5DD5F'];
  //Service Data
  totalServiceData: ApexNonAxisChartSeries = [];
  lableService: any = [
    'Renew License',
    'Modify license',
    'File Annual Returns',
    'Product Testing',
    'Water Testing',
    'Pest Control',
    'Health_check',
    'Equipment Callibration',
    'Upload Test Reports',
    'Training',
    'Internal Audit',
    'Third-party Audits',
    'New license',
  ];
  colorsService: any = [
    '#7CC3B5',
    '#9FC4FB',
    '#F66D44',
    '#FEAE65',
    '#E6F69D',
    '#AADEA7',
    '#64C2A6',
    '#2D87BB',
    '#6AAD41',
  ];

  pieChats: ApexChart = {
    type: 'pie',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    height: 250,
  };
  pieLegend: ApexLegend = {
    show: false,
  };

  // BAR CHART BELOW

  // Licence request data
  data: ApexAxisChartSeries = [
    {
      name: 'License Requested',
      data: [0, 0, 0, 0, 0],
      color: '#247DD0',
    },
    {
      name: 'Solved Requested',
      data: [0, 0, 0, 0, 0],
      color: '#6AAD41',
    },
  ];
  // service request
  serviceRequestData: ApexAxisChartSeries = [
    {
      name: 'Service Requested',
      data: [0, 0, 0, 0],
      color: '#B75FDC',
    },
    {
      name: 'Solved Requested',
      data: [0, 0, 0, 0],
      color: '#6AAD41',
    },
  ];
  //common bar chart config
  chats: ApexChart = {
    type: 'bar',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    height: 250,
  };
  dataLabels: ApexDataLabels = {
    enabled: false,
  };
  legend: ApexLegend = {
    position: 'right',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '13px',
    markers: {
      width: 25,
      height: 25,
    },
    horizontalAlign: 'center',
    onItemClick: {
      toggleDataSeries: false,
    },
  };

  xaxisCount: number = 0;
  xAxisLabel = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'];
  xaxis: ApexXAxis = {
    categories: this.xAxisLabel,
  };
  yaxis: ApexYAxis = {
    forceNiceScale: false,
    labels: {
      formatter: function (value: any) {
        return parseInt(value).toString(); // Convert to non-decimal integer
      },
    },
  };
  plotOptions: ApexPlotOptions = {
    bar: {
      columnWidth: '80%',
    },
  };
  licenseCount: any = {};
  getLicenseCount() {
    this.httpSv.getDasLicenseCount(5).subscribe((res: any) => {
      if (res['status']) {
        this.licenseCount = res['results'];
        this.totalLicenseData.push(this.licenseCount.active_licenses);
        this.totalLicenseData.push(this.licenseCount.expired_licenses);
        this.totalLicenseData.push(this.licenseCount.requested_licenses);
      }
    });
  }
  documentsCount: any = {};
  getDocumentsCount() {
    this.httpSv.getDasDocumentsCount(this.roleId)?.subscribe((res: any) => {
      if (res['status']) {
        this.documentsCount = res['results'];
        this.totalDocumentData.push(this.documentsCount.active_documents);
        this.totalDocumentData.push(this.documentsCount.expired_documents);
        this.totalDocumentData.push(
          this.documentsCount.documents_need_to_upload,
        );
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.ApplicationDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.ApplicationDocuments);
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.TestDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.TestDocuments); 
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.TrainingDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.TrainingDocuments);
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.HealthDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.HealthDocuments);
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.CalibrationDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.CalibrationDocuments);
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.OthersDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.OthersDocuments);
        this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.AuditDocuments);
        this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.AuditDocuments);        

      }
      this.totalDocumentDataPie = this.totalDocumentData.slice(0, 3);
    });
  }

  expiringLicenseCount: any = {};
  getExpiringLicenseCount() {
    this.httpSv.getDasExpiringLicenseCount().subscribe((res: any) => {
      if (res['status']) {
        this.expiringLicenseCount = res['results'];
        this.totalExpiringLicenseData.push(
          this.expiringLicenseCount.expiring_in_less_than_30_days,
        );
        this.totalExpiringLicenseData.push(
          this.expiringLicenseCount.expiring_in_less_than_60_days,
        );
        this.totalExpiringLicenseData.push(
          this.expiringLicenseCount.expiring_in_less_than_90_days,
        );
      }
    });
  }

  serviceCount: any = {};
  getAllServiceCount() {
    this.httpSv.getDasServiceRequestCount(5).subscribe((res: any) => {
      if (res['status']) {
        res['results'].map((ele: any) => {
          this.serviceCount[Object.keys(ele)[0]] = ele[Object.keys(ele)[0]];
        });

        let arr = [];
        arr.push(this.serviceCount['Renew license']);
        arr.push(this.serviceCount['Modify license']);
        arr.push(this.serviceCount['File Annual Returns']);
        arr.push(this.serviceCount['Product Testing']);
        arr.push(this.serviceCount['Water Testing']);
        arr.push(this.serviceCount['Pest Control']);
        arr.push(this.serviceCount['Health Check']);
        arr.push(this.serviceCount['Equipment Callibration']);
        arr.push(this.serviceCount['Upload Test Reports']);
        arr.push(this.serviceCount['Training']);
        arr.push(this.serviceCount['Internal Audit']);
        arr.push(this.serviceCount['Third-party Audits']);
        arr.push(this.serviceCount['New license']);

        this.totalServiceData = arr;
      }
    });
  }
  allMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  selectedServiceMonth: any = 'Jan';
  serviceChatMonthHander(data: any) {
    const month = this.allMonths.findIndex(
      (ele: any) => ele == this.selectedServiceMonth,
    );
    this.getServiceWeekly(month);
  }

  getServiceWeekly(monthData: any = null) {
    let month: any;
    if (monthData != null) month = monthData;
    else month = new Date().getMonth();
    this.httpSv
      .getDasServiceRequestWeekly(month + 1, this.currentYear, 5)
      .subscribe((res: any) => {
        if (res['status']) {
          this.selectedServiceMonth = this.allMonths[month];
          const data = res['results'];
          this.serviceRequestData[0].data = [];
          this.serviceRequestData[1].data = [];
          for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
              const element = data[key];
              this.serviceRequestData[0].data.push(element.raised_requests);
              this.serviceRequestData[1].data.push(element.solved_requests);
            }
          }
          this.serviceRequestData = JSON.parse(
            JSON.stringify(this.serviceRequestData),
          );
          // this.selectedServiceMonth = this.allMonths[month]
          // console.log(this.selectedServiceMonth)
        }
      });
  }
  moveTab(tab: any, to: any = 'Enterprise') {
    if(tab == 'reports')
  {
    this.router.navigate(['/report']);
  }   
  else{ this.messageEmitter.emit(tab);
      this.dataShare.prefilter = to;
  }
  }
}

@Component({
  selector: 'app-u7-dashboard',
templateUrl: './u_7.dashboard.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardU7Component implements OnInit {
  @Output() messageEmitter = new EventEmitter<any>();
  @ViewChild('chart') chart!: ChartComponent;
  @Output() chartDataChange = new EventEmitter<any>();
  constructor(
    private dataShare: DataService,
    private httpSv: HttpService,
    private toster: ToastrService,
    private router :Router,
    private filterService: FilterService,
  ) {}
  ngOnInit(): void {
    // // Decrypt the value using AES decryption with the secret key
    // const role = localStorage.getItem('role')
    // if (role) {
    //   const bytes = CryptoJS.AES.decrypt(role, this.dataShare.secretKey);
    //   // Convert the decrypted bytes back to a string
    //   const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    //   console.log(decrypted)
    // }
    const roleIdEncoded = localStorage.getItem('role');
    if (roleIdEncoded) {
      const role = AES.decrypt(
        roleIdEncoded,
        this.dataShare.secretKey,
      ).toString(enc.Utf8);
      this.roleId = role;
      console.log(this.roleId, role);
    }
    // this.getLicenseCount();
    // this.getDocumentsCount();
    // this.getExpiringLicenseCount();
    this.getAllServiceCount();
    this.getServiceWeekly();
    window.dispatchEvent(new Event('resize'));
    for (var i = 4; i > 0; i--) {
      this.yearDropDown.push(this.currentYear - i);
    }
    this.yearDropDown.push(this.currentYear);
  }




navigateWithFilters(filters: FilterMapping[]) {
  // Initialize empty filter object
  const filterValues: Record<FilterCategory, string[]> = {
    document_type: [] as Array<string>,
    status: [] as Array<string>,
    license_number: [] as Array<string>,
  };

  // Apply each filter to its proper category
  filters.forEach(filter => {
    if (filterValues[filter.category]) {
      filterValues[filter.category].push(...filter.value.map(v => v.toLowerCase())); // Spread operator for multiple values
    }
  });

  // Store the filter values
  this.filterService.setFilters(filterValues);
  
  // Navigate to documents page
  this.router.navigate(['/report']);
}

  roleId: any;
  yearDropDown: any = [];
  currentYear: any = new Date().getFullYear();
  //PIE CHART BELOW
  //Document Data
  // totalDocumentData: ApexNonAxisChartSeries = []; // Your original data
  // totalDocumentDataPie: ApexNonAxisChartSeries = []; 
  // lableDocument: any = [
  //   'Active documents',
  //   'Expired documents',
  //   'Documents to Upload',
  // ];
  colorsDocument: any = ['#12C058', '#C6372E', '#DFA823'];
  //License Data
  // totalLicenseData: ApexNonAxisChartSeries = [];
  // lableLicense: any = [
  //   'Active Licenses',
  //   'Expired Licenses',
  //   'Requseted Licenses',
  // ];
  // colorsLicense: any = ['#7CC3B5', '#E0473E', '#247DD0'];
  //Expiring License Data
  // totalExpiringLicenseData: ApexNonAxisChartSeries = [];
  // lableExpiringLicense: any = [
  //   'Expiring in less than 30 days',
  //   'Expiring in 60 days',
  //   'Expiring in 90 days',
  // ];
  // colorsExpiringLicense: any = ['#E0473E', '#F59E5F', '#F5DD5F'];
  //Service Data
  totalServiceData: ApexNonAxisChartSeries = [];
  lableService: any = [
    'Renew License',
    'Modify license',
    'File Annual Returns',
    'Product Testing',
    'Water Testing',
    'Pest Control',
    'Health_check',
    'Equipment Callibration',
    'Upload Test Reports',
    'Training',
    'Internal Audit',
    'Third-party Audits',
    'New license',
  ];
  colorsService: any = [
    '#7CC3B5',
    '#9FC4FB',
    '#F66D44',
    '#FEAE65',
    '#E6F69D',
    '#AADEA7',
    '#64C2A6',
    '#2D87BB',
    '#6AAD41',
  ];

  pieChats: ApexChart = {
    type: 'pie',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    height: 250,
  };
  pieLegend: ApexLegend = {
    show: false,
  };

  // BAR CHART BELOW

  // Licence request data
  data: ApexAxisChartSeries = [
    {
      name: 'License Requested',
      data: [0, 0, 0, 0, 0],
      color: '#247DD0',
    },
    {
      name: 'Solved Requested',
      data: [0, 0, 0, 0, 0],
      color: '#6AAD41',
    },
  ];
  // service request
  serviceRequestData: ApexAxisChartSeries = [
    {
      name: 'Service Requested',
      data: [0, 0, 0, 0],
      color: '#B75FDC',
    },
    {
      name: 'Solved Requested',
      data: [0, 0, 0, 0],
      color: '#6AAD41',
    },
  ];
  //common bar chart config
  chats: ApexChart = {
    type: 'bar',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    height: 250,
  };
  dataLabels: ApexDataLabels = {
    enabled: false,
  };
  legend: ApexLegend = {
    position: 'right',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '13px',
    markers: {
      width: 25,
      height: 25,
    },
    horizontalAlign: 'center',
    onItemClick: {
      toggleDataSeries: false,
    },
  };

  xaxisCount: number = 0;
  xAxisLabel = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'];
  xaxis: ApexXAxis = {
    categories: this.xAxisLabel,
  };
  yaxis: ApexYAxis = {
    forceNiceScale: false,
    labels: {
      formatter: function (value: any) {
        return parseInt(value).toString(); // Convert to non-decimal integer
      },
    },
  };
  plotOptions: ApexPlotOptions = {
    bar: {
      columnWidth: '80%',
    },
  };
  // licenseCount: any = {};
  // getLicenseCount() {
  //   this.httpSv.getDasLicenseCount(7).subscribe((res: any) => {
  //     if (res['status']) {
  //       this.licenseCount = res['results'];
  //       this.totalLicenseData.push(this.licenseCount.active_licenses);
  //       this.totalLicenseData.push(this.licenseCount.expired_licenses);
  //       this.totalLicenseData.push(this.licenseCount.requested_licenses);
  //     }
  //   });
  // }
  // documentsCount: any = {};
  // getDocumentsCount() {
  //   this.httpSv.getDasDocumentsCount(this.roleId)?.subscribe((res: any) => {
  //     if (res['status']) {
  //       this.documentsCount = res['results'];
  //       this.totalDocumentData.push(this.documentsCount.active_documents);
  //       this.totalDocumentData.push(this.documentsCount.expired_documents);
  //       this.totalDocumentData.push(
  //         this.documentsCount.documents_need_to_upload,
  //       );
  //       this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.ApplicationDocuments);
  //       this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.ApplicationDocuments);
  //       this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.TestDocuments);
  //       this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.TestDocuments); 
  //       this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.TrainingDocuments);
  //       this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.TrainingDocuments);
  //       this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.HealthDocuments);
  //       this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.HealthDocuments);
  //       this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.CalibrationDocuments);
  //       this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.CalibrationDocuments);
  //       this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.OthersDocuments);
  //       this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.OthersDocuments);
  //       this.totalDocumentData.push(this.documentsCount.expired_documents_by_type.AuditDocuments);
  //       this.totalDocumentData.push(this.documentsCount.documents_need_to_upload_by_type.AuditDocuments);        

  //     }
  //     this.totalDocumentDataPie = this.totalDocumentData.slice(0, 3);
  //   });
  // }

  // expiringLicenseCount: any = {};
  // getExpiringLicenseCount() {
  //   this.httpSv.getDasExpiringLicenseCount().subscribe((res: any) => {
  //     if (res['status']) {
  //       this.expiringLicenseCount = res['results'];
  //       this.totalExpiringLicenseData.push(
  //         this.expiringLicenseCount.expiring_in_less_than_30_days,
  //       );
  //       this.totalExpiringLicenseData.push(
  //         this.expiringLicenseCount.expiring_in_less_than_60_days,
  //       );
  //       this.totalExpiringLicenseData.push(
  //         this.expiringLicenseCount.expiring_in_less_than_90_days,
  //       );
  //     }
  //   });
  // }

  serviceCount: any = {};
  getAllServiceCount() {
    this.httpSv.getDasServiceRequestCount(7).subscribe((res: any) => {
      if (res['status']) {
        res['results'].map((ele: any) => {
          this.serviceCount[Object.keys(ele)[0]] = ele[Object.keys(ele)[0]];
        });

        let arr = [];
        arr.push(this.serviceCount['Renew license']);
        arr.push(this.serviceCount['Modify license']);
        arr.push(this.serviceCount['File Annual Returns']);
        arr.push(this.serviceCount['Product Testing']);
        arr.push(this.serviceCount['Water Testing']);
        arr.push(this.serviceCount['Pest Control']);
        arr.push(this.serviceCount['Health Check']);
        arr.push(this.serviceCount['Equipment Callibration']);
        arr.push(this.serviceCount['Upload Test Reports']);
        arr.push(this.serviceCount['Training']);
        arr.push(this.serviceCount['Internal Audit']);
        arr.push(this.serviceCount['Third-party Audits']);
        arr.push(this.serviceCount['New license']);

        this.totalServiceData = arr;
      }
    });
  }
  allMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  selectedServiceMonth: any = 'Jan';
  serviceChatMonthHander(data: any) {
    const month = this.allMonths.findIndex(
      (ele: any) => ele == this.selectedServiceMonth,
    );
    this.getServiceWeekly(month);
  }

  getServiceWeekly(monthData: any = null) {
    let month: any;
    if (monthData != null) month = monthData;
    else month = new Date().getMonth();
    this.httpSv
      .getDasServiceRequestWeekly(month + 1, this.currentYear, 7)
      .subscribe((res: any) => {
        if (res['status']) {
          this.selectedServiceMonth = this.allMonths[month];
          const data = res['results'];
          this.serviceRequestData[0].data = [];
          this.serviceRequestData[1].data = [];
          for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
              const element = data[key];
              this.serviceRequestData[0].data.push(element.raised_requests);
              this.serviceRequestData[1].data.push(element.solved_requests);
            }
          }
          this.serviceRequestData = JSON.parse(
            JSON.stringify(this.serviceRequestData),
          );
          // this.selectedServiceMonth = this.allMonths[month]
          // console.log(this.selectedServiceMonth)
        }
      });
  }
  moveTab(tab: any, to: any = 'Enterprise') {
    if(tab == 'reports')
  {
    this.router.navigate(['/report']);
  }   
  else{ this.messageEmitter.emit(tab);
      this.dataShare.prefilter = to;
  }
  }
}








