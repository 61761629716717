import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BlogPost, BlogService } from '../../services/blog.service'; // Correct import

@Component({
  selector: 'app-blog-scroll',
  templateUrl: './blog-scroll.component.html',
  styleUrls: ['./blog-scroll.component.css'],
})
export class BlogScrollComponent implements OnInit, OnDestroy {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  posts: BlogPost[] = [];
  // loadingMore$!: Observable<boolean>;
  isLoading = false;
  private destroy$ = new Subject<void>();

  isDragging = false;
  startX = 0;
  initialScrollLeft = 0;

  constructor(private blogService: BlogService) {}

  ngOnInit(): void {
    // this.blogService.posts$.pipe(takeUntil(this.destroy$)).subscribe(posts => {
    //   this.posts = posts;
    // });

    // this.loadingMore$ = this.blogService.loading$;

    // this.blogService.loadInitialPosts().subscribe();
    // Load all content
    this.blogService.loadAllContent().subscribe({
      next: ({ blogs, insights }) => {
        this.posts = [...blogs, ...insights];
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });

    fromEvent(document, 'mousemove')
      .pipe(takeUntil(this.destroy$))
      .subscribe((e: Event) => this.handleMouseMove(e as MouseEvent)); // Cast explicitly

    fromEvent(document, 'mouseup')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.stopDragging());
  }

  startDragging(e: MouseEvent): void {
    this.isDragging = true;
    const slider = this.scrollContainer.nativeElement;
    this.startX = e.pageX - slider.offsetLeft;
    this.initialScrollLeft = slider.scrollLeft;
    slider.classList.add('dragging');
  }

  handleMouseMove(e: MouseEvent): void {
    if (!this.isDragging) return;
    e.preventDefault();
    const slider = this.scrollContainer.nativeElement;
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - this.startX) * 2;
    slider.scrollLeft = this.initialScrollLeft - walk;
  }

  stopDragging(): void {
    this.isDragging = false;
    const slider = this.scrollContainer.nativeElement;
    slider.classList.remove('dragging');
  }

  handleClick(event: MouseEvent): void {
    if (this.isDragging) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  scrollLeft(): void {
    this.scrollContainer.nativeElement.scrollBy({ left: -305, behavior: 'smooth' });
  }

  scrollRight(): void {
    this.scrollContainer.nativeElement.scrollBy({ left: 305, behavior: 'smooth' });
  }

  seeAll(): void {
    window.open('https://foodsafetyworks.com/knowledge-base/', '_blank');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}