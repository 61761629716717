import { Injectable } from "@angular/core";

// filter.service.ts
@Injectable({
    providedIn: 'root'
  })
  export class FilterService {
    private filterState = {
      document_type: [],
      status: [],
      license_number: []
    };
  
    setFilters(filters: any) {
      this.filterState = { ...this.filterState, ...filters };
    }
  
    getFilters() {
      return this.filterState;
    }
  
    clearFilters() {
      this.filterState = {
        document_type: [],
        status: [],
        license_number: []
      };
    }
  }