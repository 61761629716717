<div class="ces_main_div">
    <div class="ces_header">
      <span *ngIf="!isModify">Add Pincode </span>
      <span *ngIf="isModify">Modify Pincode</span>
      <div class="header_quik_functions">
        <img src="../../../assets/img/close.svg" alt="" (click)="close()" />
      </div>
    </div>
    <div class="ces_content_main_div">
      <div class="ces_inp_main_div">
        <div class="ces_pd_main_div">
            <form [formGroup]="userForm">
                <div class="ces_pd_inp_main_div">
                  <div class="ces_pd_inp_sub_div">
                    <label for="pincode">Pincode</label>
                    <input type="text" id="pincode" formControlName="pincode" />
                    <!-- <span *ngIf="userForm.get('pincode')?.invalid && userForm.get('pincode')?.touched">
                      Pincode is required and must be 6 digits.
                    </span> -->
                  </div>
              
                  <div class="ces_pd_inp_sub_div">
                    <label for="district">City</label>
                    <input type="text" id="district" formControlName="district" />
                    <!-- <span *ngIf="userForm.get('district')?.invalid && userForm.get('district')?.touched">
                      District is required.
                    </span> -->
                  </div>
              
                  <div class="ces_pd_inp_sub_div">
                    <label for="state">State</label>
                    <select id="state" formControlName="state" class="ces_pd_inp_sub">
                      <option value="" disabled selected>Select a state</option>
                      <option *ngFor="let state of states" [value]="state">{{ state }}</option>
                    </select>
                    <!-- <input type="text" id="state" formControlName="state" /> -->
                    <!-- <span *ngIf="userForm.get('state')?.invalid && userForm.get('state')?.touched">
                      State is required.
                    </span> -->
                  </div>
                              </div>
              </form>
              
        </div>
      </div>
    </div>
    <div style="flex: 1">&nbsp;</div>
    <div class="ces_btn_main_div" style="margin-bottom: 2rem">
      <div class="ces_pd_btn_div">
        <div (click)="close()">CANCEL</div>
        <div (click)="addPincode()" *ngIf="!isModify">ADD</div>
        <div (click)="modifyPincode()" *ngIf="isModify">Update</div>
      </div>
    </div>
  </div>
  
  <style>
    .add_user_header {
      display: flex;
      gap: 26px;
      color: #484a46;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      /* 171.429% */
      letter-spacing: 0.28px;
    }
  
    .add_user_header input[type="radio"] {
      cursor: pointer;
      accent-color: #0a9b45;
    }
  
    .add_user_header span {
      margin-left: 10px;
    }
  </style>
  