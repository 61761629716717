import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  EventEmitter,
  Output,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DialogResetPassword } from '../common_dialog/reset-password/reset_password';
import { DialogDeleteCofirm } from '../common_dialog/delete-confirm/delete_confirm_dialog';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { HttpService } from 'src/app/services/api/http.service';
import { ToastrService } from 'ngx-toastr';
import { DialogDeleteDonePopup } from '../common_dialog/delete-sucess-info/delete_done_dialog';
import {
  DialogAssignment,
  DialogCESAddModify,
} from '../web-user/web-user.component';
import { DataService } from 'src/app/services/data-share/data.service';
import { MatSort } from '@angular/material/sort';
import { NotificationsComponent } from '../common_dialog/notifications/notifications.component';
import { Router } from '@angular/router';
import { AES, enc } from 'crypto-js';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.css'],
})
export class SupplierComponent implements OnInit {
  @ViewChild(MatSort) sort = new MatSort();

  constructor(
    private http: HttpService,
    private toast: ToastrService,
    public dialog: MatDialog,
    private router: Router,
    private dataShare: DataService,
  ) {}
  displayedColumns: string[] = [
    'sl_no',
    'name',
    'email',
    'address',
    'state',
    'status',
    'reset_password',
    'modify',
  ];
  data: any;
  apiData: any;
  notifications: any = [];
  isNewNotifications: boolean = false;
  dataSource: any = new MatTableDataSource();
  selectedFilter: any = {
    all: true,
    active: false,
    expired: false,
    requested: false,
  };
  role: any;
  ngOnInit(): void {
    this.getSaSupplier();
    const roleIdEncoded = localStorage.getItem('role');
    if (roleIdEncoded) {
      const roleId = AES.decrypt(
        roleIdEncoded,
        this.dataShare.secretKey,
      ).toString(enc.Utf8);
      this.role = roleId;
      console.log(this.role, roleId);
    }
    this.getNotifications();
    setTimeout(async () => {
      this.photo = await localStorage.getItem('photo');
      this.username = await localStorage.getItem('username');

      console.log(this.photo);
    }, 0);
  }
  username: any = 'User';
  photo: any = '../../../assets/img/default_dp.png';
  tPhoto: any = '../../../assets/img/default_dp.png';
  navigate(route: string) {
    if (this.router.url == route) return;
    else this.router.navigate([route]);
  }
  autocompleteControl: string = '';
  options: any[] = [];
  filterData: any;
  selectedOptions: any[] = [];
  filteredOptions: any[] = this.options;
  readonlyToggleFlag: boolean = false;

  getNotifications() {
    this.http.getNotifications(37).subscribe(
      (res: any) => {
        if (res['status']) {
          this.notifications = res['result'];
          const newNotf = this.notifications.filter(
            (data: any) => data.is_read === false,
          );
          console.log(this.notifications, newNotf);
          if (newNotf.length > 0) this.isNewNotifications = true;
          else this.isNewNotifications = false;
        } else {
          this.notifications = [];
          this.isNewNotifications = false;
        }
      },
      (err: any) => {
        this.toast.error('Oops!, Somrthing went wrong Please try again', '', {
          timeOut: 2000,
        });
      },
    );
  }
  @Output() messageEmitter = new EventEmitter<any>();
  moveTab(tab: any, to: any = 'Enterprise') {
    this.messageEmitter.emit(tab);
    this.dataShare.prefilter = to;
  }
  readNotifications(element: any) {
    this.moveTab('service', element.type == 'eu' ? 'Enterprise' : 'Retail');
    this.dataShare.dialogRef(element.service_request_id, element.type);
    let data = {
      id: element.id,
      is_read: 1,
    };
    this.http.readNotification(data).subscribe(
      (res: any) => {
        if (res['status']) {
          this.getNotifications();
        } else {
          this.toast.error(res['message'], '', { timeOut: 2000 });
        }
      },
      (err: any) => {
        this.toast.error('Oops!, Somrthing went wrong Please try again', '', {
          timeOut: 2000,
        });
      },
    );
  }
  showNotif: boolean = false;
  toggleNotif() {
    this.dialog.open(NotificationsComponent, {
      data: this.notifications,
      position: {
        top: `5rem`,
        right: `25rem`,
      },
    });
  }
  handleAssignment(type: any, supplierId: any, id: any) {
    const dialogRef = this.dialog.open(DialogAssignment, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        type,
        supplierId,
        id,
        from: 'sa',
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) this.ngOnInit();
    });
  }
  getSaSupplier() {
    this.http.getAllSupplier().subscribe(
      (res: any) => {
        if (res['status']) {
          res['results'].map((data: any) => {});
          this.dataSource.data = res['results'];
          this.dataSource.sort = this.sort;
          this.apiData = res['results'];
          this.filterData = res['results'];
          this.createEUDropDown(this.apiData);
        } else {
          this.toast.error('Oops! Someting went wrong', '', { timeOut: 2000 });
        }
      },
      (err: any) => {
        if (err.error.message == 'no data found') this.dataSource.data = [];

        this.toast.error(err.error.message, '', { timeOut: 2000 });
      },
    );
  }
  EUEmailDropdown: any[] = [];
  EUNameDropdown: any[] = [];
  EUCityDropdown: any[] = [];
  EUStatusDropdown: any[] = [];
  EUAMDropdown: any[] = [];
  EUNameFilter: any = '';
  EUAMFilter: any = '';
  EUCityFilter: any = '';
  EUEmailFilter: any = '';
  EUStatusFilter: any = '';
  createEUDropDown(tableData: any) {
    tableData.forEach((data: any) => {
      if (data.name && !this.EUNameDropdown.includes(data.name.toLowerCase()))
        this.EUNameDropdown.push(data.name.toLowerCase());
      if (data.city && !this.EUCityDropdown.includes(data.city))
        this.EUCityDropdown.push(data.city);
      if (data.status && !this.EUStatusDropdown.includes(data.status))
        this.EUStatusDropdown.push(data.status);
      if (data.email && !this.EUEmailDropdown.includes(data.email))
        this.EUEmailDropdown.push(data.email);
      if (
        data.assign_account_manager &&
        !this.EUAMDropdown.includes(data.assign_account_manager.name)
      )
        this.EUAMDropdown.push(data.assign_account_manager.name);
    });
  }
  EuApplyFilter(data: any, from: any, remove: any = null) {
    if (from == 'name') {
      this.EUNameFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUCityFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.city &&
              data.city.toLowerCase() == this.EUCityFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAMFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.assign_account_manager &&
              data.assign_account_manager.name.toLowerCase() ==
                this.EUAMFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUEmailFilter.length > 0) {
          filterData.map((data: any) => {
            if (data.email && data.email.toLowerCase() == this.EUEmailDropdown)
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
        }
        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (apiData.name && apiData.name.toLowerCase() == data.toLowerCase())
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'city') {
      this.EUCityFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.name &&
              data.name.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUEmailFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.email &&
              data.email.toLowerCase() == this.EUEmailFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAMFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.assign_account_manager &&
              data.assign_account_manager.name.toLowerCase() ==
                this.EUAMFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.status()
            )
              result.push(data);
          });
          filterData = result;
        }
        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (apiData.city && apiData.city.toLowerCase() == data.toLowerCase())
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'email') {
      this.EUEmailFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.name &&
              data.name.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCityFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.city &&
              data.city.toLowerCase() == this.EUCityFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAMFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.assign_account_manager &&
              data.assign_account_manager.name.toLowerCase() ==
                this.EUAMFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.status()
            )
              result.push(data);
          });
          filterData = result;
        }
        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.email &&
            apiData.email.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'status') {
      this.EUStatusFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.name &&
              data.name.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUAMFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.assign_account_manager &&
              data.assign_account_manager.name.toLowerCase() ==
                this.EUAMFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCityFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.city &&
              data.city.toLowerCase() == this.EUCityFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUEmailFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.email &&
              data.email.slice(0, 10).toLowerCase() ==
                this.EUEmailFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.status &&
            apiData.status.toLowerCase() == data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
    if (from == 'am') {
      this.EUAMFilter = data;
      let result: any = [];
      if (remove == 1) {
        let filterData = this.apiData;
        if (this.EUNameFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.name &&
              data.name.toLowerCase() == this.EUNameFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUStatusFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.status &&
              data.status.toLowerCase() == this.EUStatusFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUCityFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.city &&
              data.city.toLowerCase() == this.EUCityFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }
        if (this.EUEmailFilter.length > 0) {
          filterData.map((data: any) => {
            if (
              data.email &&
              data.email.slice(0, 10).toLowerCase() ==
                this.EUEmailFilter.toLowerCase()
            )
              result.push(data);
          });
          filterData = result;
          result = [];
        }

        if (result.length == 0) result = filterData;
      } else {
        this.filterData.map((apiData: any) => {
          if (
            apiData.assign_account_manager &&
            apiData.assign_account_manager.name.toLowerCase() ==
              data.toLowerCase()
          )
            result.push(apiData);
        });
      }
      this.filterData = result;
      this.dataSource.data = this.filterData;
    }
  }
  clearEUFilter() {
    this.EUStatusFilter = '';
    this.EUNameFilter = '';
    this.EUCityFilter = '';
    this.EUEmailFilter = '';
    this.EUAMFilter = '';
    this.dataSource.data = this.apiData;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  resetPassword(element: any) {
    const dialogRef = this.dialog.open(DialogResetPassword, {
      disableClose: true,
      hasBackdrop: true,
      data: {
        element,
      },
    });
  }
  handleModifyBM(id: any) {
    let dialogue = this.dialog
      .open(DialogAddSupplier, {
        hasBackdrop: true,
        disableClose: true,
        data: {
          id,
        },
      })
      .afterClosed()
      .subscribe((res: any) => {
        if (res) {
          this.getSaSupplier();
        }
      });
  }
  handelDeleteSupplier(id: any) {
    let dialogue = this.dialog
      .open(DialogDeleteCofirm, {
        disableClose: true,
        hasBackdrop: true,
        data: {
          heading: 'Are you sure you want to remove this Supplier User?',
          element: id,
        },
      })
      .afterClosed()
      .subscribe((res: any) => {
        if (res) {
          this.http.deleteUsers(id).subscribe(
            (res: any) => {
              if (res['status']) {
                const dialogRef = this.dialog.open(DialogDeleteDonePopup, {
                  hasBackdrop: true,
                  disableClose: true,
                  data: {
                    heading: 'Supplier User Deleted',
                  },
                });
                dialogRef.afterClosed().subscribe((result) => {
                  this.ngOnInit();
                });
              } else {
                this.toast.error('Oops! Someting went wrong', '', {
                  timeOut: 2000,
                });
              }
            },
            (err: any) => {
              this.toast.error(err.error.message, '', { timeOut: 2000 });
            },
          );
        }
      });
  }
  addNewUserSupplier() {
    let dialogue = this.dialog
      .open(DialogAddSupplier, {
        hasBackdrop: true,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((res: any) => {
        if (res) this.getSaSupplier();
      });
  }
  selectedFilterDropdown: any = 'All';
  filterByStatus(order: any) {
    this.selectedFilterDropdown = order;
    if (order == 'All') this.dataSource.data = this.apiData;
    else if (order == 'Unassigned') {
      const tempData = this.apiData.filter(
        (ele: any) => ele.assign_account_manager == null,
      );
      this.dataSource.data = tempData;
    } else {
      const tempData = this.apiData.filter(
        (ele: any) => ele.status.toLowerCase() == order.toLowerCase(),
      );
      this.dataSource.data = tempData;
    }
  }
}

@Component({
  selector: 'app-supplier',
  templateUrl: './add-new-supplier_component.html',
  styleUrls: ['./supplier.component.css'],
})
export class DialogAddSupplier implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogAddSupplier>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpSv: HttpService,
    private toster: ToastrService,
    public dialog: MatDialog,
    private dataShare: DataService,
  ) {}

  displayedColumns: string[] = [
    'sl_no',
    'name',
    'mobile_no',
    'city',
    'assign_am',
    'status',
    'reset_password',
    'modify',
  ];
  emailRegex = new RegExp(
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    'gm',
  );
  dataSource: any = new MatTableDataSource();
  isModify = false;
  selectedFilter: any = {
    all: true,
    active: false,
    expired: false,
    requested: false,
  };
  ngOnInit(): void {
    this.allState = this.dataShare.allStatesOfIndia;
    if (this.data.id) {
      this.httpSv.getSupplierDetails(this.data.id).subscribe(
        (res: any) => {
          if (res['status']) {
            this.isModify = true;
            let data = res['results'][0];
            this.supplierName = data.name;
            this.supplierCity = data.city;
            this.supplierAddress = data.address;
            this.assign_account_manager = data.assign_account_manager;
            this.supplierSelectedState = data.state;
            this.supplierEmail = data.email;
            this.supplierNumber = data.mobile;
            this.suppliertxtStatus = data.status;
          } else {
            this.toster.error('Oops! Someting went wrong', '', {
              timeOut: 2000,
            });
          }
        },
        (err: any) => {
          this.toster.error(err.error.message, '', { timeOut: 2000 });
        },
      );
    }
  }
  supplierName: string = '';
  passwordToggleHide: boolean = true;
  conpasswordToggleHide: boolean = true;
  supplierEmail: string = '';
  supplierNumber: string = '';
  supplierCity: string = '';
  supplierAddress: string = '';
  supplierSelectedState: any;
  supplierPassword: string = '';
  supplierConfirmPassword: string = '';
  allState: any;
  suppliertxtStatus: String = '';
  assign_account_manager: any;

  addNewUser() {
    if (this.supplierAddress && this.supplierName != '') {
      if (this.supplierName && this.supplierName != '') {
        if (this.supplierEmail && this.supplierEmail != '') {
          if (this.supplierEmail.match(this.emailRegex)) {
            if (this.supplierNumber && this.supplierNumber != '') {
              if (this.supplierNumber.toString().length == 10) {
                  if (this.supplierCity && this.supplierCity != '') {
                    if (this.supplierSelectedState) {
                      if (this.supplierPassword && this.supplierPassword != '') {
                        if (
                          this.supplierConfirmPassword &&
                          this.supplierConfirmPassword != ''
                        ) {
                          if (this.supplierPassword == this.supplierConfirmPassword) {
                            const data = {
                              name: this.supplierName,
                              email: this.supplierEmail,
                              mobile: this.supplierNumber,
                              address: this.supplierAddress,
                              city: this.supplierCity,
                              state: this.supplierSelectedState,
                              password: this.supplierPassword,
                              role: 8,
                            };
                            this.httpSv.createNewUser(data).subscribe(
                              (res: any) => {
                                if (res['status']) {
                                  this.toster.success("User created successfully!")
                                  this.dialogRef.close(true);
                                } else {
                                  this.toster.error(
                                    'Oops! Something went wrong',
                                    '',
                                    { timeOut: 2000 },
                                  );
                                }
                              },
                              (err: any) => {
                                const errorMessage = err.error.message?.non_field_errors?.join(', ') || 'Oops! Something went wrong';
                                this.toster.error(errorMessage, '', { timeOut: 2000 });
                              },
                            );
                          } else {
                            this.toster.error('Password does not match', '', {
                              timeOut: 2000,
                            });
                          }
                        } else {
                          this.toster.error('Please confirm the password', '', {
                            timeOut: 2000,
                          });
                        }
                      } else {
                        this.toster.error('Please enter password', '', {
                          timeOut: 2000,
                        });
                      }
                    } else {
                      this.toster.error('Please select state', '', {
                        timeOut: 2000,
                      });
                    }
                  } else {
                    this.toster.error('Please enter city name', '', {
                      timeOut: 2000,
                    });
                  }
              } else {
                this.toster.error('Please enter a valid mobile number', '', {
                  timeOut: 2000,
                });
              }
            } else {
              this.toster.error('Please enter mobile number', '', {
                timeOut: 2000,
              });
            }
          } else {
            this.toster.error('Please enter a valid email id', '', {
              timeOut: 2000,
            });
          }
        } else {
          this.toster.error('Please enter email id', '', { timeOut: 2000 });
        }
      } else {
        this.toster.error('Please enter name', '', { timeOut: 2000 });
      }
    } else {
      this.toster.error('Please enter Address', '', { timeOut: 2000 });
    }
  }
  modifyNewUser() {
    if (this.supplierAddress && this.supplierAddress != '') {
      if (this.supplierName && this.supplierName != '') {
        if (this.supplierEmail && this.supplierEmail != '') {
          if (this.supplierEmail.match(this.emailRegex)) {
            if (this.supplierNumber && this.supplierNumber != '') {
              if (this.supplierNumber.toString().length == 10) {
                if (this.supplierCity && this.supplierCity != '') {
                  if (this.supplierSelectedState && this.supplierSelectedState != '') {
                    const data = {
                      name: this.supplierName,
                      email: this.supplierEmail,
                      mobile: this.supplierNumber,
                      city: this.supplierCity,
                      address: this.supplierAddress,
                      state: this.supplierSelectedState,
                      status: this.suppliertxtStatus,
                      account_manager_id: this.assign_account_manager
                        ? this.assign_account_manager.id
                        : 0,
                    };
                    this.httpSv.updateSupplierDetails(this.data.id, data).subscribe(
                      (res: any) => {
                        if (res['status']) {
                          this.dialogRef.close(true);
                        } else {
                          this.toster.error('Oops! Something went wrong', '', {
                            timeOut: 2000,
                          });
                        }
                      },
                      (err: any) => {
                        this.toster.error(err.error.message, '', {
                          timeOut: 2000,
                        });
                      }
                    );
                  } else {
                    this.toster.error('Please select state', '', { timeOut: 2000 });
                  }
                } else {
                  this.toster.error('Please enter city name', '', { timeOut: 2000 });
                }
              } else {
                this.toster.error('Please enter a valid mobile number', '', { timeOut: 2000 });
              }
            } else {
              this.toster.error('Please enter mobile number', '', { timeOut: 2000 });
            }
          } else {
            this.toster.error('Please enter a valid email id', '', { timeOut: 2000 });
          }
        } else {
          this.toster.error('Please enter email id', '', { timeOut: 2000 });
        }
      } else {
        this.toster.error('Please enter name', '', { timeOut: 2000 });
      }
    } else {
      this.toster.error('Please enter Address', '', { timeOut: 2000 });
    }
  }
  handleAssignment(type: any, supplierId: any, id: any) {
    const dialogRef = this.dialog.open(DialogAssignment, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        type,
        supplierId,
        id,
        from: 'sa',
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) this.ngOnInit();
    });
  }
  handleStatusChange(status: any) {
    this.suppliertxtStatus = status;
  }
  onMaterialGroupChange(event: any) {
    // console.log(event);
  }
  close() {
    this.dialogRef.close(false);
  }
  handleChangePasswordSupplierUser() {
    const dialogRef = this.dialog.open(DialogResetPassword, {
      disableClose: true,
      hasBackdrop: true,
      data: {
        element: this.data.id,
      },
    });
  }
  handleDeleteSupplierUser() {
    let dialogue = this.dialog
      .open(DialogDeleteCofirm, {
        disableClose: true,
        hasBackdrop: true,
        data: {
          heading: 'Are you sure you want to remove this Supplier User?',
          element: this.data.id,
        },
      })
      .afterClosed()
      .subscribe((res: any) => {
        if (res) {
          this.httpSv.deleteUsers(this.data.id).subscribe(
            (res: any) => {
              if (res['status']) {
                const dialogRef = this.dialog.open(DialogDeleteDonePopup, {
                  hasBackdrop: true,
                  disableClose: true,
                  data: {
                    heading: 'Supplier Deleted',
                  },
                });
                dialogRef.afterClosed().subscribe((result) => {
                  this.dialogRef.close(true);
                });
              } else {
                this.toster.error('Oops! Someting went wrong', '', {
                  timeOut: 2000,
                });
              }
            },
            (err: any) => {
              this.toster.error(err.error.message, '', { timeOut: 2000 });
            },
          );
        }
      });
  }
}
