<div class="side_bar_common_css">
  <app-sidenav-bar></app-sidenav-bar>
  <div class="license_main_div" [ngStyle]="selectorTabData != 'documents' ? {width: '95.8%'} : {}">
    <div class="notificationBar">
      <div style="width: 100%;">
        <app-blog-scroll></app-blog-scroll>
      </div>
      <img
        *ngIf="roleId == 1"
        src="../../../assets/img/setting.png"
        (click)="navigate('/settings')"
        style="width: 35px; border-radius: 25px; cursor: pointer"
        alt=""
        srcset=""
        class="side_nav_bottom_dp"
      />
      <img
        *ngIf="!isNewNotifications"
        (click)="toggleNotif()"
        src="../../../assets/img/NotifiationBell.svg"
      />
      <img
        *ngIf="isNewNotifications"
        (click)="toggleNotif()"
        src="../../../assets/img/notificationIcon.svg"
      />
      <div class="shwoNotififications" *ngIf="showNotif">
        <div class="d-flex justify-content-end">
          <img
            style="width: 20px"
            (click)="toggleNotif()"
            src="../../../assets/img/carbon_close-filled.svg"
          />
        </div>
        <div *ngIf="notifications.length == 0">
          <div class="notificationHeader">No Notifications Found</div>
        </div>
        <div
          *ngFor="let notif of notifications"
          class="notif"
          (click)="readNotifications(notif)"
        >
          <div class="notificationHeader">
            {{ notif.type.toUpperCase().split("_").join(" ") }}
          </div>
          <div class="notificationContent">
            {{ notif.remarks }}
          </div>
        </div>
      </div>
      <button mat-button [matMenuTriggerFor]="menu" style="padding: 0">
        <img
          [src]="photo"
          style="width: 40px; height: 40px; border-radius: 35px"
          alt=""
          srcset=""
          class="side_nav_bottom_dp"
        />
      </button>
      <mat-menu #menu="matMenu">
        <div
          style="
            color: black;
            padding: 10px;
            font-weight: 700;
            border-bottom: 1px solid #a3c17e;
            width: 100%;
            max-width: 10rem;
          "
        >
          {{ username.toUpperCase() }}
        </div>
        <div style="text-align: center; font-weight: 700; color: grey; font-size: 12px; -webkit-text-stroke: 0.3px grey; border-bottom: 1px solid #a3c17e; padding-inline: 0.5rem;">
          {{ roleId == 1 ? 'Super Admin' : roleId == 2 ? 'Account Manager' : roleId == 3 ? 'Business Manager' : roleId == 5 ? 'Enterprise User' : roleId == 6 ? 'Admin User' : roleId == 7 ? 'Associate User' : roleId == 8 ? 'Supplier User': '' }}
        </div>
        <button mat-menu-item (click)="navigate('/profile')">
          <img style="width: 25px; margin-right: 10px" [src]="tPhoto" alt="" />
          Profile
        </button>
        <button mat-menu-item (click)="navigate('/login')">
          <img
            style="width: 25px; margin-right: 10px"
            src="../../../assets/img/logout.svg"
            alt=""
          />
          Logout
        </button>
      </mat-menu>
    </div>
    <div style="    
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;">
    <div class="header">REPORTS</div>
      <div class="swiching_web_user_btn_main_div">
        <button
          class="swiching_web_user_btn"
          (click)="selectorTab('sr')"
          [ngClass]="selectorTabData == 'sr' ? 'swiching_web_user_btn_active' : ''"
        >
          SERVICE REQUEST
        </button>
        <button
          class="swiching_web_user_btn"
          (click)="selectorTab('license')"
          [ngClass]="selectorTabData == 'license' ? 'swiching_web_user_btn_active' : ''"
        >
          LICENSE
        </button>
        <button
          class="swiching_web_user_btn"
          (click)="selectorTab('user')"
          [ngClass]="selectorTabData == 'user' ? 'swiching_web_user_btn_active' : ''"
          *ngIf="roleId != 5"
        >
          USER
        </button>
        <button
          class="swiching_web_user_btn"
          (click)="selectorTab('documents')"
          [ngClass]="selectorTabData == 'documents' ? 'swiching_web_user_btn_active' : ''"
        >
          DOCUMENTS
        </button>
      </div>

      <div class="datePicker" *ngIf="selectorTabData == 'user'">
          <mat-form-field style="margin: 0rem 1rem">
            <mat-label>Start Date</mat-label>
            <input
              matInput
              (dateChange)="setDate('start', $event)"
              [matDatepicker]="picker"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <mat-label>End Date</mat-label>
            <input
              matInput
              (dateChange)="setDate('end', $event)"
              [matDatepicker]="picker1"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
      </div>

      <div class="datePicker"  *ngIf="selectorTabData == 'license'">
        <mat-form-field style="margin: 0rem 1rem">
          <mat-label>Start Date</mat-label>
          <input
            matInput
            (dateChange)="setDate('start', $event)"
            [matDatepicker]="picke4"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picke4"
          ></mat-datepicker-toggle>
          <mat-datepicker #picke4></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>End Date</mat-label>
          <input
            matInput
            (dateChange)="setDate('end', $event)"
            [matDatepicker]="picker3"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker3"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker3></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="datePicker" *ngIf="selectorTabData == 'sr'">
          <mat-form-field style="margin: 0rem 1rem">
            <mat-label>Start Date</mat-label>
            <input
              matInput
              (dateChange)="setDate('start', $event)"
              [matDatepicker]="picker5"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker5"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker5></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <mat-label>End Date</mat-label>
            <input
              matInput
              (dateChange)="setDate('end', $event)"
              [matDatepicker]="picker6"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker6"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker6></mat-datepicker>
          </mat-form-field>
      </div>

      <div class="dropdown">
        <button
          class="btn export"
          style="padding: 7px 15px; margin-top: 0.5rem;"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Export <img src="../../../assets/img/Vector.svg" />
        </button>
        <ul class="dropdown-menu">
          <li
            class="dropdown-item"
            style="color: #12c058"
            [ngClass]="selectedFilter.all ? 'filterActiveCss' : ''"
            (click)="export('pdf')"
          >
            Export as PDF
          </li>
          <li
            class="dropdown-item"
            style="color: #12c058"
            [ngClass]="selectedFilter.active ? 'filterActiveCss' : ''"
            (click)="export('csv')"
          >
            Export as CSV
          </li>
        </ul>
      </div>
    </div>
    <div class="tabs">
    </div>
      <div
        style="overflow: auto; min-height: 12rem"
        *ngIf="selectorTabData == 'documents'"
        class="license_table_main_div"
      >      
      <mat-paginator
        class="paginator"
        [length]="lengthDocument"
        (page)="handlePageEvent($event, 'documents')"
        [pageSize]="pageSizeDocument"
        [showFirstLastButtons]="showFirstLastButtons"
        [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
        [hidePageSize]="hidePageSize"
        [pageIndex]="pageIndexDocument"
        aria-label="Select page"
        container-text-size="11px"
      >
      </mat-paginator>
      <div class="table-container">
      <table style="width:100%" matSort mat-table [dataSource]="dataSourceDocuments" class="mat-elevation-z8" *ngIf="dataSourceDocuments.data.length > 0">

        <!-- <ng-container matColumnDef="sl_no">
          <th mat-header-cell *matHeaderCellDef> Serial No. </th>
          <td mat-cell *matCellDef="let element"> {{ element.serial_number }} </td>
        </ng-container> -->
      
        <ng-container matColumnDef="license_number" >
          <th mat-header-cell *matHeaderCellDef style="flex-direction: column;"> 
            License Number
            <div class="dropdown">
              <button
                class="btn filter_btn multiFilterBtn"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  *ngIf="filterValues.license_number.length === 0"
                  src="../../../assets/img/../img/Group.svg"
                  alt=""
                />
                {{ filterValues.license_number.length ? filterValues.license_number.join(', ') : 'All' }}
                <img
                  style="width: 20px"
                  *ngIf="filterValues.license_number.length > 0"
                  src="../../../assets/img/../img/close-svgrepo-com.svg"
                  alt=""
                  (click)="clearFilterByKey('license_number', $event)"
                />
              </button>
              <ul class="dropdown-menu">
                <li
                  class="dropdown-item"
                  style="color: #5f6370"
                  *ngFor="let license of documentLicenseNumbers"
                  [ngClass]="{'active': filterValues.license_number.includes(license.toLowerCase())}"
                  (click)="toggleFilter(license, 'license_number', $event)"
                >
                  {{ license }}
                </li>
              </ul>
            </div>
          </th>
          <td mat-cell *matCellDef="let element" 
          (click)="modifyAppUser(element.license_number,element.id)"
          style="color: #7893ec; cursor: pointer"
          > {{ element.license_number }} </td>
        </ng-container>
      
        <ng-container matColumnDef="document_type" >
          <th mat-header-cell *matHeaderCellDef style="flex-direction: column;"> 
            Document Type 
            <div class="dropdown">
              <button
                class="btn filter_btn multiFilterBtn"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  *ngIf="filterValues.document_type.length === 0"
                  src="../../../assets/img/../img/Group.svg"
                  alt=""
                />
                {{ filterValues.document_type.length ? filterValues.document_type.join(', ') : 'All' }}
                <img
                  style="width: 20px"
                  *ngIf="filterValues.document_type.length > 0"
                  src="../../../assets/img/../img/close-svgrepo-com.svg"
                  alt=""
                  (click)="clearFilterByKey('document_type', $event)"
                />
              </button>
              <ul class="dropdown-menu">
                <li
                  class="dropdown-item"
                  style="color: #5f6370"
                  *ngFor="let type of documentTypes"
                  [ngClass]="{'active': filterValues.document_type.includes(type.toLowerCase())}"
                  (click)="toggleFilter(type, 'document_type', $event)"
                >
                  {{ type }}
                </li>
              </ul>
            </div>
          </th>
          <td mat-cell *matCellDef="let element"> {{ element.document_type }} </td>
        </ng-container>
      
        <ng-container matColumnDef="document_name">
          <th mat-header-cell *matHeaderCellDef> Document Name </th>
          <td mat-cell *matCellDef="let element"
          style="color: #7893ec; cursor: pointer"
          (click)="updateDoc(element.document_type,element)"
          > {{ element.document_name }} </td>
        </ng-container>

        <ng-container matColumnDef="status" >
          <th mat-header-cell *matHeaderCellDef style="flex-direction: column;"> 
            Status
            <div class="dropdown">
              <button
                class="btn filter_btn multiFilterBtn"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  *ngIf="filterValues.status.length === 0"
                  src="../../../assets/img/../img/Group.svg"
                  alt=""
                />
                {{ filterValues.status.length ? filterValues.status.join(', ') : 'All' }}
                <img
                  style="width: 20px"
                  *ngIf="filterValues.status.length > 0"
                  src="../../../assets/img/../img/close-svgrepo-com.svg"
                  alt=""
                  (click)="clearFilterByKey('status', $event)"
                />
              </button>
              <ul class="dropdown-menu">
                <li
                  class="dropdown-item"
                  style="color: #5f6370"
                  *ngFor="let status of documentStatuses"
                  [ngClass]="{'active': filterValues.status.includes(status.toLowerCase())}"
                  (click)="toggleFilter(status, 'status', $event)"
                >
                  {{ status }}
                </li>
              </ul>
            </div>
          </th>
          <td mat-cell *matCellDef="let element">
            <span [ngClass]="{
              'status-uploaded': element.status.toLowerCase() === 'uploaded',
              'status-expired': element.status.toLowerCase() === 'expired',
              'status-valid': element.status.toLowerCase() === 'valid',
              'status-requested': element.status.toLowerCase() === 'requested'
            }">
              {{ element.status }}
            </span>
          </td>
        </ng-container>
      
        <ng-container matColumnDef="expiry_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Expiry Date </th>
          <td mat-cell *matCellDef="let element"> {{ element.expiry_date }} </td>
        </ng-container>
      
        <!-- <ng-container matColumnDef="documents">
          <th mat-header-cell *matHeaderCellDef> Document </th>
          <td mat-cell *matCellDef="let element">
            <a *ngIf="element.documents" [href]="element.documents" target="_blank"> View Document </a>
            <span *ngIf="!element.documents"> No Document </span>
          </td>
        </ng-container> -->
        <ng-container matColumnDef="documents">
          <th class="common_table_data" mat-header-cell *matHeaderCellDef>
            View Document
          </th>
          <td class="common_table_data" mat-cell *matCellDef="let element">
            <img
            [src]="element.documents ? '../../../assets/img/carbon_view.svg' : '../../../assets/img/eye-crossed.svg'"
            alt="View Document"
            [style.cursor]="element.documents ? 'pointer' : 'not-allowed'"
            (click)="element.documents ? viewDocument(element.documents, element) : null"
          />
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumnsDocuments"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsDocuments;"></tr>
      </table>
      </div>
      

      
      <p *ngIf="dataSourceDocuments.data.length === 0" class="no-data">No documents available.</p>
      </div>

      <div
        style="overflow: auto; min-height: 12rem"
        *ngIf="selectorTabData == 'user'"
      >
        <!-- <div class="license_table_main_div" id="contentToConvert"> -->
          <mat-paginator
            #paginator
            class="paginator"
            [length]="lengthUser"
            (page)="handlePageEvent($event, 'user')"
            [pageSize]="pageSizeUser"
            [showFirstLastButtons]="showFirstLastButtons"
            [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
            [hidePageSize]="hidePageSize"
            [pageIndex]="pageIndexUser"
            aria-label="Select page"
          >
          </mat-paginator>
      <div class="table-container">

          <table
            mat-table
            [dataSource]="dataSource"
            class="mat-elevation-z8"
            style="width: 100%; position: relative"
            matPaginator
            matSort
          >
            <!-- Sl NO -->
            <!-- <ng-container matColumnDef="sl_no">
              <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
              <td class="" mat-cell *matCellDef="let element; let i = index">
                {{ i + 1 }}
              </td>
            </ng-container> -->

            <ng-container matColumnDef="email" *ngIf="selectorTabData == 'user'">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef >
                Email
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.email}}
              </td>
            </ng-container>
            
            <ng-container matColumnDef="mobile" *ngIf="selectorTabData == 'user'">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Phone
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.mobile}}
              </td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                {{ selectorTabData == "user" ? "User Name" : "Client Name" }}
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="user_type">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: flex-start;
                    padding: 5px 0;
                  "
                >
                  <p>User Type</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!userNameFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ userNameFilter ? userNameFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="userNameFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="userApplyFilter('', 'name', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of userNameDropdown"
                        (click)="userApplyFilter(status, 'name', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{
                  element.user_type == "retail_user" ? "Retail" : "Enterprise"
                }}
              </td>
            </ng-container>
            <ng-container matColumnDef="city">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>City</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!userCityFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ userCityFilter ? userCityFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="userCityFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="userApplyFilter('', 'city', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of userCityDropdown"
                        (click)="userApplyFilter(status, 'city', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.city ? element.city : element.address }}
              </td>
            </ng-container>

            <ng-container matColumnDef="state">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>State</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!userStateFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ userStateFilter ? userStateFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="userStateFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="userApplyFilter('', 'state', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of userStateDropdown"
                        (click)="userApplyFilter(status, 'state', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.state }}
              </td>
            </ng-container>

            <ng-container matColumnDef="no_licenses">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef
              style="padding-right: .5rem;">
                <p>
                  Number of <br />
                  Licenses
                </p>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.no_of_licenses }}
              </td>
            </ng-container>

            <ng-container matColumnDef="am">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                    width: 11rem;
                  "
                >
                  <p>Account Manager</p>
                  <div class="dropdown">
                    <button
                      class="btn filter_btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!userAmFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ userAmFilter ? userAmFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="userAmFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="userApplyFilter('', 'am', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of userAmDropdown"
                        (click)="userApplyFilter(status, 'am', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.account_manager ? element.account_manager : "NA" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="no_docs">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef
              style="padding-right: .5rem;">
                <p>
                  Number of <br />
                  Documents
                </p>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span
                  [ngClass]="
                    element.certificate_validity == 'Active'
                      ? 'colorGreen'
                      : element.certificate_validity == 'Inactive'
                        ? 'colorRed'
                        : 'colorBlue'
                  "
                >
                  {{ element.no_of_documents }}</span
                >
              </td>
            </ng-container>

            <ng-container matColumnDef="user_since">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <p>User Since</p>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                <span
                  [ngClass]="
                    element.date_joined == 'Active'
                      ? 'colorGreen'
                      : element.certificate_validity == 'Inactive'
                        ? 'colorRed'
                        : 'colorBlue'
                  "
                >
                  {{ element.date_joined }}</span
                >
              </td>
            </ng-container>

            <ng-container matColumnDef="storage">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Storage
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.storage_used }}
              </td>
            </ng-container>

            <ng-container matColumnDef="paid">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Paid
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.paid_status }}
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>Status</p>
                  <div class="dropdown">
                    <button
                      class="btn filter_btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!userStatusFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ userStatusFilter ? userStatusFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="userStatusFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="userApplyFilter('', 'status', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of userStatusDropdown"
                        (click)="userApplyFilter(status, 'status', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.status }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
      </div>
      </div>

      <div
        style="overflow: auto; min-height: 12rem"
        *ngIf="selectorTabData == 'license'"
      >


          <mat-paginator
            #paginator
            class="paginator"
            (page)="handlePageEvent($event, 'license')"
            [length]="lengthLicense"
            [pageSize]="pageSizeLicense"
            [disabled]="disabled"
            [showFirstLastButtons]="showFirstLastButtons"
            [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
            [hidePageSize]="hidePageSize"
            [pageIndex]="pageIndexLicense"
            aria-label="Select page"
          >
          </mat-paginator>
          <div class="table-container">
          <table
            mat-table
            [dataSource]="dataSourceLicense"
            class="mat-elevation-z8"
            style="width: 100%; position: relative"
            matSort
          >
            <!-- Sl NO -->
            <!-- <ng-container matColumnDef="sl_no">
              <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
              <td class="" mat-cell *matCellDef="let element; let i = index">
                {{ i + 1 }}
              </td>
            </ng-container> -->

            <ng-container matColumnDef="name">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>User Name</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!licenseUserFiler"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ licenseUserFiler ? licenseUserFiler : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="licenseUserFiler"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="licenseApplyFilter('', 'name', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of licenseUserDropdown"
                        (click)="licenseApplyFilter(status, 'name', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.client_name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="license">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                License
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.registration_no }}
              </td>
            </ng-container>

            <ng-container matColumnDef="type">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>Type of License</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!licenseTypeFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ licenseTypeFilter ? licenseTypeFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="licenseTypeFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="licenseApplyFilter('', 'type', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of licenseTypeDropdown"
                        (click)="licenseApplyFilter(status, 'type', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.license_type }}
              </td>
            </ng-container>

            <ng-container matColumnDef="user_type">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: flex-start;
                    padding: 5px 0;
                  "
                >
                  <p>User Type</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!licenseUserTypeFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ licenseUserTypeFilter ? licenseUserTypeFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="licenseUserTypeFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="licenseApplyFilter('', 'user_type', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of licenseUserTypeDropdown"
                        (click)="licenseApplyFilter(status, 'user_type', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{
                  element.user_type == "retail_user" ? "Retail" : "Enterprise"
                }}
              </td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>Created Date</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!licenseDateFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ licenseDateFilter ? licenseDateFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="licenseDateFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="licenseApplyFilter('', 'date', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of licenseDateDropdown"
                        (click)="licenseApplyFilter(status, 'date', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.created_at }}
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>Status</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!licenseStatusFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ licenseStatusFilter ? licenseStatusFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="licenseStatusFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="licenseApplyFilter('', 'status', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of licenseStatusDropdown"
                        (click)="licenseApplyFilter(status, 'status', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.status }}
              </td>
            </ng-container>

            <ng-container matColumnDef="am">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                    width: 10rem;
                  "
                >
                  <p>Account Manager</p>
                  <div class="dropdown">
                    <button
                      class="btn filter_btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!licenseAmFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ licenseAmFilter ? licenseAmFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="licenseAmFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="licenseApplyFilter('', 'am', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of licenseAmDropdown"
                        (click)="licenseApplyFilter(status, 'am', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.account_manager ? element.account_manager : "NA" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="city">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>City</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!licenseCityFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ licenseCityFilter ? licenseCityFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="licenseCityFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="licenseApplyFilter('', 'city', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of licenseCityDropdown"
                        (click)="licenseApplyFilter(status, 'city', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.city ? element.city : element.address }}
              </td>
            </ng-container>

            <ng-container matColumnDef="state">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>State</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!licenseStateFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ licenseStateFilter ? licenseStateFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="licenseStateFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="licenseApplyFilter('', 'state', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of licenseStateDropdown"
                        (click)="licenseApplyFilter(status, 'state', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.state }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsLicense"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumnsLicense"
            ></tr>
          </table>
          
        </div>
      </div>

      <div
        style="overflow: auto; min-height: 12rem"
        *ngIf="selectorTabData == 'sr'"
      >

      <mat-paginator
      #paginator
      class="paginator"
      (page)="handlePageEvent($event, 'sr')"
      [length]="lengthSr"
      [pageSize]="pageSizeSr"
      [disabled]="disabled"
      [showFirstLastButtons]="showFirstLastButtons"
      [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []"
      [hidePageSize]="hidePageSize"
      [pageIndex]="pageIndexSr"
      aria-label="Select page"
    >
    </mat-paginator>
        <div class="table-container" >
          <table
            mat-table
            [dataSource]="dataSourceService"
            class="mat-elevation-z8"
            style="width: 100%; position: relative"
            matSort
          >
            <!-- Sl NO -->
            <!-- <ng-container matColumnDef="sl_no">
              <th class="" mat-header-cell *matHeaderCellDef>SN.</th>
              <td class="" mat-cell *matCellDef="let element; let i = index">
                {{ i + 1 }}
              </td>
            </ng-container> -->

            <ng-container matColumnDef="id">
              <th class="" mat-header-cell *matHeaderCellDef>ID</th>
              <td class="" mat-cell *matCellDef="let element; let i = index">
                {{ element.id }}
              </td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>Client Name</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!serviceUserFiler"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ serviceUserFiler ? serviceUserFiler : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="serviceUserFiler"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="ServiceApplyFilter('', 'name', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of serviceUserDropdown"
                        (click)="ServiceApplyFilter(status, 'name', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.username }}
              </td>
            </ng-container>

            <ng-container matColumnDef="user_type">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: flex-start;
                    padding: 5px 0;
                  "
                >
                  <p>User Type</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!serviceUserTypeFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ serviceUserTypeFilter ? serviceUserTypeFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="serviceUserTypeFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="ServiceApplyFilter('', 'user_type', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of serviceUserTypeDropdown"
                        (click)="ServiceApplyFilter(status, 'user_type', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{
                  element.user_type == "retail_user" ? "Retail" : "Enterprise"
                }}
              </td>
            </ng-container>


            <ng-container matColumnDef="type">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                    width: 10rem;
                  "
                >
                  <p>Service Request Name</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!serviceTypeFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ serviceTypeFilter ? serviceTypeFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="serviceTypeFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="ServiceApplyFilter('', 'type', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of serviceTypeDropdown"
                        (click)="ServiceApplyFilter(status, 'type', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.services }}
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>Current Status</p>
                  <div class="dropdown">
                    <button
                      class="btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!serviceStatusFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ serviceStatusFilter ? serviceStatusFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="serviceStatusFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="ServiceApplyFilter('', 'status', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of serviceStatusDropdown"
                        (click)="ServiceApplyFilter(status, 'status', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.status }}
              </td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Date
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.date }}
              </td>
            </ng-container>

            <ng-container matColumnDef="time">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                Days Lapsed
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.days_lapsed }}
              </td>
            </ng-container>

            <ng-container matColumnDef="am">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                    width: 10rem;
                  "
                >
                  <p>Account Manager</p>
                  <div class="dropdown">
                    <button
                      class="btn filter_btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!serviceAmFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ serviceAmFilter ? serviceAmFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="serviceAmFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="ServiceApplyFilter('', 'am', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of serviceAmDropdown"
                        (click)="ServiceApplyFilter(status, 'am', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.account_manager ? element.account_manager : "NA" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="city">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>City</p>
                  <div class="dropdown">
                    <button
                      class="btn filter_btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!serviceCityFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ serviceCityFilter ? serviceCityFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="serviceCityFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="ServiceApplyFilter('', 'city', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of serviceCityDropdown"
                        (click)="ServiceApplyFilter(status, 'city', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.city ? element.city : element.address }}
              </td>
            </ng-container>

            <ng-container matColumnDef="state">
              <th class="common_table_data" mat-header-cell *matHeaderCellDef>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    height: 5rem;
                    justify-content: space-around;
                    padding: 5px 0;
                  "
                >
                  <p>State</p>
                  <div class="dropdown">
                    <button
                      class="btn filter_btn multiFilterBtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        *ngIf="!serviceStateFilter"
                        src="../../../assets/img/../img/Group.svg"
                        alt=""
                      />{{ serviceStateFilter ? serviceStateFilter : "All" }}
                      <img
                        style="width: 20px"
                        *ngIf="serviceStateFilter"
                        src="../../../assets/img/../img/close-svgrepo-com.svg"
                        alt=""
                        (click)="ServiceApplyFilter('', 'state', 1)"
                      />
                    </button>
                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        style="color: #5f6370"
                        [ngClass]="selectedFilter.all ? '' : ''"
                        *ngFor="let status of serviceStateDropdown"
                        (click)="ServiceApplyFilter(status, 'state', 0)"
                      >
                        {{ status }}
                      </li>
                    </ul>
                  </div>
                </div>
              </th>
              <td class="common_table_data" mat-cell *matCellDef="let element">
                {{ element.state }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsService"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumnsService"
            ></tr>
          </table>
        </div>
      </div>
    <!-- </div> -->
  </div>
</div>
